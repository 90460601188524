/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlankOutlined } from '@material-ui/icons';
import CustomDropdown from '../common/CustomDropdown';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ResetFields from '../common/textFiledwithReset';
import { useInterventionDetailsContext } from '../context/InterventionDetails';
import useStyles from './styles';

const RRWHCustomBasicParameters = (props) => {
  const classes = useStyles();
  const { setSaveError } = props;
  const [error, setError] = useState('');
  const {
    schema,
    get: { getQuantityValue, getQuantityLabel, getQuantityUnit },
    set: { updateQuantityValue },
  } = useInterventionDetailsContext();
  const demandOption = getQuantityValue(schema.rrwh__pi_demandOption);
  const maximiseOption = getQuantityValue(schema.rrwh__pi_maximiseOption);
  const [selectedRechargeOptions, setSelectedRechargeOptions] = useState([]);
  const [rechargeStructureOptions, setRechargeStructureOptions] = useState([
    { option: 'Recharge Pits', label: 'Recharge Pits', enabled: true, selected: false },
    { option: 'Recharge Trenches', label: 'Recharge Trenches', enabled: true, selected: false },
    { option: 'Recharge Wells', label: 'Recharge Wells', enabled: true, selected: false },
    { option: 'Recharge Shafts with Borewells', label: 'Recharge Shafts with Borewells', enabled: true, selected: false },
    { option: 'Recharge Trenches with Borewells', label: 'Recharge Trenches with Borewells', enabled: true, selected: false },
    { option: 'Recharge Existing Bore Wells', label: 'Recharge Existing Bore Wells', enabled: true, selected: false },
  ]);
  const schemaRechargeStructureOptions = getQuantityValue(schema.rrwh__pi_rechargeStructureOptions);

  useEffect(() => {
    const initialOptions = getQuantityValue(schema.rrwh__pi_rechargeStructureOptions);
    if (initialOptions && Array.isArray(initialOptions) && initialOptions.length > 0) {
      setSelectedRechargeOptions(initialOptions);
      setRechargeStructureOptions((prevOptions) =>
        prevOptions.map((option) => ({
          ...option,
          selected: initialOptions.includes(option.option),
        }))
      );
    } else setSelectedRechargeOptions([]);
  }, []);

  useEffect(() => {
    let sum = 0;
    if (selectedRechargeOptions.includes('Recharge Pits')) {
      sum += Number(getQuantityValue(schema.rrwh__pi_rpRechargePercentage)) || 0;
    }
    if (selectedRechargeOptions.includes('Recharge Trenches')) {
      sum += Number(getQuantityValue(schema.rrwh__pi_rtRechargePercentage)) || 0;
    }
    if (selectedRechargeOptions.includes('Recharge Wells')) {
      sum += Number(getQuantityValue(schema.rrwh__pi_rwRechargePercentage)) || 0;
    }
    if (selectedRechargeOptions.includes('Recharge Shafts with Borewells')) {
      sum += Number(getQuantityValue(schema.rrwh__pi_rsbwRechargePercentage)) || 0;
    }
    if (selectedRechargeOptions.includes('Recharge Trenches with Borewells')) {
      sum += Number(getQuantityValue(schema.rrwh__pi_rtbwRechargePercentage)) || 0;
    }
    if (selectedRechargeOptions.includes('Recharge Existing Bore Wells')) {
      sum += Number(getQuantityValue(schema.rrwh__pi_rbwRechargePercentage)) || 0;
    }

    if (selectedRechargeOptions.length > 0 && sum !== 100) {
      setError('The sum of percentages should be 100%');
      setSaveError(true);
    } else {
      setError('');
      setSaveError(false);
    }
  }, [
    selectedRechargeOptions,
    getQuantityValue(schema.rrwh__pi_rpRechargePercentage),
    getQuantityValue(schema.rrwh__pi_rtRechargePercentage),
    getQuantityValue(schema.rrwh__pi_rwRechargePercentage),
    getQuantityValue(schema.rrwh__pi_rsbwRechargePercentage),
    getQuantityValue(schema.rrwh__pi_rtbwRechargePercentage),
    getQuantityValue(schema.rrwh__pi_rbwRechargePercentage),
  ]);

  const handleDemandOption = (option) => {
    const currentValue = getQuantityValue(schema.rrwh__pi_demandOption);

    let newValue;
    if (currentValue.includes(option)) {
      newValue = currentValue.filter((item) => item !== option);
    } else {
      newValue = [...currentValue, option];
    }

    updateQuantityValue(schema.rrwh__pi_demandOption, newValue);
  };

  const handleMaximiseOption = (option) => {
    updateQuantityValue(schema.rrwh__pi_maximiseOption, option);
  };

  // TODO: add validation for save
  const handleRechargeStructureOptions = (options) => {
    setSelectedRechargeOptions(options);
    setRechargeStructureOptions((prevOptions) =>
      prevOptions.map((option) => ({
        ...option,
        selected: options.includes(option.option),
      }))
    );

    if (!options.includes('Recharge Pits'))
      updateQuantityValue(schema.rrwh__pi_rpRechargePercentage, getQuantityValue(schema.rrwh__pi_rpRechargePercentage) || 0);
    if (!options.includes('Recharge Trenches'))
      updateQuantityValue(schema.rrwh__pi_rtRechargePercentage, getQuantityValue(schema.rrwh__pi_rtRechargePercentage) || 0);
    if (!options.includes('Recharge Wells'))
      updateQuantityValue(schema.rrwh__pi_rwRechargePercentage, getQuantityValue(schema.rrwh__pi_rwRechargePercentage) || 0);
    if (!options.includes('Recharge Shafts with Borewells'))
      updateQuantityValue(schema.rrwh__pi_rsbwRechargePercentage, getQuantityValue(schema.rrwh__pi_rsbwRechargePercentage) || 0);
    if (!options.includes('Recharge Trenches with Borewells'))
      updateQuantityValue(schema.rrwh__pi_rtbwRechargePercentage, getQuantityValue(schema.rrwh__pi_rtbwRechargePercentage) || 0);
    if (!options.includes('Recharge Existing Bore Wells'))
      updateQuantityValue(schema.rrwh__pi_rbwRechargePercentage, getQuantityValue(schema.rrwh__pi_rbwRechargePercentage) || 0);
    updateQuantityValue(schema.rrwh__pi_rechargeStructureOptions, options);
  };

  const renderStructure = (label, percentageKey, numberKey, areaKey) => (
    <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
      <Grid item xs={4} direction="column">
        <Typography className={classes.textHeader5}>{label}</Typography>
      </Grid>
      <Grid item xs={2} direction="column">
        <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
          <Grid item xs={7} direction="column">
            <ResetFields
              id={`outlined-${percentageKey}`}
              variant="outlined"
              value={getQuantityValue(schema[percentageKey])}
              placeholder=""
              onChange={(e) => {
                e.preventDefault();
                const value = e.target.value;
                updateQuantityValue(schema[percentageKey], value);
              }}
              fullWidth
              required
              disabled={false}
              removeResetIcon={true}
            />
          </Grid>
          <Grid item xs={2} direction="column" container style={{ paddingLeft: '8px' }}>
            <Typography className={classes.textHeader5}>%</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3} direction="column">
        <Typography className={classes.textHeader5}>{getQuantityValue(schema[numberKey])}</Typography>
      </Grid>
      <Grid item xs={3} direction="column">
        <Typography className={classes.textHeader5}>{getQuantityValue(schema[areaKey])}</Typography>
      </Grid>
    </Grid>
  );

  return (
    <Grid container direction="column" alignItems="left" item xs={12}>
      <Grid container item xs={12}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.rrwh__pi_tankSize)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.rrwh__pi_tankSize)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.rrwh__pi_tankSize, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.rrwh__pi_tankSize, null);
                }}
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.rrwh__pi_tankSize)}</Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
            <Typography variant="caption">
              {getQuantityLabel(schema.rrwh_mandatedTankSize)}: {getQuantityValue(schema.rrwh_mandatedTankSize)} {getQuantityUnit(schema.rrwh_mandatedTankSize)}
            </Typography>
          </Grid>
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography variant="caption">
              {getQuantityLabel(schema.rrwh_additionalTankSize)}: {getQuantityValue(schema.rrwh_additionalTankSize) || '-'}{' '}
              {getQuantityUnit(schema.rrwh_additionalTankSize)}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.rrwh_daysServiced)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.textHeader5}>{getQuantityValue(schema.rrwh_daysServiced)}</Typography>
          </Grid>
        </Grid>

        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.rrwh_annualRechargedWater)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.textHeader5}>
              {getQuantityValue(schema.rrwh_annualRechargedWater)} {getQuantityUnit(schema.rrwh_annualRechargedWater)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container item xs={12}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.rrwh__pi_demandOption)}</Typography>
          </Grid>

          {demandOption.includes('domestic') ? (
            <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
              <CheckBox className={classes.nonselectedCheckbox} onClick={() => handleDemandOption('domestic')} />
              <Typography>Domestic</Typography>
            </Grid>
          ) : (
            <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
              <CheckBoxOutlineBlankOutlined className={classes.nonselectedCheckbox} onClick={() => handleDemandOption('domestic')} />
              <Typography>Domestic</Typography>
            </Grid>
          )}

          {demandOption.includes('drinking') ? (
            <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
              <CheckBox className={classes.nonselectedCheckbox} onClick={() => handleDemandOption('drinking')} />
              <Typography>Drinking</Typography>
            </Grid>
          ) : (
            <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
              <CheckBoxOutlineBlankOutlined className={classes.nonselectedCheckbox} onClick={() => handleDemandOption('drinking')} />
              <Typography>Drinking</Typography>
            </Grid>
          )}
        </Grid>

        <Grid item xs={6} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '8px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.rrwh__pi_maximiseOption)}</Typography>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6} container alignItems="flex-start" direction="column" style={{}}>
              <Grid xs>
                <Typography className={classes.textHeader3}>
                  {maximiseOption === 'Maximise Reuse' && (
                    <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleMaximiseOption('Maximise Reuse')} />
                  )}
                  {maximiseOption !== 'Maximise Reuse' && (
                    <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleMaximiseOption('Maximise Reuse')} />
                  )}
                  Maximise Reuse
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12}>
            <Grid item xs={6} container alignItems="flex-start" direction="column" style={{}}>
              <Grid xs>
                <Typography className={classes.textHeader3}>
                  {maximiseOption === 'Maximise Recharge' && (
                    <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleMaximiseOption('Maximise Recharge')} />
                  )}
                  {maximiseOption !== 'Maximise Recharge' && (
                    <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleMaximiseOption('Maximise Recharge')} />
                  )}
                  Maximise Recharge
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container item xs={12}>
        <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.rrwh__pi_rechargeStructureOptions)}</Typography>
        </Grid>
        <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
          <Typography variant="caption" style={error ? { color: 'red' } : {}}>
            The % should add up to 100%
          </Typography>
        </Grid>
        <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
          <Grid item xs={6} direction="column">
            <CustomDropdown
              placeholder="Select Options"
              value={selectedRechargeOptions}
              onChange={(e) => {
                e.preventDefault();
                handleRechargeStructureOptions(e.target.value);
              }}
              options={rechargeStructureOptions}
              disabled={false}
              multiple={true}
            />
          </Grid>
          <Grid item xs={6} direction="column"></Grid>
        </Grid>

        <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
          <Grid item xs={4} direction="column"></Grid>
          <Grid item xs={2} direction="column">
            <Typography variant="caption">Split %</Typography>
          </Grid>
          <Grid item xs={3} direction="column">
            <Typography variant="caption">No. of Structures</Typography>
          </Grid>
          <Grid item xs={3} direction="column">
            <Typography variant="caption">Surface Area per Structure</Typography>
          </Grid>
        </Grid>

        {schemaRechargeStructureOptions.includes('Recharge Pits') &&
          renderStructure('Recharge Pits', 'rrwh__pi_rpRechargePercentage', 'rrwh_rpNumberOfStructures', 'rrwh_rpSurfaceArea')}

        {schemaRechargeStructureOptions.includes('Recharge Trenches') &&
          renderStructure('Recharge Trenches', 'rrwh__pi_rtRechargePercentage', 'rrwh_rtNumberOfStructures', 'rrwh_rtSurfaceArea')}

        {schemaRechargeStructureOptions.includes('Recharge Wells') &&
          renderStructure('Recharge Wells', 'rrwh__pi_rwRechargePercentage', 'rrwh_rwNumberOfStructures', 'rrwh_rwSurfaceArea')}

        {schemaRechargeStructureOptions.includes('Recharge Shafts with Borewells') &&
          renderStructure('Recharge Shafts with Borewells', 'rrwh__pi_rsbwRechargePercentage', 'rrwh_rsbwNumberOfStructures', 'rrwh_rsbwSurfaceArea')}

        {schemaRechargeStructureOptions.includes('Recharge Trenches with Borewells') &&
          renderStructure('Recharge Trenches with Borewells', 'rrwh__pi_rtbwRechargePercentage', 'rrwh_rtbwNumberOfStructures', 'rrwh_rtbwSurfaceArea')}

        {schemaRechargeStructureOptions.includes('Recharge Existing Bore Wells') &&
          renderStructure('Recharge Existing Bore Wells', 'rrwh__pi_rbwRechargePercentage', 'rrwh_rbwNumberOfStructures', 'rrwh_rbwSurfaceArea')}
      </Grid>
    </Grid>
  );
};
export default RRWHCustomBasicParameters;
