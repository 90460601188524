import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { barChartColorMap, sortData } from '../newProjectReport/ReportUtils';
import { MetafireInterventions } from '../../../../../metafire/utils/types';

const MAX_BAR_HEIGHT = 513;

const chunkArray = (array, size) => {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
};
const Legend = ({ items }) => {
  const classes = useStyles();
  const groupedItems = chunkArray(items, 3);
  const formatLabel = (label) => {
    /*react-pdf does not support word-break, white-space css properties yet, hence using a custom function.*/
    if (label === 'Structural Concrete') return 'Structural \n Concrete';
    if (label === 'Bathroom Flooring') return 'Bathroom \n Flooring';
    return label;
  };
  return (
    <Grid container direction="row" justifyContent="left" className={classes.legendMainGrid}>
      {groupedItems.map((group, index) => (
        <Grid key={index} item xs={3} className={classes.legendSubGrid}>
          {group.map((item) => (
            <Grid className={classes.legendColorBoxGrid}>
              <Grid className={classes.legendColorBox} style={{ backgroundColor: barChartColorMap[item.key] }} />
              <Typography className={classes.legendText}>{formatLabel(item.label)}</Typography>
            </Grid>
          ))}
        </Grid>
      ))}
    </Grid>
  );
};

const calculateScaledHeight = (value, total, maxTotal) => {
  const scaledHeight = (value / total) * MAX_BAR_HEIGHT;
  return scaledHeight;
};

const MaterialsBarChart = ({ data, label, isLeftMost, maxTotal, maxActualTotal, lowerBarHeight, reduction, reductionPercent }) => {
  const classes = useStyles();
  const { total, segments } = data;
  let isTopMostLayer = false;
  let isTopLayerBorderCurved = false;
  const barHeight = (total / maxTotal) * MAX_BAR_HEIGHT;
  return (
    <Grid container className={classes.barchartMainGrid}>
      {isLeftMost && (
        <Grid className={classes.leftRangebarGrid}>
          <Grid className={classes.leftLineContainerText}>
            <Grid className={classes.subGrid}>
              <Grid style={{ position: 'absolute', width: 100 }}>
                <Typography style={{ fontFamily: 'Lato', fontSize: '10px', marginTop: '-6px', color: '#2E2E2E' }}>
                  {Math.round(maxActualTotal)} tCO2e
                </Typography>
              </Grid>
            </Grid>
            <Grid className={classes.subGrid}>
              <Grid className={classes.solidLineMiddleText} />
              <Grid style={{ position: 'absolute', marginTop: 500 - lowerBarHeight, width: 100 }}>
                <Typography style={{ color: '#15B7B9', fontFamily: 'Lato', fontSize: '10px', fontWeight: 400 }}>{Math.round(reduction)} tCO2e</Typography>
                <Typography style={{ color: '#15B7B9', fontFamily: 'Lato', fontSize: '14px', fontWeight: 400 }}>{reductionPercent.toFixed(2)}%</Typography>
                <Typography style={{ color: '#2E2E2E', fontFamily: 'Lato', fontSize: '14px', fontWeight: 400 }}>Reduction</Typography>
              </Grid>
            </Grid>
            <Typography style={{ fontFamily: 'Lato', fontSize: '10px', marginBottom: '-6px', color: '#2E2E2E' }}>0 tCO2e</Typography>
          </Grid>
          <Grid className={classes.leftLineContainer}>
            <Grid className={classes.dashedLineTop}></Grid>
            <Grid className={classes.subGrid}>
              <Grid className={classes.solidLineMiddle} />
              <Grid className={classes.dashedLineTopTwo} style={{ marginTop: 513 - lowerBarHeight }}></Grid>
            </Grid>
            <Grid className={classes.dashedLineBottom}></Grid>
          </Grid>
        </Grid>
      )}
      <Grid style={{ height: barHeight }} className={classes.bar}>
        {segments.map((segment, index) => {
          const height = calculateScaledHeight(segment.value, total, maxTotal);
          if (height !== 0 && !isTopMostLayer && !isTopLayerBorderCurved) {
            isTopMostLayer = true;
            isTopLayerBorderCurved = true;
          }
          const segmentStyle = {
            height: height,
            backgroundColor: segment.color,
            borderTopLeftRadius: isTopMostLayer ? 20 : 0,
            borderTopRightRadius: isTopMostLayer ? 20 : 0,
          };
          isTopMostLayer = false;
          return (
            <Grid
              key={index}
              style={{
                ...segmentStyle,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              className={classes.segment}
            >
              {height > 30 && (
                <Typography style={{ marginTop: 10, fontWeight: 'Bold', fontFamily: 'Lato', fontSize: 17, color: '#2E2E2E' }}>
                  {Math.round(segment.actualValue)}
                </Typography>
              )}
            </Grid>
          );
        })}
      </Grid>
      <Grid container style={{ marginTop: '-6px' }}>
        <Typography className={classes.chartLabel} style={{ marginLeft: label === 'Base Case' ? 85 : 0 }}>
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
};

const MaterialsFlowDiagram = (props) => {
  const classes = useStyles();
  const segmentsPreSdplus = sortData(
    props?.categorisedReportData?.map((interv) => {
      return {
        actualValue: interv.resourceSavingsPreSdPlus,
        value: Math.abs(interv.resourceSavingsPreSdPlus),
        interventionKey: interv?.interventionKey,
        color: barChartColorMap[interv.interventionKey],
      };
    }) || []
  );

  const segmentsPostSdplus = sortData(
    props?.categorisedReportData?.map((interv) => {
      return {
        actualValue: interv.resourceSavings,
        value: Math.abs(interv.resourceSavings),
        interventionKey: interv?.interventionKey,
        color: barChartColorMap[interv.interventionKey],
      };
    }) || []
  );

  const resourceSavingPreSdplusSum = props?.categorisedReportData?.reduce((initialValue, interv) => {
    return initialValue + Math.abs(interv.resourceSavingsPreSdPlus);
  }, 0);
  const resourceSavingPreSdplusSumActual = props?.categorisedReportData?.reduce((initialValue, interv) => {
    return initialValue + interv.resourceSavingsPreSdPlus;
  }, 0);
  const resourceSavingPostSdplusSum = props?.categorisedReportData?.reduce((initialValue, interv) => {
    return initialValue + Math.abs(interv.resourceSavings);
  }, 0);
  const resourceSavingPostSdplusSumActual = props?.categorisedReportData?.reduce((initialValue, interv) => {
    return initialValue + interv.resourceSavings;
  }, 0);
  const otherMaterialsPreSdplus = props?.baselineEmbodiedCarbon - resourceSavingPreSdplusSum;
  const otherMaterialsPreSdplusActual = props?.baselineEmbodiedCarbon - resourceSavingPreSdplusSumActual;

  const baseCase = {
    total: resourceSavingPreSdplusSum + Math.abs(otherMaterialsPreSdplus),
    actualTotal: resourceSavingPreSdplusSumActual + otherMaterialsPreSdplusActual,
    segments: [
      ...segmentsPreSdplus,
      {
        actualValue: otherMaterialsPreSdplusActual,
        value: Math.abs(otherMaterialsPreSdplus),
        interventionKey: 'otherMaterials',
        color: '#99FFD9',
      },
    ],
  };

  const otherMaterialsPostSdplus = otherMaterialsPreSdplus;
  const otherMaterialsPostSdplusActual = otherMaterialsPreSdplusActual;
  const sdPlusImpact = {
    total: resourceSavingPostSdplusSum + Math.abs(otherMaterialsPostSdplus),
    actualTotal: resourceSavingPostSdplusSumActual + otherMaterialsPostSdplusActual,
    segments: [
      ...segmentsPostSdplus,
      {
        actualValue: otherMaterialsPostSdplusActual,
        value: Math.abs(otherMaterialsPostSdplus),
        interventionKey: 'otherMaterials',
        color: '#99FFD9',
      },
    ],
  };
  const legendItems = props?.categorisedReportData.map((interv) => ({
    key: interv.interventionKey,
    label: MetafireInterventions[interv.interventionKey],
  }));

  const maxTotal = Math.max(baseCase.total, sdPlusImpact.total);
  const maxActualTotal = Math.max(baseCase.actualTotal, sdPlusImpact.actualTotal);
  const reduction = baseCase.actualTotal - sdPlusImpact.actualTotal;
  const reductionPercent = (reduction * 100) / baseCase.actualTotal;
  const lowerBarHeight = Math.min((baseCase.total / maxTotal) * MAX_BAR_HEIGHT, (sdPlusImpact.total / maxTotal) * MAX_BAR_HEIGHT);
  return (
    <Grid>
      <Grid className={classes.chartContainer}>
        <MaterialsBarChart
          lowerBarHeight={lowerBarHeight}
          data={baseCase}
          label="Base Case"
          isLeftMost={true}
          maxTotal={maxTotal}
          maxActualTotal={maxActualTotal}
          reduction={reduction}
          reductionPercent={reductionPercent}
        />
        <MaterialsBarChart
          lowerBarHeight={lowerBarHeight}
          data={sdPlusImpact}
          label="SD+ Impact"
          isLeftMost={false}
          maxTotal={maxTotal}
          maxActualTotal={maxActualTotal}
          reduction={reduction}
          reductionPercent={reductionPercent}
        />
      </Grid>

      <Grid className={classes.bottomTitleGrid}>
        <Typography className={classes.bottomTitleSUbText}>
          {reductionPercent.toFixed(2)}% ({Math.round(reduction)} tCO2e)
        </Typography>
        <Typography className={classes.bottomTitleSubText2}> reduction in Embodied Carbon</Typography>
      </Grid>
      <Legend items={[...legendItems, { key: 'otherMaterials', label: 'Other Materials' }]} />
    </Grid>
  );
};

export default MaterialsFlowDiagram;

const useStyles = makeStyles((theme) => ({
  bottomTitleGrid: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  bottomTitleSUbText: {
    color: '#15B7B9',
    fontFamily: 'Lato',
    fontSize: '13px',
    fontWeight: 400,
  },
  bottomTitleSubText2: {
    color: '#2E2E2E',
    fontFamily: 'Lato',
    fontSize: '13px',
    fontWeight: 400,
  },
  chartContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    height: '100%',
  },
  bar: {
    flexDirection: 'column',
    width: 110,
    overflow: 'hidden',
  },
  leftLineContainer: {
    marginLeft: 10,
    height: MAX_BAR_HEIGHT,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: '20px',
  },
  leftLineContainerText: {
    height: MAX_BAR_HEIGHT,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  dashedLineTop: {
    fontSize: 30,
    height: 2,
    width: 12,
    backgroundColor: '#666666',
    borderBottom: '0.5px solid #666666',
    alignItems: 'center',
  },
  dashedLineTopTwo: {
    fontSize: 30,
    height: 2,
    width: 12,
    borderBottom: '2px solid #666666',
    alignItems: 'center',
    backgroundColor: 'transparent',
    position: 'absolute',
  },
  solidLineMiddle: {
    width: 2,
    borderTop: '0.5px solid #666666',
    borderBottom: '0.5px solid #666666',
    backgroundColor: '#666666',
    height: MAX_BAR_HEIGHT,
  },
  solidLineMiddleText: {
    width: 0,
    backgroundColor: '#666666',
    height: MAX_BAR_HEIGHT,
  },
  dashedLineBottom: {
    height: 2,
    width: 12,
    backgroundColor: '#666666',
    borderTop: '0.5px solid #666666',
    borderBottom: '0.5px solid #666666',
  },
  chartLabel: {
    marginTop: 10,
    fontWeight: 'Bold',
    fontFamily: 'Lato',
    fontSize: 17,
    color: '#2E2E2E',
  },
  legendColorBox: {
    width: '20px',
    height: '20px',
    marginRight: 8,
  },
  legendText: {
    fontFamily: 'Lato',
    fontSize: '8px',
    color: '#2E2E2E',
    textAlign: 'left',
    width: '65px',
    flexGrow: 1,
    whiteSpace: 'normal',
    wordWrap: 'break-word',
  },
  legendMainGrid: {
    marginTop: 20,
    padding: 10,
    justifyContent: 'space-between',
  },
  legendColorBoxGrid: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
    alignItems: 'center',
    width: '100%',
  },
  legendSubGrid: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  barchartMainGrid: {
    display: 'flex',
    alignItems: 'flex-end',
    height: '100%',
    flexDirection: 'row',
    marginLeft: '15%',
  },
  leftRangebarGrid: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: '2%',
    position: 'relative',
  },
  subGrid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
