import React, { useState, useEffect } from 'react';
import { Typography, Grid } from '@material-ui/core';
import numberWithCommas from '../../../../../utils/numberToComma';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';

const StructuralSteelProjectInputs = (props) => {
  const classes = useStyles();
  const {
    get: { getQuantityValue, getQuantityLabel, getQuantityUnit },
    schema,
  } = useInterventionDetailsContext();

  return (
    <Grid container item xs={12} direction="column">
      <Grid xs={12} style={{ display: 'flex', alignItems: 'center' }}>
        <Typography className={classes.textHeader1}>Project Inputs</Typography>
      </Grid>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.structuralSteel_builtUpArea)}</Typography>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(schema.structuralSteel_builtUpArea)} {getQuantityUnit(schema.structuralSteel_builtUpArea)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.textHeader5}> {getQuantityLabel(schema.structuralSteel_projectQuantityFactorUserInput)} </Typography>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(schema.structuralSteel_projectQuantityFactorUserInput)} {getQuantityUnit(schema.structuralSteel_projectQuantityFactorUserInput)}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={12} style={{ paddingTop: '50px', paddingBottom: '20px' }}></Grid>
    </Grid>
  );
};

export default StructuralSteelProjectInputs;
