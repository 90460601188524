import { withWidth } from '@material-ui/core';
import React, { useEffect, useContext, useState, useMemo } from 'react';
import { NavigatorSource } from '../../utils/Defaults';
import { useLocation, useHistory } from 'react-router-dom';
import useScreenType from '../../../../hooks/checkScreenType';
import { ProductsPageContext } from '../../../../../routing/IndexRouting';
import useCategoryDetails, { usePrevious } from '../../hooks/useCategoryDetailsData';
import CategoryDetailsMobile from './components/mobilecomponents/CategoryDetailsMobile';
import CategoryDetailsDesktop from './components/desktopcomponents/CategoryDetailsDesktop';
import {
  getURLStrings,
  constructURlforSourceCategory,
  constructURlforSourceSuperCategory,
  constructURLforSourceEcolabels,
  constructURLforSourceIntervention,
} from './utils/URLParser';
import axios from 'axios';

function CertificationAndSuperCategories(props) {
  const { width } = props;
  const { isMobile } = useScreenType(width);

  const location = useLocation();
  const previousLocation = usePrevious(location);

  const history = useHistory();
  const search = history?.location?.search;

  const query = new URLSearchParams(search);
  const setURL = (URL) => history.push(URL);

  const [userSelectedFirstSection, setUserSelectedFirstSection] = useState('');

  //DATA FROM HOOKS
  const { productsresponse, categories, source, totalProducts, ecoLabels, allbrands } = useCategoryDetails();

  const { handleURL, URLS, appliedfilters, isDefault } = useContext(ProductsPageContext);
  const uniqueBrands = [...new Set(productsresponse && productsresponse.map((product) => product.brand.name))];
  const uniqueSubcategories = {};
  productsresponse &&
    productsresponse.forEach((product) => {
      const categoryId = product?.category?.id;
      const categoryName = product?.category?.name;
      const subcategoryName = product?.subcategory?.name;
      if (!uniqueSubcategories[categoryId]) {
        uniqueSubcategories[categoryId] = { categoryName, subcategories: new Set() };
      }
      uniqueSubcategories[categoryId].subcategories.add(subcategoryName);
    });
  const uniqueEcolabels = new Set();
  productsresponse &&
    productsresponse.forEach((product) => {
      if (product.ecolabels && product.ecolabels.length > 0) product.ecolabels.forEach((ecolabel) => uniqueEcolabels.add(ecolabel));
    });

  /*DATA RELOAD IF URL CHANGES*/
  useEffect(() => {
    if (previousLocation !== location) {
      handleURL(
        query.get('category'),
        query.get('subcategory'),
        query.get('brands'),
        query.get('supercategory'),
        query.get('page'),
        query.get('pagesize'),
        query.get('source'),
        query.get('filters'),
        query.get('sort'),
        query.get('searchquery'),
        query.get('mappingID'),
        query.get('productids'),
        query.get('productstatus')
      );
    }
  }, [location, previousLocation, query]);

  /*for new source intervention*/
  const [shortlistedProducts, setShortlistedProducts] = useState([]);

  const iid = useMemo(() => {
    if (search) {
      const query = new URLSearchParams(search);
      return query.get('mappingID');
    }
    return null;
  }, [search]);

  useEffect(() => {
    if (iid && URLS.source === NavigatorSource.intervention) {
      axios
        .get(`/ProjectInterventionMapping/${iid}`)
        .then((response) => {
          setShortlistedProducts(response?.data?.data?.data[0]?.shortlistedProducts);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [iid, URLS, NavigatorSource]);

  /* if apply filters then */
  useEffect(() => {
    if (appliedfilters.length !== 0) {
      setUserSelectedFirstSection(appliedfilters[0].id);
    } else {
      setUserSelectedFirstSection('');
    }

    if (URLS.source === NavigatorSource.categories) {
      const { subcategorystr, brandsstr, filterstr } = getURLStrings(appliedfilters);
      const url = constructURlforSourceCategory(
        URLS.source,
        URLS.category,
        subcategorystr,
        brandsstr,
        filterstr,
        URLS.page,
        URLS.pagesize,
        isDefault,
        URLS.sort
      );
      setURL(url);
    } else if (URLS.source === NavigatorSource.supercategories) {
      const { subcategorystr, brandsstr, filterstr, categoriesstr } = getURLStrings(appliedfilters);
      const url = constructURlforSourceSuperCategory(
        URLS.source,
        URLS.supercategory,
        subcategorystr,
        brandsstr,
        filterstr,
        categoriesstr,
        URLS.page,
        URLS.pagesize,
        URLS.sort
      );
      setURL(url);
    } else if (URLS.source === NavigatorSource.ecolabels) {
      const { subcategorystr, brandsstr, filterstr, categoriesstr, ecolabelstr } = getURLStrings(appliedfilters);
      const url = constructURLforSourceEcolabels(URLS.source, brandsstr, ecolabelstr, URLS.page, URLS.pagesize, URLS.sort);
      setURL(url);
    } else if (URLS.source === NavigatorSource.intervention) {
      const data = getURLStrings(appliedfilters);
      const { subcategorystr, brandsstr, filterstr, categoriesstr, filtercontextual, filtercontextualforshowproduct } = data;
      const url = constructURLforSourceIntervention(
        URLS.source,
        URLS.category,
        subcategorystr,
        brandsstr,
        filterstr,
        URLS.page,
        URLS.pagesize,
        isDefault,
        URLS.sort,
        URLS.mappingid,
        shortlistedProducts.join(','),
        filtercontextual,
        filtercontextualforshowproduct
      );
      setURL(url);
    }
  }, [appliedfilters]);
  return (
    <>
      {!isMobile && (
        <CategoryDetailsDesktop
          products={productsresponse}
          categories={categories}
          totalproducts={totalProducts}
          ecoLabels={ecoLabels}
          allbrands={allbrands}
          // for filters
          uniqueBrands={uniqueBrands}
          uniqueSubcategories={uniqueSubcategories}
          userSelectedFirstSection={userSelectedFirstSection}
          uniqueEcolabels={Array.from(uniqueEcolabels)}
          shortlistedProducts={shortlistedProducts}
          mappingid={iid}
          setShortlistedProducts={setShortlistedProducts}
        />
      )}
      {isMobile && (
        <CategoryDetailsMobile
          products={productsresponse}
          categories={categories}
          totalproducts={totalProducts}
          ecoLabels={ecoLabels}
          allbrands={allbrands}
          mappingid={iid}
        />
      )}
    </>
  );
}

export default withWidth()(CertificationAndSuperCategories);
// const uniqueEcolabels = [...new Set(productsresponse && productsresponse.flatMap((product) => product.ecolabels || []))];
// const uniqueSubcategories = [...new Set(productsresponse && productsresponse.map((product) => product.subcategory.name))];
