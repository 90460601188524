import * as user from './user';
import * as project from './project';
import * as auth from './auth';
import * as organisation from './organisation';
import * as s3 from './s3';
import * as admin from './admin';
import * as customIntervention from './customIntervention';

const apiList = {
  ...user,
  ...project,
  ...auth,
  ...organisation,
  ...s3,
  ...admin,
  ...customIntervention,
};

export default apiList;
