import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import useApiRequest from '../../../hooks/useApiRequest';
import { SUCCESS } from '../../../hooks/useApiRequest/actionTypes';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UpdateIcon from '@material-ui/icons/Update';
import DeleteIcon from '@material-ui/icons/Delete';
import { useRouteMatch, Link, useHistory } from 'react-router-dom';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SearchBar from '../../../common/Searchbar/Search';
import axios from '../../../../config/Axios';
const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  heading2: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  spacing: {
    paddingTop: '4%',
  },
  spacing2: {
    marginTop: '-3%',
  },
}));

export default function VendorInterventionMapping(props) {
  let match = useRouteMatch();
  let history = useHistory();
  const classes = useStyles();
  const [data, getData] = useState(false);
  const [mappings, setMappings] = useState('');
  const [interventions, setInterventions] = useState('');
  const [{ status, response }, makeRequest] = useApiRequest('/interventionVendorMapping', {
    verb: 'get',
  });
  const [organisations, setOrganisations] = useState('');
  useEffect(() => {
    if (!data) {
      makeRequest();
      getData(true);
    }
    if (status === SUCCESS && response) {
      setMappings(response.data.data.data);
    }
  }, [status, response, mappings, makeRequest, data]);
  useEffect(() => {
    axios
      .get('/organisationsschemev2')
      .then((response) => {
        if (response && response.data) setOrganisations(response.data.data.data);
      })
      .catch((err) => {
        console.log(err, 'error');
      });
  }, []);

  const findOrganisation = (v) => {
    const data = organisations && organisations.find((d) => d._id === v);
    return data?.name;
  };

  const handleUpdateMapping = (id) => {
    props.history.push(`/admin/intervention-vendor-mapping/update/${id}`);
  };

  return (
    <>
      <Grid container alignItems="center" item xs={12} style={{ marginBottom: '5%' }}>
        <Grid item xs={8} align="left">
          <Typography variant="h3">Intervention Vendor Mapping</Typography>
        </Grid>
        <Grid container alignItems="flex-end" xs={12} className={classes.spacing}>
          <Grid item xs={9} />
          <Grid item xs={3}>
            <Link style={{ background: 'transparent', color: 'rgba(0, 219, 153, 0.82)', width: '50%' }} to={match.url + '/create'}>
              Add New Mapping <AddCircleIcon />
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} direction="column" className={classes.spacing2}>
        {mappings &&
          mappings.map((mapping) => {
            return (
              <>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Grid container spacing={0} alignItems="center">
                      <Grid item xs={3}>
                        <Typography className={classes.heading}>
                          <b>Intervention</b>
                        </Typography>

                        <Typography className={classes.secondaryHeading}>{mapping.interventionScheme?.name}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography className={classes.heading}>
                          <b>Vendor</b>
                        </Typography>

                        <Typography className={classes.secondaryHeading}>{mapping.vendor ? mapping.vendor.vendorname : null}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography className={classes.heading}>
                          <b>Manager</b>
                        </Typography>

                        <Typography className={classes.secondaryHeading}>{mapping.contact.manager}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography className={classes.heading}>
                          <b>Email</b>
                        </Typography>

                        <Typography className={classes.secondaryHeading}>{mapping.contact.email}</Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails style={{ backgroundColor: 'rgba(0, 0, 0, 0.06)' }}>
                    <Grid container spacing={0} alignItems="center">
                      <Grid item xs={2}>
                        <Typography className={classes.heading2}>
                          <b>properties</b>
                        </Typography>
                        {mapping.properties.map((v) => {
                          return <Typography className={classes.secondaryHeading}>{v}</Typography>;
                        })}
                      </Grid>
                      <Grid item xs={3}>
                        <Typography className={classes.heading2}>
                          <b>maintaninance</b>
                        </Typography>
                        {mapping.maintainance.map((v) => {
                          return <Typography className={classes.secondaryHeading}>{v}</Typography>;
                        })}
                      </Grid>
                      <Grid item xs={3}>
                        <Typography className={classes.heading2}>
                          <b>Installation Timeline</b>
                        </Typography>
                        {mapping.installationTimeline.map((v) => {
                          return <Typography className={classes.secondaryHeading}>{v}</Typography>;
                        })}
                      </Grid>
                      <Grid item xs={2}>
                        <Typography className={classes.heading2}>
                          <b>features</b>
                        </Typography>
                        {mapping.features.map((v) => {
                          return <Typography className={classes.secondaryHeading}>{v}</Typography>;
                        })}
                      </Grid>
                      <Grid item xs={1} align="center">
                        <Typography className={classes.heading2}>
                          <b>Update</b>
                        </Typography>
                        <br />
                        <UpdateIcon onClick={() => handleUpdateMapping(mapping._id)} />
                      </Grid>
                      <Grid item xs={2} style={{ marginTop: '2%' }}>
                        <Typography className={classes.heading2}>
                          <b>Client Recomendation</b>
                        </Typography>
                        {mapping.clientRecommendations.length === 0 ? (
                          <Typography className={classes.secondaryHeading}>no-recomendations</Typography>
                        ) : (
                          mapping.clientRecommendations.map((v) => {
                            return <Typography className={classes.secondaryHeading}>{findOrganisation(v)}</Typography>;
                          })
                        )}
                      </Grid>
                      <Grid item xs={2} style={{ marginTop: '2%' }}>
                        <Typography className={classes.heading2}>
                          <b>Product Images</b>
                        </Typography>
                        {mapping.productImages.length === 0 ? (
                          <Typography className={classes.secondaryHeading}>no-Images</Typography>
                        ) : (
                          mapping.productImages.map((v) => {
                            return <Typography className={classes.secondaryHeading}>{v}</Typography>;
                          })
                        )}
                      </Grid>
                      <Grid item xs={2} style={{ marginTop: '2%' }}>
                        <Typography className={classes.heading2}>
                          <b>About Us</b>
                        </Typography>
                        <Typography className={classes.secondaryHeading}>{mapping.aboutUs}</Typography>
                      </Grid>
                      <Grid item xs={2} style={{ marginTop: '2%' }}>
                        <Typography className={classes.heading2}>
                          <b>Phone Number</b>
                        </Typography>
                        <Typography className={classes.secondaryHeading}>{mapping.contact.phone}</Typography>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </>
            );
          })}
      </Grid>
    </>
  );
}
