/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography, FormGroup, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import useApiRequest from '../../../hooks/useApiRequest';
import { SUCCESS } from '../../../hooks/useApiRequest/actionTypes';
import { Link } from 'react-router-dom';
import CustomInput from '../../../common/textfield/CustomInput';
import CustomLink from '../../../common/link/CustomLink';
import PrimaryButton from '../../../common/button/Button';
import axios from '../../../../config/Axios';
import { InputLabel, MenuItem, FormControl, Select, FormControlLabel } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import swal from 'sweetalert';
import TextField from '@material-ui/core/TextField';

import S3 from 'react-aws-s3';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { MetafireInterventions } from '../../../../metafire/utils/types';

const useStyles = makeStyles((theme) => ({
  spacing: {
    paddingTop: '4%',
  },
  form: {
    width: '100%',
    marginTop: '-1%',
  },
  formPaper: {
    background: '#FFFFFF',
    boxShadow: '2px 2px 14px rgba(0, 0, 0, 0.1)',
  },
  container: {
    paddingTop: '0%',
    paddingLeft: '3%',
    paddingBottom: '3%',
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 210,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modal2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 600,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paper2: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: '600px',
  },
}));

export default function CreateVendorInterventionMapping(props) {
  const classes = useStyles();
  const [vendors, setVendors] = useState('');
  const [interventions, setInterventions] = useState('');
  const [organisations, setOrganisations] = useState('');
  const handleClose = () => props.history.push('/admin/intervention-vendor-mapping');
  const [{ status, response }, makeRequest] = useApiRequest('/vendorsv2', {
    verb: 'get',
  });
  const [data, getData] = useState(false);
  const [intervention, setIntervention] = useState('');
  const [vandor, setVendor] = useState('');
  const [manager, setManager] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [managerPhoto, setManagerPhoto] = useState('');
  const [aboutus, setAboutUs] = useState('');
  const [capexmultiplier, setCapexMultiplier] = useState('');
  const [resourceSaving, setResourceSaving] = useState('');
  const [areaRequiredToInstall, setAreaRequiredToInstall] = useState('');
  const [installationTimeline, setInstallationTimeline] = useState('');
  const [features, setFeatures] = useState('');
  const [properties, setProperties] = useState('');
  const [maintainance, setMaintainance] = useState('');
  const [productImages] = useState([]);
  const [open, setOpen] = useState(false);

  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [file, setFile] = useState('');
  const [name, setName] = useState('');
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 9 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [openPic, setOpenPic] = useState(false);
  const [capex, setCapex] = useState(false);
  const [resource, setResource] = useState(false);
  const [area, setArea] = useState(false);
  const [textDescription, setTextDescription] = useState('');

  const handleModalClose = () => setOpen(false);
  const handleIntallationTimeline = (value) => setInstallationTimeline(value);
  const handleFeatures = (value) => setFeatures(value);
  const handleProperties = (value) => setProperties(value);
  const handleMaintainance = (value) => setMaintainance(value);

  useEffect(() => {
    if (!data) {
      makeRequest();
      getData(true);
    }
    if (status === SUCCESS && response) {
      setVendors(response.data.data.data);
    }
  }, [status, response, vendors, makeRequest, data]);
  const description = [
    {
      name: MetafireInterventions.solarPV,
      resource: 'Daily kWh generated per kWp installed',
      capex: 'Capex per kWp installed',
      area: 'Area required in sqm per kWp installed',
    },
    {
      name: MetafireInterventions.solarWaterHeater,
      resource: 'Daily kWh saved per LPD installed',
      capex: 'Capex per LPD installed',
      area: 'Area required in sqm per LPD installed',
    },
    {
      name: MetafireInterventions.wwm,
      resource: 'Not Applicable for this Intervention',
      capex: 'Capex per KG of Capacity installed',
      area: 'Area required in sqm per KG Capacity installed',
    },
    {
      name: MetafireInterventions.csp,
      resource: 'Not Applicable for this Intervention',
      capex: 'Area required in sqm per KG Capacity installed',
      area: 'Area required in sqm per KG Capacity installed',
    },
    {
      name: MetafireInterventions.sanitaryNapkinIncinerator,
      resource: 'Not Applicable for this Intervention',
      capex: 'Capex per 500 Napkins processing capacity',
      area: 'Area required in sqm per 500 Napkins processing capacity',
    },
    {
      name: MetafireInterventions.centralizedRO,
      resource: 'Not Applicable for this Intervention',
      capex: 'Capex per KL of capacity',
      area: 'Area required in sqm per KL of capacity',
    },
    {
      name: MetafireInterventions.efficientFixtures,
      resource: 'Not Applicable for this Intervention',
      capex: 'Not Applicable for this Intervention',
      area: 'Not Applicable for this Intervention',
    },
    {
      name: MetafireInterventions.dualFlush,
      resource: 'Not Applicable for this Intervention',
      capex: 'Not Applicable for this Intervention',
      area: 'Not Applicable for this Intervention',
    },
    {
      name: MetafireInterventions.swm,
      resource: 'Efficiency in % i.e. enter 0.94 if 6% savings potential',
      capex: 'Capex per daily KL capacity processed by Smart Meters',
      area: 'Not Applicable for this Intervention',
    },
    {
      name: MetafireInterventions.greyWaterReuse,
      resource: 'Not Applicable for this Intervention',
      capex: 'Capex per KL of capacity',
      area: 'Area required in sqm per KL of Capacity',
    },
    {
      name: MetafireInterventions.blackWaterReuse,
      resource: 'Not Applicable for this Intervention',
      capex: 'Capex per KL of capacity',
      area: 'Area required in sqm per KL of Capacity',
    },
    {
      name: MetafireInterventions.rooftopRWH,
      resource: 'Not Applicable for this Intervention',
      capex: 'Capex per KL of capacity',
      area: 'Area required in sqm per KL of Capacity',
    },
    {
      name: MetafireInterventions.surfaceRWH,
      resource: 'Not Applicable for this Intervention',
      capex: 'Capex per KL of capacity',
      area: 'Area required in sqm per KL of Capacity',
    },
  ];

  useEffect(() => {
    if (intervention) {
      let selected = interventions.filter((int) => intervention._id === int._id);
      let textdescription = description.filter((des) => des.name === intervention.name);
      setTextDescription(textdescription);
      let capex = selected && selected[0].multipliers.capex;
      let resource = selected && selected[0].multipliers.resource;
      let area = selected && selected[0].multipliers.area;
      setArea(area);
      setCapex(capex);
      setResource(resource);
    }
  }, [intervention]);

  useEffect(() => {
    axios
      .get('/interventionschemev2')
      .then((response) => {
        if (response && response.data) setInterventions(response.data.data.data);
      })
      .catch((err) => {
        console.log(err, 'error');
      });
    axios
      .get('/organisationsschemev2')
      .then((response) => {
        if (response && response.data) setOrganisations(response.data.data.data);
      })
      .catch((err) => {
        console.log(err, 'error');
      });
  }, []);

  const handleSubmit = () => {
    const body = {
      vendor: vandor && vandor._id,
      interventionScheme: intervention && intervention._id,
      contact: {
        manager: manager,
        phone: phone,
        email: email,
        photo: managerPhoto,
      },
      installationTimeline: installationTimeline && installationTimeline.split('|'),
      features: features && features.split('|'),
      properties: properties && properties.split('|'),
      maintainance: maintainance && maintainance.split('|'),
      aboutUs: aboutus && aboutus.split('|'),
      productImages: productImages,
      clientRecommendations: myArray && myArray.length === 0 ? ['no-recomendations'] : myArray,
      quantities: {
        capexmultiplier: capexmultiplier,
        resourceSavingsMultiplier: resourceSaving,
        areaRequiredtoInstall: areaRequiredToInstall,
      },
    };
    axios
      .post('/interventionVendorMapping', body)
      .then((response) => {
        if (response) {
          props.history.push(`/admin/intervention-vendor-mapping`);
        }
      })
      .catch((err) => {
        console.log(err, 'error');
      });
  };
  const [myArray, setMyArray] = useState([]);

  const appendChanged = (id) => {
    let n =
      myArray &&
      myArray.find((data) => {
        return data === id;
      });
    if (n) {
      n = id;
      setMyArray((oldArray) => [...oldArray]);
    } else {
      setMyArray((oldArray) => [...oldArray, id]);
    }
  };
  const onSelectProductFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
      console.log(e, 'type');
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setOpenPic(true);
      setName('product');
    }
  };
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
      console.log(e, 'type');
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setOpenPic(true);
      setName('manager');
    }
  };
  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);
  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    console.log(ctx, 'ctx');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';
    ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);
  }, [completedCrop]);
  const handlepicModalClose = () => {
    setOpenPic(false);
    setFile('');
    setUpImg('');
    imgRef.current = null;
  };
  const handleUpload = () => {
    if (file) {
      const config = {
        bucketName: process.env.REACT_APP_AWS_BUCKET_NAME,
        region: 'us-east-2',
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        dirName: 'public/vendors',
        ContentType: file.type,
        ACL: 'public-read',
      };
      const ReactS3Client = new S3(config);
      ReactS3Client.uploadFile(file, file.name)
        .then((response) => {
          if (response && response.status === 204) {
            const body = {
              URL: response.location,
            };
            axios
              .post('/sign-s3', body)
              .then((res) => {
                if (res && res.data.data && name === 'product') {
                  productImages.push(res.data.data.data._id);
                } else if (res && res.data.data && name === 'manager') {
                  setManagerPhoto(res.data.data.data._id);
                }
                setFile('');
                setOpen(false);
                setOpenPic(false);
                setName('');
                setUpImg('');
                handlepicModalClose();
                swal({ icon: 'success', title: 'Successfully Uploaded Image. You can upload multiple images only for product images.' });
              })
              .catch((err) => {
                console.log(err, 'err');
              });
          }
        })
        .catch((err) => console.error(err));
    }
  };
  return (
    <>
      <Grid container alignItems="center" item xs={12} style={{ marginBottom: '5%' }}>
        <Grid item xs={8} align="left">
          <Typography variant="h3">Create Intervention Vendor Mapping</Typography>
        </Grid>

        <Grid container alignItems="center" item xs={12} style={{ marginTop: '3%' }}>
          <form className={classes.form} noValidate>
            <Paper className={classes.formPaper}>
              <>
                <Grid container alignItems="center" item xs={12} className={classes.container}>
                  <Grid item xs={3}>
                    <Grid direction="column" style={{ marginTop: '5%' }}>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">Select Intervention</InputLabel>
                        <Select labelId="demo-simple-select-label" id="demo-simple-select">
                          {interventions && interventions
                            ? interventions.map((intervention) => (
                                <MenuItem
                                  onClick={() => setIntervention(intervention)}
                                  value={intervention && intervention.name}
                                  name={intervention && intervention.name}
                                >
                                  {intervention && intervention.name}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid direction="column">
                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">Select Vendor</InputLabel>
                        <Select labelId="demo-simple-select-label" id="demo-simple-select">
                          {vendors && vendors
                            ? vendors.map((vendor) => (
                                <MenuItem onClick={() => setVendor(vendor)} value={vendor && vendor.vendorname} name={vendor && vendor.vendorname}>
                                  {vendor && vendor.vendorname}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid direction="column">
                      <CustomInput
                        margin="normal"
                        required
                        value={manager}
                        onChange={(e) => setManager(e.target.value)}
                        placeholder="Manager"
                        fullWidth
                        id="standard-multiline-flexible"
                        label="Manager name"
                        name="manager"
                      />
                    </Grid>
                    <Grid direction="column">
                      <CustomInput
                        margin="normal"
                        required
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="Phone"
                        fullWidth
                        id="standard-multiline-flexible"
                        label="Phone Number"
                        name="email"
                      />
                    </Grid>
                    <Grid direction="column">
                      <CustomInput
                        margin="normal"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        fullWidth
                        id="standard-multiline-flexible"
                        label="Email"
                        name="email"
                      />
                    </Grid>
                    <Grid direction="column">
                      <CustomInput
                        margin="normal"
                        required
                        value={installationTimeline}
                        onChange={(e) => handleIntallationTimeline(e.target.value)}
                        placeholder="Installation Timeline"
                        fullWidth
                        id="standard-multiline-flexible"
                        label="Installation Timeline"
                        name="installation timeline"
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={1} />

                  <Grid item xs={3}>
                    <Grid direction="column">
                      <CustomInput
                        margin="normal"
                        required
                        value={features}
                        onChange={(e) => handleFeatures(e.target.value)}
                        placeholder="Features"
                        fullWidth
                        id="standard-multiline-flexible"
                        label="Features"
                        name="features"
                      />
                    </Grid>
                    <Grid direction="column">
                      <CustomInput
                        margin="normal"
                        required
                        value={properties}
                        onChange={(e) => handleProperties(e.target.value)}
                        placeholder="Properties"
                        fullWidth
                        id="standard-multiline-flexible"
                        label="Properties"
                        name="prorperties"
                      />
                    </Grid>
                    <Grid direction="column">
                      <CustomInput
                        margin="normal"
                        required
                        value={maintainance}
                        onChange={(e) => handleMaintainance(e.target.value)}
                        placeholder="Maintainance"
                        fullWidth
                        id="standard-multiline-flexible"
                        label="Maintainance"
                        name="Maintainance"
                      />
                    </Grid>
                    <br />
                    <br />
                    <Grid direction="column">
                      <div>
                        <label class="custom-file-upload">Product Images</label>
                        <input type="file" accept="image/*" onChange={onSelectProductFile} />
                      </div>
                    </Grid>

                    <Grid direction="column" style={{ marginTop: '5%' }}>
                      <Link style={{ background: 'transparent', color: 'rgba(0, 219, 153, 0.82)', width: '100%' }} onClick={() => setOpen(true)}>
                        select clients <AddCircleIcon />
                      </Link>
                    </Grid>
                  </Grid>

                  <Grid item xs={1} />

                  <Grid item xs={3}>
                    <Grid direction="column">
                      <CustomInput
                        margin="normal"
                        required
                        value={aboutus}
                        onChange={(e) => setAboutUs(e.target.value)}
                        placeholder="About Us"
                        fullWidth
                        id="standard-multiline-flexible"
                        label="About Us"
                        name="about us"
                      />
                    </Grid>

                    <Grid direction="column" className={classes.container} style={{ marginTop: '5%' }}>
                      <Typography variant="h6">Capex Multiplier</Typography>
                      {textDescription ? (
                        <Typography variant="h6" style={{ color: '#666666', fontSize: '12px', paddingTop: '2%' }}>
                          {textDescription[0].capex}
                        </Typography>
                      ) : (
                        ''
                      )}
                      {capex === true ? (
                        <TextField
                          margin="normal"
                          placeholder="Capex Multiplier*"
                          required
                          value={capexmultiplier}
                          onChange={(e) => setCapexMultiplier(e.target.value)}
                          fullWidth
                          id="standard-basic"
                          variant="standard"
                        />
                      ) : (
                        <TextField
                          margin="normal"
                          placeholder="Capex Multiplier*"
                          required
                          value={capexmultiplier}
                          onChange={(e) => setCapexMultiplier(e.target.value)}
                          fullWidth
                          disabled
                          id="standard-basic"
                          variant="standard"
                        />
                      )}
                    </Grid>
                    <Grid direction="column" className={classes.container}>
                      <Typography variant="h6">Resource Multiplier</Typography>
                      {textDescription ? (
                        <Typography variant="h6" style={{ color: '#666666', fontSize: '12px', paddingTop: '2%' }}>
                          {textDescription[0].resource}
                        </Typography>
                      ) : (
                        ''
                      )}
                      {resource === true ? (
                        <TextField
                          margin="normal"
                          placeholder="Resource Multiplier*"
                          required
                          value={resourceSaving}
                          onChange={(e) => setResourceSaving(e.target.value)}
                          fullWidth
                          id="standard-basic"
                        />
                      ) : (
                        <TextField
                          margin="normal"
                          placeholder="Resource Multiplier*"
                          required
                          disabled
                          value={resourceSaving}
                          onChange={(e) => setResourceSaving(e.target.value)}
                          fullWidth
                          id="standard-basic"
                        />
                      )}
                    </Grid>
                    <Grid direction="column" className={classes.container}>
                      <Typography variant="h6">Area Multiplier</Typography>
                      {textDescription ? (
                        <Typography variant="h6" style={{ color: '#666666', fontSize: '12px', paddingTop: '2%' }}>
                          {textDescription[0].area}
                        </Typography>
                      ) : (
                        ''
                      )}
                      {area === true ? (
                        <TextField
                          margin="normal"
                          placeholder="Area Multiplier*"
                          required
                          value={areaRequiredToInstall}
                          onChange={(e) => setAreaRequiredToInstall(e.target.value)}
                          fullWidth
                          id="standard-basic"
                        />
                      ) : (
                        <TextField
                          margin="normal"
                          placeholder="Area Multiplier*"
                          required
                          disabled
                          value={areaRequiredToInstall}
                          onChange={(e) => setAreaRequiredToInstall(e.target.value)}
                          fullWidth
                          id="standard-basic"
                        />
                      )}
                    </Grid>

                    <Grid direction="column" className={classes.container}>
                      <div>
                        <label class="custom-file-upload">Manager Photo</label>
                        <input type="file" accept="image/*" onChange={onSelectFile} />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            </Paper>
            <Grid container item xs={12} className={classes.spacing}>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <Grid container item xs={12}>
                  <Grid item xs={6}>
                    <CustomLink onClick={handleClose} activeOnlyWhenExact={true} label={<Typography variant="h5">CANCEL </Typography>} />
                  </Grid>
                  <Grid item xs={6}>
                    <PrimaryButton children="ADD" onClick={() => handleSubmit()} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Typography gutterBottom variant="h5" component="h2">
              Choose Clients Recommend this vendor
            </Typography>
            <Typography gutterBottom variant="h6" component="h2">
              <b>IMPORTANT</b>: Once Selected only those values will get added for the vendor.
            </Typography>
            <br />
            <FormGroup row>
              {organisations &&
                organisations.map((v) => {
                  return (
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={false}
                            onChange={() => {
                              appendChanged(v._id);
                            }}
                            name={v.name}
                          />
                        }
                        label={v.name}
                      />
                    </>
                  );
                })}
            </FormGroup>
            <br />
            <Link size="small" color="primary" onClick={handleModalClose}>
              Add
            </Link>
            <Link size="small" color="primary" onClick={handleModalClose} style={{ marginLeft: '10%' }}>
              Cancel
            </Link>
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal2}
        open={openPic}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openPic}>
          <div className={classes.paper2}>
            <Grid container alignItems="center" item xs={12} style={{ marginBottom: '3%' }}>
              <Grid item xs={6} align="left">
                <Grid item xs={12}>
                  <ReactCrop src={upImg} onImageLoaded={onLoad} crop={crop} onChange={(c) => setCrop(c)} onComplete={(c) => setCompletedCrop(c)} />
                  <div>
                    <canvas
                      ref={previewCanvasRef}
                      style={{
                        width: Math.round(completedCrop?.width ?? 0),
                        height: Math.round(completedCrop?.height ?? 0),
                        maxHeight: '600px',
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <br />
            <br />
            <Link size="small" color="primary" onClick={handleUpload}>
              Upload
            </Link>
            <Link size="small" color="primary" onClick={handlepicModalClose} style={{ marginLeft: '10%' }}>
              Cancel
            </Link>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
