import React, { useContext, useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Drawer, Grid, Paper, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import NoteField from './common/TextField';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import PrimaryButton from '../../../../common/button/Button';
import UploadImage from './UploadImageModal';
import { UserContext } from '../../../../../routing/IndexRouting';
import { useParams } from 'react-router';
import axios from '../../../../../config/Axios';
import NoteCard from './common/NoteCard';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ImageViewer from './common/ImageViewer';
import { NotetakingContext } from '../../../../../projectRouting/Index';
import ApiError from '../../../../common/Error/ApiError';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '500px',
    zIndex: 9998,
  },
  drawerPaper: {
    width: '500px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
  },
  drawerContainer: {
    paddingBottom: '20%',
  },
  drawerHead: {
    fontFamily: 'Lato',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0.7142857313156128px',
    textAlign: 'left',
  },
  spacing: {
    paddingTop: '20%',
    paddingLeft: '3%',
    paddingRight: '3%',
    paddingBottom: '3%',
  },
  subHead: {
    fontFamily: 'Lato',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '16.8px',
    letterSpacing: '0.7142857313156128px',
  },
  textSmall: {
    fontFamily: 'Lato',
    fontSize: '8px',
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: '0.7142857313156128px',
    color: 'rgba(102, 102, 102, 1)',
  },
  gridstyle: {
    margin: '2% 0',
    display: 'flex',
    alignItems: 'center',
  },
  addboxiconstyle: {
    color: 'rgb(102, 227, 190)',
    width: '35px',
    height: '70%',
  },
  labelstyle: {
    color: 'rgb(102, 227, 190)',
    cursor: 'pointer',
    fontSize: '16px',
  },
  imagegridstyle: {
    paddingTop: '15px',
  },
  imagestyle: {
    marginRight: '10px',
    cursor: 'pointer',
    border: '1px solid rgba(204, 204, 204, 1)',
    borderRadius: '8px',
    '&:hover': {
      border: '1px solid rgba(73, 221, 177, 1)',
    },
  },
  bottompaperstyle: {
    position: 'fixed',
    bottom: 0,
    width: '500px',
    height: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
  },
  headerStyle: {
    display: 'flex',
    alignItems: 'center',
  },
  closeiconStyle: {
    color: 'rgba(102, 102, 102, 1)',
    cursor: 'pointer',
    marginRight: '8px',
  },
  subheadGridStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: '5%',
    marginBottom: '2%',
  },
  imagemodaltypo: {
    display: 'flex',
    alignItems: 'center',
    color: 'rgba(73, 221, 177, 1)',
    cursor: 'pointer',
  },
  insertphotoiconstyle: {
    fontSize: 'large',
    color: 'rgba(73, 221, 177, 1)',
    marginRight: '5px',
  },
  buttonStyle: {
    borderColor: '#00C58A',
    width: '30%',
  },
  closebuttonstyle: {
    borderColor: '#00C58A',
    right: 16,
    position: 'fixed',
    width: '10%',
  },
}));
function NoteTaking({ notetaking, setNotetaking, allnotes, makeapirequest, fetchAllNotes, isErrorFetchingNotes, category }) {
  const { notetakingContext, handleNotetakingContext } = useContext(NotetakingContext);
  const [addEditNote, setAddEditNote] = useState(false);

  const classes = useStyles();
  const drawerContentRef = useRef(null);
  const { userDetails } = useContext(UserContext);
  const { id, iid } = useParams();
  const [imageModal, setImageModal] = useState(false);
  const [statcAssetids, setStaticAssetIds] = useState([]);
  const [note, setNote] = useState('');
  const [Uploadedimagesurls, setUploadedimageURLS] = useState([]);
  const [noteid, setNoteid] = useState('');
  const [characters, setCharacters] = useState(0);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (allnotes && allnotes.length === 0) {
      setAddEditNote(true);
    }
  }, [allnotes]);

  useEffect(() => {
    setCharacters(note.length);
  }, [note]);
  const [disableButton, setDisableButton] = useState(false);

  const handleNotetaking = () => setNotetaking(!notetaking);

  const handleSaveNote = () => {
    setDisableButton(true);
    if (noteid === '' && !disableButton) {
      let body = {
        userid: userDetails && userDetails._id,
        note: note,
        staticAssetids: statcAssetids,
        projectId: id,
        interventionMappingId: iid,
        category: category,
      };
      axios
        .post(`/note-taking`, body)
        .then(() => {
          setStaticAssetIds([]);
          fetchAllNotes();
          setNote('');
          setUploadedimageURLS([]);
          setCharacters(0);
          setNoteid('');
          setDisableButton(false);
          setAddEditNote(!addEditNote);
          setImageModal(false);
        })
        .catch((err) => console.log(err, 'err'));
    } else if (noteid !== '' && !disableButton) {
      let body = {
        note: note,
        staticassetids: statcAssetids,
      };
      axios
        .put(`/note-taking/${noteid}`, body)
        .then(() => {
          setStaticAssetIds([]);
          setAddEditNote(!addEditNote);
          fetchAllNotes();
          setNote('');
          setUploadedimageURLS([]);
          setCharacters(0);
          setNoteid('');
          setDisableButton(false);
          setImageModal(false);
        })
        .catch((err) => console.log(err, 'err'));
    }
  };

  const handleClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    handleNotetakingContext();
    handleNotetaking();
    setStaticAssetIds([]);
    handleNotetaking(false);
    setUploadedimageURLS([]);
    setNote('');
    setCharacters(0);
    setNoteid('');
    setAddEditNote(false);
    setImageModal(false);
  };

  const handleNoteField = (e) => {
    const inputText = e.target.value;
    if (inputText.length <= 500) {
      setNote(inputText);
    } else {
      setNote(inputText.slice(0, 500));
    }
  };

  function sortByLastEditedAt(data) {
    return data.sort((a, b) => {
      const dateA = new Date(a.lastEditedAt);
      const dateB = new Date(b.lastEditedAt);
      return dateB - dateA; // Sort in descending order
    });
  }

  return (
    <>
      <Drawer
        anchor="right"
        open={notetaking}
        onClose={handleClose}
        style={
          {
            // position: 'absolute',
            // zIndex: notetakingContext ? 9000 : 0
          }
        }
        variant={notetaking ? 'permanent' : ''}
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <div className={classes.drawerContainer} ref={drawerContentRef}>
          {/* Content of the Drawer */}
          <Grid container item xs={12} direction="column" className={classes.spacing}>
            <Grid>
              <Typography className={`${classes.drawerHead} ${classes.headerStyle}`}>
                <CloseIcon onClick={handleClose} className={`${classes.drawerHead} ${classes.closeiconStyle}`} />
                Intervention Notes
              </Typography>
            </Grid>
            {isErrorFetchingNotes ? (
              <div style={{ marginTop: 100 }}>
                <ApiError />
              </div>
            ) : (
              <>
                {addEditNote ? (
                  <>
                    <Grid className={classes.subheadGridStyle}>
                      <Typography style={{ textAlign: 'left' }} className={classes.subHead}>
                        {noteid ? 'Edit Note' : 'Add Note'}
                      </Typography>
                      <Typography style={{ textAlign: 'right' }} className={classes.textSmall}>
                        {characters}/500 characters
                      </Typography>
                    </Grid>
                    {/* <Grid container item xs={12}> */}
                    <NoteField style={{ paddingTop: '3%' }} value={note} onChange={handleNoteField} />
                    {/* </Grid> */}
                    <Grid className={classes.subheadGridStyle}>
                      <Typography className={`${classes.subHead} ${classes.imagemodaltypo}`} onClick={() => setImageModal(true)}>
                        <InsertPhotoIcon className={classes.insertphotoiconstyle} />
                        {Uploadedimagesurls.length !== 0 ? 'Add/Remove Images' : 'Attach Images'}
                      </Typography>
                      <PrimaryButton onClick={handleSaveNote} children="Save" disabled={!note?.length} className={classes.buttonStyle} />
                    </Grid>
                    {Uploadedimagesurls.length !== 0 && (
                      <Grid container item xs={12} className={classes.imagegridstyle}>
                        {Uploadedimagesurls &&
                          Uploadedimagesurls.map((assets) => (
                            <img src={assets?.URL} alt="..." height="75px" width="75px" className={classes.imagestyle} onClick={handleClick} />
                          ))}
                      </Grid>
                    )}
                    <UploadImage
                      Uploadedimagesurls={Uploadedimagesurls}
                      setUploadedimageURLS={setUploadedimageURLS}
                      imageModal={imageModal}
                      setImageModal={setImageModal}
                      statcAssetids={statcAssetids}
                      setStaticAssetIds={setStaticAssetIds}
                    />
                  </>
                ) : (
                  <div className={classes.gridstyle} onClick={() => setAddEditNote(true)}>
                    <AddBoxIcon className={classes.addboxiconstyle} />
                    <Typography className={classes.labelstyle}>ADD NOTE</Typography>
                  </div>
                )}
                {allnotes &&
                  sortByLastEditedAt(allnotes).map((ele) => {
                    return (
                      <NoteCard
                        data={ele}
                        allnotes={allnotes}
                        fetchAllNotes={fetchAllNotes}
                        setHideAddnotes={(value) => setAddEditNote(!value)}
                        setNote={setNote}
                        Uploadedimagesurls={Uploadedimagesurls}
                        setUploadedimageURLS={setUploadedimageURLS}
                        setNoteid={setNoteid}
                        statcAssetids={statcAssetids}
                        drawerContentRef={drawerContentRef}
                        makeapirequest={makeapirequest}
                      />
                    );
                  })}
                <ImageViewer staticassetids={Uploadedimagesurls} showModal={showModal} setShowModal={setShowModal} />
              </>
            )}
          </Grid>
        </div>
        <Paper className={classes.bottompaperstyle}>
          <PrimaryButton onClick={handleClose} children="Close" className={classes.closebuttonstyle} />
        </Paper>
      </Drawer>
    </>
  );
}

export default NoteTaking;
