/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';
import useInterventionDetails from '../../../marketplace/hooks/useInterventionDetailsData';
import DummyAddProductCard from '../../../marketplace/common/DummyAddProductCard';
import { useParams } from 'react-router';
import { useInterventionDetailsContext } from '../context/InterventionDetails';
import InterventionProductCard from '../main/InterventionProductCard';
import PrimaryButton from '../../../../common/button/Button';

const useStyles = makeStyles((theme) => ({
  vendorGrid: {
    marginTop: '3%',
  },
  textwithrefresh: {
    display: 'flex',
    alignItems: 'center',
  },
  refreshicon: {
    color: 'rgba(38, 224, 163, 1)',
    cursor: 'pointer',
  },
  defaultText: {
    fontFamily: 'Lato',
    fontSize: '11px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.7142857313156128px',
    textAlign: 'left',
  },
  textForNoProduct: {
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '32px',
    letterSpacing: '0.7142857313156128px',
    textAlign: 'left',
    color: 'rgba(102, 102, 102, 1)',
  },
}));

const widthstyle = {
  width: '220px',
};

export const MarketPlaceLink = ({ interventionname }) => {
  const classes = useStyles();
  const { id, iid } = useParams();
  const {
    product,
    key,
    get: { getShortlistedProductsForCombination, getCategorySubCategoryAndFilterForCombination, getProjectInterventionMapping },
    set: { updateSelectedProduct, removeSelectedProductFromShortlist },
  } = useInterventionDetailsContext();
  const defaultProduct = product?.default || null;
  const selectedProduct = product?.selected;
  const { productDetailsData, fetchData: fetchProductDetailsData } = useInterventionDetails();

  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProductData, setSelectedProductData] = useState({});
  const [isCompareModeEnabled, setIsCompareModeEnabled] = useState(false);
  const [productSelectedForComparison, setProductSelectedForComparison] = useState([]);

  const moveToProductCompareTab = () => {
    if (productSelectedForComparison.length > 0)
      window.open(
        `/projects/${id}/interventions/${iid}/product-comparison?selectedProducts=${productSelectedForComparison.join(
          ','
        )}&source=interventionDetails&projectId=${id}&interventionId=${iid}&interventionKey=${key}&selectedProduct=${selectedProduct}`,
        '_blank'
      );
  };

  useEffect(() => {
    if (product?.productIdentifiers?.length) {
      fetchProductDetailsData(product?.productIdentifiers?.join());
    }
  }, [product?.productIdentifiers]);

  useEffect(() => {
    if (productDetailsData?.products && defaultProduct && selectedProduct) {
      const filteredProducts = getShortlistedProductsForCombination([...productDetailsData?.products]).reduce((memo, item) => {
        if (item.catalog_sku_id == defaultProduct) {
          memo = [item, ...memo];
        } else {
          memo = [...memo, item];
        }
        return memo;
      }, []);
      const getSelectedProductData = productDetailsData?.products?.find((ele) => ele.catalog_sku_id === selectedProduct);
      const subCategoryAndFilter = getCategorySubCategoryAndFilterForCombination(getSelectedProductData);
      setFilteredProducts(filteredProducts);
      setSelectedProductData(subCategoryAndFilter);
    }
  }, [productDetailsData, defaultProduct, selectedProduct]);

  const handleRefresh = async () => {
    await getProjectInterventionMapping();
  };

  return (
    <Grid container direction="column" item xs={12} className={classes.vendorGrid}>
      {
        <Grid container direction="column" item xs={3}>
          <Typography variant="h5">Selected Product</Typography>
        </Grid>
      }
      {defaultProduct === null || !defaultProduct ? (
        <Typography className={classes.textForNoProduct}>No products are required to meet the criteria for this intervention.</Typography>
      ) : (
        <>
          {productDetailsData?.products &&
            selectedProduct &&
            productDetailsData?.products?.map((element) => {
              if (element.catalog_sku_id === selectedProduct) {
                return (
                  <div>
                    <InterventionProductCard
                      isCompareModeEnabled={isCompareModeEnabled}
                      product={element}
                      defaultProduct={defaultProduct}
                      selectedProduct={selectedProduct}
                      section="selected"
                      setProductSelectedForComparison={setProductSelectedForComparison}
                      productSelectedForComparison={productSelectedForComparison}
                    />
                  </div>
                );
              }
            })}
          {filteredProducts && filteredProducts.length !== 0 ? (
            <Box>
              <Grid style={{ height: '75px', marginBottom: '1%' }} container direction="row" item xs={12} alignItems="center" justifyContent="space-between">
                <Typography variant="h5" className={classes.textwithrefresh} style={{ textAlign: 'left' }}>
                  Shortlisted Products
                  <RefreshIcon className={classes.refreshicon} onClick={handleRefresh} />
                </Typography>

                <Box
                  container
                  style={{
                    marginRight: '35px',
                    width: '400px',
                    height: '75px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  {isCompareModeEnabled && (
                    <Typography
                      onClick={() => {
                        setIsCompareModeEnabled(false);
                        setProductSelectedForComparison([]);
                      }}
                      style={{
                        color: '#666666',
                        height: '12px',
                        width: '12px',
                        fontWeight: 'bold',
                        marginRight: '2%',
                        cursor: 'pointer',
                      }}
                    >
                      X
                    </Typography>
                  )}
                  <Box
                    xs={7}
                    style={{
                      marginRight: '2%',
                      marginTop: isCompareModeEnabled ? '6%' : '',
                      display: 'flex',
                      flexDirection: 'column',
                      textAlign: 'center',
                      width: '180px',
                    }}
                  >
                    <PrimaryButton
                      children={isCompareModeEnabled ? `compare selected (${productSelectedForComparison.length})` : 'add to compare'}
                      onClick={() => {
                        if (!isCompareModeEnabled) setIsCompareModeEnabled(!isCompareModeEnabled);
                        if (isCompareModeEnabled) moveToProductCompareTab();
                      }}
                      style={{
                        fontWeight: '700',
                        fontSize: '12px',
                        lineHeight: '14px',
                        width: '100%',
                        boxShadow: 'none',
                      }}
                    />
                    {isCompareModeEnabled && <Typography style={{ marginTop: '4%', color: '#666666', fontSize: '8px' }}>ADD UP TO 5 PRODUCTS</Typography>}
                  </Box>
                </Box>
              </Grid>

              <Grid container direction="row" item xs={12}>
                <DummyAddProductCard
                  widthstyle={widthstyle}
                  mappingid={iid}
                  categoryName={selectedProductData?.category}
                  filters={selectedProductData?.filter}
                  subcategoryName={selectedProductData?.subCategory}
                />
                {filteredProducts &&
                  filteredProducts.map((product) => {
                    return (
                      <div style={{ marginRight: '2%' }}>
                        <InterventionProductCard
                          isCompareModeEnabled={isCompareModeEnabled}
                          setProductSelectedForComparison={setProductSelectedForComparison}
                          productSelectedForComparison={productSelectedForComparison}
                          defaultProduct={defaultProduct}
                          selectedProduct={selectedProduct}
                          product={product}
                          section="shortlisted"
                          onRemoveProduct={async () => {
                            await removeSelectedProductFromShortlist(product);
                            await handleRefresh();
                          }}
                          onSelectProduct={() => updateSelectedProduct(product?.catalog_sku_id)}
                        />
                      </div>
                    );
                  })}
              </Grid>
            </Box>
          ) : (
            <>
              <Grid container direction="column" item xs={3}>
                <Typography variant="h5" className={classes.textwithrefresh}>
                  Shortlisted Products
                  <RefreshIcon className={classes.refreshicon} onClick={handleRefresh} />
                </Typography>
              </Grid>
              <Grid container direction="row" item xs={12}>
                <DummyAddProductCard
                  widthstyle={widthstyle}
                  mappingid={iid}
                  categoryName={selectedProductData?.category}
                  filters={selectedProductData?.filter}
                  subcategoryName={selectedProductData?.subCategory}
                />
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  );
};
