import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
// import { Link } from 'react-router-dom';
// import ShowSaveAndCancel from './showSaveAndCancel';
// import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ResetFields from '../common/textFiledwithReset';
// import numberWithCommas from '../../../../../utils/numberToComma';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';

const WWMCustomAdvancedParameters = (props) => {
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityDefaultValue, getQuantityLabel, getQuantityUnit },
    set: { updateQuantityValue },
  } = useInterventionDetailsContext();

  return (
    <Grid container direction="column" alignItems="left" item xs={12}>
      <Grid xs={4}>
        <Grid xs={12} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6">Intervention Cost Assumptions</Typography>
          <CustomizedTooltips icon="info" title={<Typography>Changes will only affect this intervention</Typography>} placement="right" />
        </Grid>
        <Typography color="textSecondary" variant="h6" style={{ marginTop: '5%' }}>
          {'CAPEX (Per unit)'}
        </Typography>
      </Grid>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Grid xs={4} style={{ display: 'flex', flexDirection: 'column' }}>
          <Grid xs={6} style={{ display: 'flex', alignItems: 'center' }}>
            {(getQuantityValue(schema.wwm__pi_capexMultiplierSource) === 'CUSTOM' || getQuantityValue(schema.wwm__pi_capexMultiplierSource) === '') && (
              <RadioButtonUncheckedIcon
                className={classes.selectedCheckbox}
                onClick={() => {
                  updateQuantityValue(schema.wwm__pi_capexMultiplierSource, 'VENDOR');
                }}
              />
            )}
            {getQuantityValue(schema.wwm__pi_capexMultiplierSource) === 'VENDOR' && (
              <CheckCircleIcon
                className={classes.selectedCheckbox}
                onClick={() => {
                  updateQuantityValue(schema.wwm__pi_capexMultiplierSource, 'VENDOR');
                }}
              />
            )}
            <Typography>Selected Vendor</Typography>
          </Grid>
          <Grid style={{ display: 'flex', alignItems: 'center', marginLeft: '15%' }}>
            <Typography>{getQuantityValue(schema.wwm_vendorCapex)}</Typography>
            <Typography style={{ marginLeft: '2%' }}>Rs</Typography>
          </Grid>
        </Grid>
        <Grid xs={4} style={{ display: 'flex', flexDirection: 'column' }}>
          <Grid xs={6} style={{ display: 'flex', alignItems: 'center' }}>
            {(getQuantityValue(schema.wwm__pi_capexMultiplierSource) === 'VENDOR' || getQuantityValue(schema.wwm__pi_capexMultiplierSource) === '') && (
              <RadioButtonUncheckedIcon
                className={classes.selectedCheckbox}
                onClick={() => {
                  updateQuantityValue(schema.wwm__pi_capexMultiplierSource, 'CUSTOM');
                }}
              />
            )}
            {getQuantityValue(schema.wwm__pi_capexMultiplierSource) === 'CUSTOM' && (
              <CheckCircleIcon
                className={classes.selectedCheckbox}
                onClick={() => {
                  updateQuantityValue(schema.wwm__pi_capexMultiplierSource, 'CUSTOM');
                }}
              />
            )}
            <Typography>Custom</Typography>
          </Grid>
          <Grid style={{ display: 'flex', alignItems: 'center' }}>
            <ResetFields
              id="outlined-basic"
              variant="outlined"
              value={getQuantityValue(schema.wwm__pi_customCapex)}
              placeholder=""
              fullWidth
              required
              onChange={(e) => {
                e.preventDefault();
                updateQuantityValue(schema.wwm__pi_customCapex, e.target.value);
              }}
              onClick={() => updateQuantityValue(schema.wwm__pi_customCapex, getQuantityDefaultValue(schema.wwm__pi_customCapex))}
              disabled={getQuantityValue(schema.wwm__pi_capexMultiplierSource) !== 'CUSTOM' ? true : false}
              removeResetIcon={true}
            />
            <Typography
              style={{ marginLeft: '2%' }}
              className={getQuantityValue(schema.wwm__pi_capexMultiplierSource) !== 'CUSTOM' ? classes.textDisable : undefined}
            >
              Rs
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Grid xs={12} style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h6">Project Cost Assumptions</Typography>
        <CustomizedTooltips icon="info" title={<Typography>Changes may affect other project or intervention values</Typography>} placement="right" />
      </Grid>
      <Grid container item xs={12} style={{ paddingTop: '10px' }}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px', display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.wwm__pi_lpgPricePerKg)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.wwm__pi_lpgPricePerKg)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.wwm__pi_lpgPricePerKg, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.wwm__pi_lpgPricePerKg, getQuantityDefaultValue(schema.wwm__pi_lpgPricePerKg));
                }}
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.wwm__pi_lpgPricePerKg)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.wwm__pi_residentialUtilityTariff)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.wwm__pi_residentialUtilityTariff)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.wwm__pi_residentialUtilityTariff, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.wwm__pi_residentialUtilityTariff, getQuantityDefaultValue(schema.wwm__pi_residentialUtilityTariff));
                }}
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.wwm__pi_residentialUtilityTariff)}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Typography className={classes.textHeader1} style={{ paddingTop: '3%' }}>
        Constants
      </Typography>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.wwm_kgLpgPerKgWetWaste)}</Typography>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(schema.wwm_kgLpgPerKgWetWaste)} {getQuantityUnit(schema.wwm_kgLpgPerKgWetWaste)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.wwm_kwhPerBiogasCum)}</Typography>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(schema.wwm_kwhPerBiogasCum)} {getQuantityUnit(schema.wwm_kwhPerBiogasCum)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.wwm_wetContentPerKgWaste)}</Typography>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(schema.wwm_wetContentPerKgWaste)} {getQuantityUnit(schema.wwm_wetContentPerKgWaste)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.wwm_kgToCumConversion)}</Typography>
          <Typography className={classes.textHeader3}>{getQuantityValue(schema.wwm_kgToCumConversion)}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.wwm_emissionFactorForLpg)}</Typography>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(schema.wwm_emissionFactorForLpg)} {getQuantityUnit(schema.wwm_emissionFactorForLpg)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WWMCustomAdvancedParameters;
