// React and hooks
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';

// Material UI components
import { Modal, Box, Typography, Grid, CircularProgress } from '@material-ui/core';

// Custom components
import UploadComponent from '../../../../users/admin/imageUpload/ImageUpload';
import PrimaryButton from '../../../../../common/button/Button';
import { FormField, formFields } from './form.components';

// API and hooks
import { useAppApiRequest } from '../../../../../../hooks/useAppApiRequest';
import api from '../../../../../../http';

// Styles
export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    borderRadius: theme.spacing(1),
    width: '50%',
    maxWidth: '90%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  actions: {
    marginTop: theme.spacing(5),
  },
}));

const OrganizationFormModal = ({ open, onClose, initialData = null, selectedOrganization, setSelectedOrganization, refetchOrganizations }) => {
  const classes = useStyles();
  const [formData, setFormData] = React.useState({
    organizationName: '',
    organizationCode: '',
    ownerEmail: '',
    organizationType: '',
    subscriptionType: '',
    numberOfUsers: '',
  });
  const [errors, setErrors] = React.useState({});
  const [createOrg, setCreateOrg] = useState(false);
  const [updateOrg, setUpdateOrg] = useState(false);

  const { data, isFetching, refetch } = useAppApiRequest(
    () =>
      api.postOrganisation({
        name: formData.organizationName,
        code: formData.organizationCode,
        email: formData.ownerEmail,
        type: formData.organizationType,
        subscriptionType: formData.subscriptionType,
        numberOfUsers: formData.numberOfUsers,
      }),
    { method: 'POST' },
    [],
    createOrg
  );

  const {
    data: editedOrgDetails,
    isFetching: processingEditOrgDetails,
    refetch: editOrgDetails,
  } = useAppApiRequest(
    () =>
      api.putOrganisation(selectedOrganization?._id, {
        name: formData.organizationName,
      }),
    { method: 'PUT' },
    [],
    updateOrg
  );

  useEffect(() => {
    if (data) {
      setSelectedOrganization(null);
      onClose();
      setCreateOrg(false);
      refetchOrganizations();
      setErrors({});
    }
  }, [data]);

  useEffect(() => {
    if (editedOrgDetails) {
      setSelectedOrganization(null);
      onClose();
      setUpdateOrg(false);
      refetchOrganizations();
      setErrors({});
    }
  }, [editedOrgDetails]);

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
    } else {
      setFormData({
        organizationName: '',
        organizationCode: '',
        ownerEmail: '',
        organizationType: '',
        subscriptionType: '',
        numberOfUsers: '',
      });
    }
    setErrors({});
  }, [initialData]);

  const validateForm = () => {
    const newErrors = {};

    if (!formData.organizationName.trim()) {
      newErrors.organizationName = 'Organization Name is required';
    }

    if (!formData.organizationCode) {
      newErrors.organizationCode = 'Organization Code is required';
    } else if (formData.organizationCode.length > 3) {
      newErrors.organizationCode = 'Maximum 3 characters allowed';
    } else if (!/^[A-Z]*$/.test(formData.organizationCode)) {
      newErrors.organizationCode = 'Only uppercase letters are allowed';
    }

    if (formData.ownerEmail && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.ownerEmail)) {
      newErrors.ownerEmail = 'Invalid email format';
    }

    if (!formData.organizationType) {
      newErrors.organizationType = 'Organization Type is required';
    }

    if (!formData.subscriptionType) {
      newErrors.subscriptionType = 'Subscription Type is required';
    }

    if (!formData.numberOfUsers) {
      newErrors.numberOfUsers = 'Number of Users is required';
    } else if (parseInt(formData.numberOfUsers) < 1) {
      newErrors.numberOfUsers = 'Minimum 1 user required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'organizationCode') {
      const upperValue = value.toUpperCase();
      if (upperValue.length <= 3 && /^[A-Z]*$/.test(upperValue)) {
        setFormData((prev) => ({ ...prev, [name]: upperValue }));
      }
      return;
    }

    if (name === 'numberOfUsers') {
      const numberValue = parseInt(value);
      if (!isNaN(numberValue) && numberValue >= 0) {
        setFormData((prev) => ({ ...prev, [name]: value }));
      }
      return;
    }

    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) selectedOrganization ? handleEdit() : handleCreateNew();
  };

  const handleCreateNew = async () => {
    setCreateOrg(true);
    refetch();
  };

  const handleEdit = () => {
    setUpdateOrg(true);
    editOrgDetails();
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        setSelectedOrganization(null);
        setErrors({});
      }}
      className={classes.modal}
      aria-labelledby="organization-modal-title"
    >
      <div className={classes.paper}>
        <Typography variant="h5" id="organization-modal-title" gutterBottom>
          {initialData ? 'Update Organization' : 'New Organization'}
        </Typography>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {formFields.map((field) => (
              <Grid item xs={12} md={6} key={field.name}>
                <FormField
                  {...field}
                  value={formData[field.name]}
                  onChange={handleChange}
                  error={errors[field.name]}
                  disabled={field.name !== 'organizationName' && !!selectedOrganization?._id}
                />
              </Grid>
            ))}

            {!!selectedOrganization?._id && (
              <Grid item xs={12}>
                <UploadComponent organisationId={selectedOrganization?._id} />
              </Grid>
            )}
          </Grid>

          <Grid item container justifyContent="space-between" className={classes.actions}>
            <Grid item xs={7} container justifyContent="flex-end" alignItems="center">
              <Typography
                onClick={() => {
                  onClose();
                  setErrors({});
                }}
                color="primary"
                style={{ cursor: 'pointer' }}
              >
                Cancel
              </Typography>
            </Grid>
            <Grid item xs={4} container justifyContent="flex-end" alignItems="center">
              <PrimaryButton variant="contained" color="primary" disabled={false} onClick={handleSubmit} style={{ cursor: 'pointer' }}>
                <Box display="flex" alignItems="center" gap={1}>
                  {initialData ? 'Update' : 'Create'}
                  {(initialData ? processingEditOrgDetails : isFetching) && <CircularProgress size={20} color="inherit" style={{ marginLeft: '3%' }} />}
                </Box>
              </PrimaryButton>
            </Grid>
          </Grid>
        </form>
      </div>
    </Modal>
  );
};

export default OrganizationFormModal;
