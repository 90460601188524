import React, { useState, useEffect, useContext } from 'react';
import { Grid, Typography, FormControl, Select, MenuItem, Backdrop, CircularProgress } from '@material-ui/core';
import Fields from '../../common/textfield/Fields';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import PrimaryButton from '../../common/button/Button';
import { UserContext } from '../../../routing/IndexRouting';
import { ProjectContext } from '../../../routing/IndexRouting';
import axios from '../../../config/Axios';
import swal from 'sweetalert';
import userRoles, { UserRoles } from '../../../config/roles';
import CommonHeadingWithIcon from '../../common/header/commonHeadings';
import TooglebuttonGroupForCreateProject from './components/TooglebuttonGroupForCreateProject';
import LocationInput from './components/LocationInput';
import OrganisationInput from './components/OrganisationInput';
import ToogleSqmAndSqft from './components/ToogleSqmAndSqft';
import UnitAwareTextFieldInputs from './components/UnitAwareTextFieldsInput';
import { useAppApiRequest } from '../../../hooks/useAppApiRequest';
import { Cities } from '../../../utils/cities';

const useStyles = makeStyles((theme) => ({
  spacing: {
    marginTop: '3vh',
  },
  formControl: {
    minWidth: 155,
    '& > *': {
      borderRadius: '50px',
      height: '7vh',
    },
  },
}));

export const PI_INCLUDE_CLUBHOUSE = 'project__pi_includeClubhouse';
export const PI_CLUBHOUSE_AREA = 'project__pi_clubhouseArea';

const REQUIRED_PIPS = {
  BASE: ['project__pi_builtUpArea', 'project__pi_siteArea'],
  ROOFTOP_APARTMENT: ['project__pi_rooftopArea'],
  AVAILABLE_VILLA: ['project__pi_availableVillaRooftopArea'],
};

const isValidNumber = (value) => value && !isNaN(value) && parseFloat(value) > 0;

const checkValues = (arr, obj) => arr?.every((id) => isValidNumber(obj[id]));

export const checkErrorForZeros = (requiredpips, form) => {
  const findPIP = (name) => requiredpips?.data.find((field) => field.name === name);
  const filterPIPs = (names) => requiredpips?.data?.filter((data) => names.includes(data.name)).map((data) => data._id);

  const includeClubhousePIP = findPIP(PI_INCLUDE_CLUBHOUSE);
  const clubhouseArea = findPIP(PI_CLUBHOUSE_AREA);

  const requiredPIPsBase = filterPIPs(REQUIRED_PIPS.BASE);
  const requiredPIPsApartmentRooftop = filterPIPs(REQUIRED_PIPS.ROOFTOP_APARTMENT);
  const requiredPIPsVillaRooftop = filterPIPs(REQUIRED_PIPS.AVAILABLE_VILLA);

  const errorBase = checkValues(requiredPIPsBase, form?.pipvalues);
  const errorApartmentRooftop = checkValues(requiredPIPsApartmentRooftop, form?.pipvalues);
  const errorVillaRooftop = checkValues(requiredPIPsVillaRooftop, form?.pipvalues);

  const hasClubhouse = form?.pipvalues[includeClubhousePIP?._id];
  const clubhouseAreaValue = form?.pipvalues[clubhouseArea?._id];

  if (hasClubhouse && !isValidNumber(clubhouseAreaValue)) {
    return false;
  }

  return form?.subcategory === 'villa' ? errorBase && errorVillaRooftop : errorBase && errorApartmentRooftop;
};

const RequiredFields = () => {
  const defaultCity = 'Bengaluru';
  const classes = useStyles();
  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);

  const { handleProjectData } = useContext(ProjectContext);
  const { userDetails } = useContext(UserContext);

  const [cityOptions, setCityOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [locationInput, setLocationInput] = useState('');

  const [form, setForm] = useState({
    name: null,
    organisation: null,
    category: 'residential',
    subcategory: null,
    city: defaultCity,
    location: {},
    pipvalues: {},
    unitPIPValue: null,
  });

  const { data: organisations } = useAppApiRequest(`/organisationsschemev2`, { method: 'GET' });
  const { data: requiredpips } = useAppApiRequest(`/pipschemev2?required=true`, { method: 'GET' });
  const pips = requiredpips?.data?.data;

  const handleUpdateForm = (key, value) => {
    if (key) {
      return setForm((oldFormValues) => {
        return {
          ...oldFormValues,
          [key]: value,
        };
      });
    }
  };

  const handlePIPValueUpdate = (id, value) => {
    const updatedPIPValues = Object.assign(form?.pipvalues, {
      [id]: value,
    });
    return handleUpdateForm('pipvalues', updatedPIPValues);
  };

  useEffect(() => {
    if (userDetails && userDetails.role !== UserRoles.super) handleUpdateForm('organisation', userDetails.organisation._id);
  }, [userDetails]);

  useEffect(() => {
    let handler;
    if (locationInput && locationInput?.length) {
      handler = setTimeout(() => {
        setLoading(true);
        axios.get(`/location?location=${locationInput}`).then((resp) => {
          if (resp && resp.data && resp.data.data) setCityOptions([...resp.data.data.data]);
        });
        setOpen(true);
        setLoading(false);
      }, 250);
    }

    return () => {
      clearTimeout(handler);
    };
  }, [locationInput]);

  useEffect(() => {
    if (pips) {
      const cityschema = pips?.data?.find((ele) => ele?.name === 'project__pi_city');
      const unitSchema = pips?.data?.find((ele) => ele?.name === 'project__pi_unitPreferenceForArea');
      handlePIPValueUpdate(cityschema?._id, defaultCity);
      handleUpdateForm('unitPIPValue', 'Sqm');
      handlePIPValueUpdate(unitSchema?._id, 'Sqm');
    }
  }, [pips]);

  const handleCityChange = (value) => {
    const cityschema = pips?.data?.find((ele) => ele?.name === 'project__pi_city');
    handleUpdateForm('city', value);
    handlePIPValueUpdate(cityschema?._id, value);
  };
  const handleSearch = () => {};
  const handleClick = () => history.push(`/projects`);

  const handleContinue = () => {
    let isError = false;
    ['name', 'organisation', 'category', 'subcategory', 'city', 'unitPIPValue'].forEach((field) => {
      const fieldValue = form[field];
      if (!fieldValue || fieldValue === 0 || fieldValue === '' || Object.keys(fieldValue).length === 0) {
        isError = true;
      }
    });
    ['pipvalues', 'location'].forEach((field) => {
      const fieldValue = form[field];
      if (Object.keys(fieldValue).length === 0) {
        isError = true;
      }
    });
    if (isError) {
      swal({ icon: 'error', title: 'You can not create a project without providing all the mandatory fields.' });
      return;
    }

    setIsSaving(true);
    if (form?.location.placeId) {
      axios.get(`/location/place?id=${form?.location?.placeId}`).then((ress) => {
        if (ress && ress.data && ress.data.data) {
          const loc = ress.data.data.data;
          const createdby = userDetails._id;

          const body = {
            name: form?.name,
            category: form?.category,
            subcategory: form?.subcategory,
            organisation: form?.organisation,
            createdBy: createdby,
            location: { name: loc.name, type: 'Point', coordinates: [loc.coordinates.lat, loc.coordinates.long] },
            payload: '',
            pipvalues: Object.entries(form?.pipvalues).map(([key, value]) => ({
              PIPScheme: key,
              value,
            })),
          };

          axios
            .post('/projectv2', body)
            .then((response) => {
              setIsSaving(false);
              let project = response.data.data.data;
              handleProjectData(project);
              swal({ icon: 'success', title: 'Successfully Created Project' });
              history.push(`/projects/${project._id}/edit?type=basic`);
            })
            .catch(() => {
              setIsSaving(false);
            });
        }
      });
    }
  };

  return (
    <>
      <Grid container justify="left" direction="row" item xs={12}>
        <Grid container item xs={12}>
          <CommonHeadingWithIcon heading=" Basic Information" handleClick={handleClick} />
        </Grid>

        <Grid container item xs={12} className={classes.spacing} style={{ marginTop: '3%' }}>
          <Typography variant="h6" color="textSecondary">
            <b>*</b>All fields are mandatory
          </Typography>
        </Grid>

        <Grid container justify="left" direction="row" item xs={12}>
          <Grid item xs={12} className={classes.spacing}>
            <Typography variant="h5"> Enter Project Name*</Typography>
          </Grid>
          <Grid item xs={2}>
            <Fields
              // error={clickedContinue === true && !name ? true : false}
              id="standard-error-helper-text"
              // helperText={clickedContinue === true && !name ? 'name missing.' : ''}
              value={form?.name}
              onChange={(e) => handleUpdateForm('name', e.target.value)}
              fullWidth
              required
            />
          </Grid>
        </Grid>

        <OrganisationInput
          organisationv2={form?.organisation}
          handleOrganisation={(e) => handleUpdateForm('organisation', e.target.value)}
          orgv2={organisations?.data?.data}
          // clickedContinue={clickedContinue && clickedContinue}
          userRoles={userRoles && userRoles}
        />

        <TooglebuttonGroupForCreateProject
          category={form?.category}
          handleChange={(event, value) => {
            return value && handleUpdateForm('category', value);
          }}
          // clickedContinue={clickedContinue && clickedContinue}
          subCategory={form?.subcategory}
          setSubCategory={(value) => handleUpdateForm('subcategory', value)}
          // setChange={(v) => setChange(v)}
          // change={change && change}
          requiredpips={pips}
          appendChanged={(id, value) => handlePIPValueUpdate(id, value)}
          pipValues={form?.pipvalues}
          unit={form?.unitPIPValue}
        />
        {/* toogle between sqm and sqft */}

        <ToogleSqmAndSqft
          requiredpips={pips}
          radioButtonValue={form?.unitPIPValue}
          handleRadioButtonChange={(event, id) => {
            handlePIPValueUpdate(id, event.target.value);
            handleUpdateForm('unitPIPValue', event.target.value);
          }}
          // setUnitPIPid={(v) => setUnitPIPid(v)}
        />

        <Grid item xs={12} className={classes.spacing}>
          <Typography variant="h5"> Enter Project City*</Typography>
        </Grid>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select value={form?.city} onChange={(e) => handleCityChange(e.target.value)}>
            {Cities.map((city) => (
              <MenuItem key={city} value={city} name={city}>
                {city}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <LocationInput
          open={open}
          requiredpips={pips}
          setOpen={(v) => setOpen(v)}
          setNewloc={(v) => handleUpdateForm('location', v)}
          appendChanged={(id, value) => handlePIPValueUpdate(id, value)}
          setInputChange={(v) => setLocationInput(v)}
          // clickedContinue={clickedContinue && clickedContinue}
          // newloc={newloc && newloc}
          inputchange={form?.location}
          loading={loading}
          options={cityOptions}
          handleSearch={handleSearch}
        />

        <UnitAwareTextFieldInputs
          area="createproject"
          requiredpips={pips}
          unit={form?.unitPIPValue}
          appendChanged={(id, value) => handlePIPValueUpdate(id, value)}
          subCategory={form?.subcategory}
        />

        <Grid container className={classes.spacing} xs={12}>
          <Grid item xs={9}></Grid>
          <Grid item xs={2}>
            <PrimaryButton disabled={!checkErrorForZeros(pips, form)} onClick={() => handleContinue()} children="Continue" />
          </Grid>
        </Grid>
      </Grid>
      <div>
        <Backdrop open={isSaving} style={{ zIndex: 3, color: '#66E3BE' }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </>
  );
};

export default RequiredFields;
