import React from 'react';
import { Grid, Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomSelectFI from '../child-components/CustomSelectFinancialinsights';
import CustomizedBar from '../child-components/CustomizedBar';
import rsToCrore from '../../../../../utils/rsToCr';
import unit from '../../../../../utils/unitConfig';
import Skeleton from '@material-ui/lab/Skeleton';

import { useAppMetafireUtilities } from '../../../../../metafire/hooks/useAppMetafireUtilities';
import numberWithCommas from '../../../../../utils/numberToComma';
import Bold from '../../../../../v2/components/Bold';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginBottom: theme.spacing(3),
    background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), #BFF5E6',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
    borderRadius: '6px',
  },
  maingridPadding: {
    padding: '2%',
  },
  secondaryTextcolor: {
    color: '#9E9E9E',
  },
  gridPaddingtop1: {
    paddingTop: '15px',
  },
  gridPaddingtop2: {
    paddingTop: '7px',
  },
  gridTextalign: {
    textAlign: 'center',
  },
  timeframe: {
    textAlign: 'center',
    padding: '10px',
  },
}));

const FianancialInsights = ({ intervention, setNumberOfYears, numberOfYears }) => {
  const { getValue, getUnit } = useAppMetafireUtilities();
  const classes = useStyles();
  const financeSavings = intervention?.quantities?.overall?.finance;

  const capex = getValue(financeSavings?.capex);
  const irr = getValue(financeSavings?.irr);
  const irrunit = getUnit(financeSavings?.irr);
  const paybackPeriod = getValue(financeSavings?.paybackPeriod);
  const paybackPeriodUnit = getUnit(financeSavings?.paybackPeriod);
  const rsPerSqft = getValue(financeSavings?.capexConverted);
  const rsPerSqftUnit = getUnit(financeSavings?.capexConverted);
  const overallExpenses = getValue(financeSavings?.overallExpenses);
  const opexSavings = getValue(financeSavings?.opexSavings);
  return (
    <>
      <Container className={classes.gridContainer} disableGutters>
        <Grid container className={classes.maingridPadding} xs={12}>
          <Grid item container xs={6}>
            <Grid item xs={9} sm={7} md={5} lg={3} className={classes.timeframe}>
              <Typography variant="h6" className={classes.secondaryTextcolor}>
                Timeframe
              </Typography>
            </Grid>
            <Grid item xs={3} sm={5} md={7} lg={9}>
              <CustomSelectFI
                age={+numberOfYears}
                handleChange={(e) => {
                  setNumberOfYears(+e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid container item xs={6} spacing={3} className={classes.maingridPadding}>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.secondaryTextcolor}>
                  CAPEX Increase
                </Typography>
                {
                  <CustomizedBar
                    bgcolor="#FFCE93"
                    value={capex ? `${numberWithCommas(rsToCrore('Rs', capex))}` : <Skeleton variant="text" height={25} animation="wave" />}
                    unit={unit('cr')}
                    textColor="rgba(242, 141, 20, 1)"
                    barvalue={capex > opexSavings ? 100 : (capex / opexSavings) * 100}
                  />
                }
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.secondaryTextcolor}>
                  OPEX Savings
                </Typography>
                <CustomizedBar
                  bgcolor="#74DEFB"
                  value={financeSavings ? `${numberWithCommas(rsToCrore('Rs', opexSavings))}` : <Skeleton variant="text" height={25} animation="wave" />}
                  unit={unit('cr')}
                  textColor="rgba(59, 199, 238, 1)"
                  barvalue={opexSavings > capex ? 100 : (opexSavings / capex) * 100}
                />
              </Grid>
            </Grid>
            <Grid container item xs={3} style={{ borderRight: '1px solid #E6E6E6', borderLeft: '1px solid #E6E6E6' }}>
              <Grid container item xs={12} style={{ borderBottom: '1px solid #E6E6E6', textAlign: 'center' }}>
                <Grid xs={12} className={classes.gridPaddingtop1}>
                  <Typography variant="h6" className={classes.secondaryTextcolor}>
                    Overall Expenses
                  </Typography>
                </Grid>
                <Grid xs={12} justifyContent="center" className={classes.gridPaddingtop2}>
                  <Bold isHighlight={true} value={numberWithCommas(rsToCrore('Rs', overallExpenses))} unit={unit('cr')} />
                </Grid>
              </Grid>
              <Grid container item xs={12} className={classes.gridTextalign}>
                <Grid xs={12} className={classes.gridPaddingtop1}>
                  <Typography variant="h6" className={classes.secondaryTextcolor}>
                    IRR
                  </Typography>
                </Grid>
                <Grid xs={12} className={classes.gridPaddingtop2}>
                  {irr && irrunit ? (
                    <Typography variant="h6">
                      <b>{`${numberWithCommas(irr)} ${irrunit}`}</b>
                    </Typography>
                  ) : (
                    <Typography variant="h6">
                      <b>{`0 %`}</b>
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={3}>
              <Grid container item xs={12} style={{ borderBottom: '1px solid #E6E6E6', textAlign: 'center' }}>
                <Grid xs={12} className={classes.gridPaddingtop1}>
                  <Typography variant="h6" className={classes.secondaryTextcolor}>
                    Payback Period
                  </Typography>
                </Grid>
                <Grid xs={12} className={classes.gridPaddingtop2}>
                  {paybackPeriod && paybackPeriodUnit ? (
                    <Bold isHighlight={true} value={paybackPeriod} unit={paybackPeriodUnit} />
                  ) : (
                    <Bold value={0} unit={'Years'} />
                  )}
                </Grid>
              </Grid>
              <Grid container item xs={12} className={classes.gridTextalign}>
                <Grid xs={12} className={classes.gridPaddingtop1}>
                  <Typography variant="h6" className={classes.secondaryTextcolor}>
                    Rs/Sqft
                  </Typography>
                </Grid>
                <Grid xs={12} className={classes.gridPaddingtop2}>
                  {rsPerSqft && rsPerSqftUnit ? <Bold isHighlight={true} value={rsPerSqft} unit={rsPerSqftUnit} /> : <Bold value={0} unit={'Rs/Sqft'} />}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default FianancialInsights;
