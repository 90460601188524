import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppApiRequest } from '../../../../../hooks/useAppApiRequest';
import FloatingButton from '../noteTaking/FloatingButton';
import NoteTaking from '../noteTaking/NoteTakingDrawer';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  floatingButtonStyle: {
    overflow: 'hidden',
    position: 'fixed',
    right: '0',
    top: '290px',
    height: 60,
    width: 160,
  },
}));

const InterventionNoteTaking = ({ category }) => {
  const classes = useStyles();
  const { id, iid } = useParams();

  const [notetaking, setNotetaking] = useState(false);
  const [makeapirequest, setMakeapirequest] = useState(false);

  const {
    data: response,
    isError,
    isFetching,
    refetch: fetchAllNotes,
  } = useAppApiRequest(
    `/note-taking?projectId=${id}&&category=${category}&&interventionMappingId=${iid}`,
    { method: 'GET' },
    [id, makeapirequest],
    id !== null && id !== undefined && makeapirequest
  );

  const allnotes = response?.data?.data?.data;
  return (
    <div>
      <Grid item xs={9} className={classes.floatingButtonStyle} style={{ zIndex: !notetaking ? 9998 : 0 }}>
        <FloatingButton style={{ pointerEvents: 'auto' }} notetaking={notetaking} setNotetaking={setNotetaking} setMakeapirequest={setMakeapirequest} />
      </Grid>
      <NoteTaking
        notetaking={notetaking}
        setNotetaking={setNotetaking}
        allnotes={allnotes}
        makeapirequest={makeapirequest}
        fetchAllNotes={() => fetchAllNotes()}
        isErrorFetchingNotes={isError}
        category={category}
      />
    </div>
  );
};

export default InterventionNoteTaking;
