import React, { useEffect } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Grid, MenuItem, Typography } from '@material-ui/core';
import axios from '../../../../../../config/Axios';
import { useParams } from 'react-router-dom';
import UseConfigureProjectsAndInterventionsHook from '../hooks/UseConfigureProjectsAndInterventionsHook';
import { MarketplaceColors, MarketplaceTypography } from '../../../config';
import MarketplaceButton from '../../../common/Button';

const ProductDetailsAddToInterventionWithoutLogin = ({
  style,
  className,
  onClose,
  setShowSuccessModal,
  setShowFailureModal,
  setInterventionFromLogin,
  productDetailsData,
}) => {
  const { attributes } = productDetailsData && productDetailsData;
  const { sdplus_interventions } = attributes && attributes;
  const selectedInterventions = sdplus_interventions?.split(',');

  const { interventions, projects } = UseConfigureProjectsAndInterventionsHook();
  const matchingInterventions = interventions?.filter((intervention) => selectedInterventions?.includes(intervention));

  const { iid } = useParams();
  const [project, setProject] = React.useState(null);
  const [intervention, setIntervention] = React.useState(null);
  const [mappingsData, setMappingsData] = React.useState(null);

  const handleProjectChange = (event) => setProject(event.target.value);
  const handleInterventionChange = (event) => {
    setInterventionFromLogin(interventions?.find((d) => d._id === event.target.value)?.name);
    setIntervention(event.target.value);
  };

  const handleCloseModal = (success, failure) => {
    onClose();
    if (success) setShowSuccessModal(true);
    if (failure) setShowFailureModal(true);
  };

  useEffect(() => {
    if (intervention && project) {
      axios.get(`/ProjectInterventionMapping/project/${project}?interventionid=${intervention}`).then((res) => setMappingsData(res?.data?.data?.data[0]));
    }
  }, [project, intervention]);

  const handleAddToShortlistedProduct = () => {
    const { shortlistedProducts } = mappingsData && mappingsData;
    if (shortlistedProducts.includes(iid)) handleCloseModal(false, true);
    else {
      let body = { shortlistedProducts: [...shortlistedProducts, iid] };
      axios.put(`ProjectInterventionMapping/intervention/${mappingsData?._id}?key=addShortlistedProducts`, body).then((res) => handleCloseModal(true, false));
    }
  };

  return (
    <>
      <div style={style} className={className}>
        <form>
          <Grid container>
            <Grid xs={12} container alignItems="center" justifyContent="center">
              <Typography variant="h5">
                <b>Add To Intervention</b>
              </Typography>
            </Grid>
            <Grid xs={12} container alignItems="center" justifyContent="flex-start" style={{ marginTop: '5%' }}>
              <Grid xs={3} container alignItems="center" justifyContent="flex-start">
                <Typography style={MarketplaceTypography.SubHeaderMedium} color="textSecondary">
                  Project Name
                </Typography>
              </Grid>
              <Grid xs={8} container alignItems="center" justifyContent="flex-start">
                <FormControl variant="outlined" style={{ width: '180px' }}>
                  <InputLabel id="demo-simple-select-label">select</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={project}
                    onChange={handleProjectChange}
                    label="Select"
                  >
                    {projects && projects.map((d, index) => <MenuItem value={d?._id}>{d?.name}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid xs={12} container alignItems="center" justifyContent="flex-start" style={{ marginTop: '10%' }}>
              <Grid xs={3} container alignItems="center" justifyContent="flex-start">
                <Typography style={MarketplaceTypography.SubHeaderMedium} color="textSecondary">
                  Intervention
                </Typography>
              </Grid>
              <Grid xs={8} container alignItems="center" justifyContent="flex-start">
                <FormControl variant="outlined" style={{ width: '180px' }}>
                  <InputLabel id="demo-simple-select-label">select</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={intervention}
                    onChange={handleInterventionChange}
                    label="Select"
                  >
                    {matchingInterventions && matchingInterventions?.length !== 0 ? (
                      matchingInterventions.map((intervention, index) => <MenuItem value={intervention?._id}>{intervention?.name}</MenuItem>)
                    ) : (
                      <MenuItem value="none">None</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid xs={12} container alignItems="flex-end" justifyContent="flex-end" style={{ marginTop: '10%' }}>
              <Grid xs={3}>
                <Typography style={{ cursor: 'pointer', color: MarketplaceColors.Secondary, textTransform: 'uppercase' }} onClick={onClose}>
                  Cancel
                </Typography>
              </Grid>
              <Grid xs={4}>
                <MarketplaceButton style={{ fontSize: '10px' }} children="Done" onClick={handleAddToShortlistedProduct} />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
    </>
  );
};

export default ProductDetailsAddToInterventionWithoutLogin;
