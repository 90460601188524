import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Card } from '@material-ui/core';
import numberWithCommas from '../../../../../utils/numberToComma';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
import { useAppMetafireUtilities } from '../../../../../metafire/hooks/useAppMetafireUtilities';
import Bold from '../../../../../v2/components/Bold';
import { getWarningText } from '../../intervention/utils';
import { MetafireInterventions } from '../../../../../metafire/utils/types';

const useStyles = makeStyles((theme) => ({
  paper1: {
    outline: 'none',
    padding: theme.spacing(2, 1, 2, 4),
    background: '#FFFFFF',
    borderRadius: '10px',
    boxShadow: '2px 2px 8px rgba(24, 50, 119, 0.1)',
  },
  alignTypo: {
    marginTop: '5%',
  },
  alignUnits: {
    paddingLeft: 10,
  },
  errorIcon: {
    color: '#66E3BE',
    cursor: 'pointer',
    height: '70%',
    marginTop: '2%',
  },
  alignTypo2: {
    marginTop: '3%',
  },
  warningText: {
    marginTop: '3%',
    color: '#ED5E68',
    fontSize: '12px',
  },
}));

const isString = (value) => typeof value === 'string';

function CommonCardNew({ capex, capexTotalValue, capexTotalConvertedValue, title, inputs, warning, tooltipBody, tooltipReady, selectedIntervention }) {
  const classes = useStyles();
  const {
    getRoundOffValue,
    utils: { getUiRoundTag, getDecimalPlacesByTag, defaultRoundOff },
  } = useAppMetafireUtilities();

  const getRoundValueFromTags = (item) => {
    const roundTag = getUiRoundTag(item);
    const roundValue = getDecimalPlacesByTag(roundTag);
    return roundValue || defaultRoundOff;
  };

  const getDisplayValue = (item) => {
    if (item?.value === undefined || item?.value === null) return '-';
    if (isNaN(item?.value)) return item?.value;

    const roundValue = getRoundValueFromTags(item);

    if (!isString(item?.value)) {
      if (item?.label === 'IRR' || item?.label === 'Payback Period') {
        return item?.value < 0 && item?.label === 'Payback Period' ? '-' : `${numberWithCommas(getRoundOffValue(item?.value, roundValue))}`;
      }

      return `${numberWithCommas(getRoundOffValue(item?.value, roundValue))}`;
    }

    return item?.value || '-';
  };

  const getValue = (item) => {
    if (!item) return null;

    const isSDGNumber = item.label === 'SDG Number';
    const value = item.value;

    if (isSDGNumber) return Array.isArray(value) ? value.join(' & ') : value;

    if (Array.isArray(value)) {
      return value.map((val, index) => (
        <b
          key={index}
          style={{
            color: val < 0 ? '#26E0A3' : 'black',
          }}
        >
          {isNaN(val) ? val : numberWithCommas(getRoundOffValue(val))}
        </b>
      ));
    }

    return <b style={{ color: value < 0 && item?.label?.toLowerCase().includes('capex') ? '#26E0A3' : 'black' }}>{getDisplayValue(item)}</b>;
  };

  const renderTooltip = (item) => {
    if (!tooltipReady) return null;

    const shouldShowTooltip = ['SDG Number'].includes(item?.label) || selectedIntervention === MetafireInterventions.sanitaryNapkinIncinerator;

    return <CustomizedTooltips icon="info" title={<Typography>{tooltipBody || ''}</Typography>} placement="right" showTooltip={shouldShowTooltip} />;
  };

  const renderCapexInfo = () => {
    if (title !== 'Intervention Results') {
      return (
        <Grid xs={12} style={{ marginTop: '2%', visibility: 'hidden' }}>
          <Typography style={{ fontSize: '11px' }}>
            <b style={{ marginRight: '2%' }}>-</b>
            {'-'}
          </Typography>
        </Grid>
      );
    }

    return (
      <Grid xs={12} style={{ marginTop: '2%' }}>
        <Typography style={{ fontSize: '11px' }}>
          <b style={{ marginRight: '2%' }}>{numberWithCommas(capex ? capexTotalValue[0]?.value : capexTotalConvertedValue[0]?.value)}</b>
          {`${capex ? 'Rs' : 'Rs/Sqft'}`} in Total CAPEX
        </Typography>
      </Grid>
    );
  };

  return (
    <Card className={classes.paper1}>
      <Grid container direction="row" xs={12}>
        {inputs?.map((item, index) => (
          <React.Fragment key={index}>
            <Grid xs={6} container direction="row" style={index > 1 ? { marginTop: '6%' } : null}>
              <Grid xs={12} container direction="row">
                <Typography color="textSecondary">{item?.label?.toLowerCase() === 'capex' ? 'Capex Increase' : item?.label}</Typography>
                {renderTooltip(item)}
              </Grid>
              <Grid xs={12} direction="row">
                <Typography className={classes.alignTypo}>
                  <Bold isHighlight={true} value={getValue(item)} unit={item?.unit} />
                </Typography>
                <Typography className={classes.warningText}>{getWarningText(warning, item) || ''}</Typography>
              </Grid>
            </Grid>
            {index === 1 && renderCapexInfo()}
          </React.Fragment>
        ))}
      </Grid>
    </Card>
  );
}

export default CommonCardNew;
