import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import CurrentProjectCard from './CurrentProjectCard';
import CasaSkeleton from '../../../common/skeleton/CasaSkeleton';
import PrimaryButton from '../../../common/button/Button';

const useStyles = makeStyles((theme) => ({
  spacing: {
    marginTop: '3%',
  },
  span: {
    marginLeft: '240px',
    fontWeight: '850',
    fontSize: '12px',
    lineHeight: '19px',
    letterSpacing: '0.714286px',
    textDecoration: 'underline',
    color: 'rgba(0, 219, 153, 0.82)',
    cursor: 'pointer',
  },
  paper: {
    width: '50%',
    marginLeft: '5%',
    backgroundColor: '#fffff',
  },
}));

const CurrentProjectComponent = (props) => {
  const { loader } = props;
  const classes = useStyles();
  const isWideScreen = props.width || window.innerWidth > 1279;
  const direction = isWideScreen ? 'column' : 'row';

  const getPipValue = (pipName) => {
    const pip = props.currentproject?.pips?.find((d) => d.pipScheme && d.pipScheme.name === pipName);
    return pip ? pip.value : 0;
  };

  const getPipUnit = (pipName) => {
    const pip = props.currentproject?.pips?.find((d) => d.pipScheme && d.pipScheme.name === pipName);
    return pip ? pip.pipScheme.unit : '';
  };

  return (
    <>
      <Grid item xs={isWideScreen ? 3 : 6}>
        <div style={{ position: isWideScreen ? 'fixed' : '', marginTop: '4%' }}>
          <Grid item container direction={direction} xs={12}>
            <Grid item container>
              <Typography style={{ fontWeight: 'bold' }} variant="h5">
                Current Project
              </Typography>

              <Typography
                variant="h6"
                onClick={props.handleEditCurrentProject}
                style={{
                  paddingTop: '2%',
                  marginLeft: '38%',
                  background: 'transparent',
                  color: '#66E3BE',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
              >
                <b>Edit Details</b>
              </Typography>

              <Grid item xs={12} className={classes.spacing}></Grid>

              {loader ? (
                <Grid item xs={3}>
                  <CasaSkeleton variant="rect" height="40vh" width="32rem" animation="wave" />
                </Grid>
              ) : props.currentproject ? (
                <CurrentProjectCard
                  name={props.currentproject.name}
                  category={props.currentproject.category}
                  rooftop={getPipValue('project__pi_rooftopArea')}
                  builtup={getPipValue('project__pi_builtUpArea')}
                  location={props.currentproject.location?.name}
                  unit1={getPipUnit('project__pi_rooftopArea')}
                  unit2={getPipUnit('project__pi_builtUpArea')}
                />
              ) : (
                <Typography variant="h5">Please select or create a new Project to continue</Typography>
              )}
            </Grid>

            <Grid
              align="right"
              item
              xs={9}
              style={{
                marginLeft: isWideScreen ? '-5%' : '23%',
                paddingTop: '4%',
              }}
            >
              <PrimaryButton
                onClick={props.handleInsiteButton}
                disabled={!props.currentproject}
                children="View Insights"
                style={{ width: '150px', borderRadius: '40px' }}
              />
            </Grid>
          </Grid>
        </div>
      </Grid>
    </>
  );
};

export default CurrentProjectComponent;
