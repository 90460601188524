import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ResetFields from '../common/textFiledwithReset';
import numberWithCommas from '../../../../../utils/numberToComma';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';

const IndoorFlooringLnDCustomAdvancedParameters = (props) => {
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityDefaultValue, getQuantityLabel },
    set: { updateQuantityValue },
  } = useInterventionDetailsContext();
  const capexmultipliersource = getQuantityValue(schema.indoorFlooringLnD__pi_capexSourceMultiplier);

  return (
    <Grid container direction="column" alignItems="left" item xs={12}>
      <Typography className={classes.textHeader1} style={{ paddingTop: '3%' }}>
        Intervention Cost Assumptions
        <CustomizedTooltips icon="info" title={<Typography>Changes will only affect this intervention </Typography>} placement="right" />
      </Typography>
      <Typography className={classes.textHeader4}>CAPEX(Per sqm)</Typography>
      <Grid container item xs={12}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader3}>
              {capexmultipliersource === 'vendor' ? (
                <CheckCircleIcon
                  className={classes.selectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.indoorFlooringLnD__pi_capexSourceMultiplier, 'vendor');
                  }}
                />
              ) : (
                <RadioButtonUncheckedIcon
                  className={classes.nonselectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.indoorFlooringLnD__pi_capexSourceMultiplier, 'vendor');
                  }}
                />
              )}
              Selected Vendor
            </Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <Typography className={classes.textconstants}>{numberWithCommas(getQuantityValue(schema.indoorFlooringLnD_vendorCapex))} RS</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader3}>
              {capexmultipliersource === 'custom' ? (
                <CheckCircleIcon
                  className={classes.selectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.indoorFlooringLnD__pi_capexSourceMultiplier, 'custom');
                  }}
                />
              ) : (
                <RadioButtonUncheckedIcon
                  className={classes.nonselectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.indoorFlooringLnD__pi_capexSourceMultiplier, 'custom');
                  }}
                />
              )}
              Custom
            </Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.indoorFlooringLnD__pi_customCapex)}
                placeholder=""
                onChange={(e) => {
                  updateQuantityValue(schema.indoorFlooringLnD__pi_customCapex, e.target.value);
                }}
                fullWidth
                required
                onClick={() => updateQuantityValue(schema.indoorFlooringLnD__pi_customCapex, getQuantityDefaultValue(schema.indoorFlooringLnD__pi_customCapex))}
                disabled={capexmultipliersource !== 'custom' ? true : false}
                removeResetIcon={true}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6" className={capexmultipliersource !== 'custom' ? classes.textDisable : undefined}>
                RS
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Typography className={classes.textHeader1} style={{ paddingTop: '3%' }}>
        Constants
      </Typography>
      <Grid container item xs={12}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.indoorFlooringLnD__pi_areaFactor)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.indoorFlooringLnD__pi_areaFactor)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.indoorFlooringLnD__pi_areaFactor, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  updateQuantityValue(schema.indoorFlooringLnD__pi_areaFactor, getQuantityDefaultValue(schema.indoorFlooringLnD__pi_areaFactor));
                }}
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6"></Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default IndoorFlooringLnDCustomAdvancedParameters;
