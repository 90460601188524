// ///TODO: Latest intervention details icons -  roundIcon
import solarPV from '../../assets/solarPV.svg';
import smartWaterMeter from '../../assets/smartwatermeter.svg';
import EfficientFixtures from '../../assets/efficientFixtures.svg';
import dualflush from '../../assets/dualflush.svg';
import biogasPlant from '../../assets/icons/waste/biogas.svg';
import greywaterReuse from '../../assets/greywater.svg';
import toxicNew from '../../assets/icons/waste/segregation.svg';
import ExteriorPaints from '../../assets/interventions/materials/exterior-paints.svg';
import structure from '../../assets/interventions/structural-steel-rounded.svg';
import hwmVillaicon from '../../assets/interventions/HWM Villa Icon.svg';
import nstpIcon from '../../assets/interventions/NSTPicon.svg';
import rrwhIcon from '../../assets/interventions/rrwh.svg';
import srwhIcon from '../../assets/interventions/srwh.svg';
import cement from '../../assets/interventions/structural-concrete-rounded.svg';
import flooring from '../../assets/interventions/materials/flooring.svg';
import interiorPaints from '../../assets/interventions/materials/interior-paints.svg';

///// TODO: Latest intervention table icons - squareIcon
import solarpv_square from '../../assets/solarPV.svg';
import EnergyCustom from '../../assets/icons/energy/custom-icon.png';
import swm from '../../assets/smartwatermeter.svg';
import df from '../../assets/dualflush.svg';
import gwr from '../../assets/greywater.svg';
import WaterCustom from '../../assets/icons/water/custom-icon.png';
import hwmvillicon from '../../assets/interventions/HWM Villa Icon.svg';
import rrwh from '../../assets/interventions/rrwh.svg';
import srwh from '../../assets/interventions/srwh.svg';
import bgp from '../../assets/icons/waste/biogas.svg';
import toxic from '../../assets/icons/waste/segregation.svg';
import WasteCustom from '../../assets/icons/waste/custom-icon.png';
import cement_square from '../../assets/interventions/structural-concrete-rectangle.svg';
import structure_square from '../../assets/interventions/materials/structure.svg';
import MaterialsCustom from '../../assets/interventions/materials/custom.svg';
import ep from '../../assets/interventions/materials/exterior-paints.svg';
import ip from '../../assets/interventions/materials/interior-paints.svg';
import kitchen from '../../assets/interventions/materials/kitchen-rectangle.svg';
import utilityBalcony from '../../assets/interventions/materials/utility-balcony-rectangle.svg';
import livingDining from '../../assets/interventions/materials/living-dining-rectangle.svg';
import bedroom from '../../assets/interventions/materials/bedroom-rectangle.svg';
import bathroom from '../../assets/interventions/materials/bathroom-rectangle.svg';
import ef from '../../assets/efficientFixtures.svg';
import nstp from '../../assets/interventions/NSTPicon.svg';

export const InterventionIcons = {
  spvApartment: { squareIcon: solarpv_square, roundIcon: solarPV },
  hwmVilla: { squareIcon: hwmvillicon, roundIcon: hwmVillaicon },
  efficientFixturesShower: { squareIcon: ef, roundIcon: EfficientFixtures },
  twr: { squareIcon: gwr, roundIcon: greywaterReuse },
  spvVilla: { squareIcon: solarpv_square, roundIcon: solarPV },
  spvPenthouse: { squareIcon: solarpv_square, roundIcon: solarPV },
  spvClubhouse: { squareIcon: solarpv_square, roundIcon: solarPV },
  hwmPenthouse: { squareIcon: hwmvillicon, roundIcon: hwmVillaicon },
  hwmClubhouse: { squareIcon: hwmvillicon, roundIcon: hwmVillaicon },
  efficientFixturesFaucet: { squareIcon: ef, roundIcon: EfficientFixtures },
  efficientFixturesKitchenSink: { squareIcon: ef, roundIcon: EfficientFixtures },
  efficientFixturesDualFlush: { squareIcon: df, roundIcon: dualflush },
  nstp: { squareIcon: nstp, roundIcon: nstpIcon },
  rrwh: { squareIcon: rrwh, roundIcon: rrwhIcon },
  srwh: { squareIcon: srwh, roundIcon: srwhIcon },
  sc: { squareIcon: cement_square, roundIcon: cement },
  hwmApartment: { squareIcon: hwmvillicon, roundIcon: hwmVillaicon },
  hwmSwimmingPool: { squareIcon: hwmvillicon, roundIcon: hwmVillaicon },
  efficientFixturesHealthFaucet: { squareIcon: ef, roundIcon: EfficientFixtures },
  wwm: { squareIcon: bgp, roundIcon: biogasPlant },
  csp: { squareIcon: toxic, roundIcon: toxicNew },
  swm: { squareIcon: swm, roundIcon: smartWaterMeter },
  exteriorPaintsSunny: { squareIcon: ep, roundIcon: ExteriorPaints },
  exteriorPaintsNonSunny: { squareIcon: ep, roundIcon: ExteriorPaints },
  structuralSteel: { squareIcon: structure_square, roundIcon: structure },
  indoorFlooringLnD: { squareIcon: livingDining, roundIcon: flooring },
  indoorFlooringBedroom: { squareIcon: bedroom, roundIcon: flooring },
  indoorFlooringKitchen: { squareIcon: kitchen, roundIcon: flooring },
  indoorFlooringBathroom: { squareIcon: bathroom, roundIcon: flooring },
  indoorFlooringUnB: { squareIcon: utilityBalcony, roundIcon: flooring },
  interiorPaints: { squareIcon: ip, roundIcon: interiorPaints },
  Energy: { squareIcon: EnergyCustom, roundIcon: null },
  Water: { squareIcon: WaterCustom, roundIcon: null },
  Waste: { squareIcon: WasteCustom, roundIcon: null },
  Materials: { squareIcon: MaterialsCustom, roundIcon: null },
};
