import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../../../../config/Axios';

// Material-UI components
import { Typography, Grid, Popover, Backdrop, CircularProgress } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { withStyles, makeStyles } from '@material-ui/core/styles';

// Material-UI icons
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

// Custom components
import CustomCheckBox from '../../../../common/checkbox/CustomCheckBox';
import Bold from '../../../../../v2/components/Bold';

// Utility functions
import rsToCr from '../../../../../utils/rsToCr';
import unit from '../../../../../utils/unitConfig';
import numberWithCommas from '../../../../../utils/numberToComma';
import { useAppMetafireUtilities } from '../../../../../metafire/hooks/useAppMetafireUtilities';

const StyledTableRow = withStyles((theme) => ({
  root: {
    background: 'rgba(0, 0, 0, 0.03)',
    paddingRight: 1,
    paddingLeft: 1,
  },
}))(TableRow);

const StyledTableSubRow = withStyles((theme) => ({
  root: {
    paddingRight: 1,
    paddingLeft: 1,
  },
}))(TableRow);

const StyledRowCell = withStyles((theme) => ({
  body: {
    paddingTop: 4,
    paddingBottom: 4,

    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '19px',
    letterSpacing: '0.571429px',
    color: 'rgba(0, 0, 0, 0.82)',
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  linkfont: {
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    color: '#00C58A',
  },
  decoration: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  decoration2: {
    cursor: 'pointer',
  },
  grid: {
    marginTop: '3%',
  },
  typography: {
    padding: theme.spacing(2),
  },
  modal: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function CategoryRow(props) {
  const { row, StyledTableCell } = props;
  const { getValue, getUnit, getIconByFeatureKey } = useAppMetafireUtilities();
  const category = props.category;
  const state = props.state;
  const [spinner, setSpinner] = useState(false);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  let history = useHistory();
  let iid = props.projectID;

  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleRefresh = async (id, state) => {
    setSpinner(true);
    const body = {
      enabled: state,
    };
    axios
      .put(`/ProjectInterventionMapping/${id}`, body)
      .then((res) => {
        if (res) {
          props.handleRefresh();
          setSpinner(false);
        }
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
      });
  };

  const sortedDataWithActiveInterventions = row?.interventions?.filter((data) => getValue(data?.quantities?.activated) === true) || [];
  const financeSavings = row?.quantities?.finance;
  const resourceSavings = row?.quantities?.resource;

  const capex = financeSavings?.capex;
  const capexConverted = financeSavings?.capexConverted;
  const totalCapexConverted = financeSavings?.totalCapexConverted;
  const totalCapex = financeSavings?.totalCapex;
  const opexSavings = financeSavings?.opex;
  const resourceCategory = resourceSavings?.category;

  const isCustomIntervention = props?.name === 'Custom Intervention';
  return (
    <>
      <Backdrop className={classes.backdrop} open={spinner}>
        <CircularProgress style={{ color: '#28DEAA' }} />
      </Backdrop>
      {sortedDataWithActiveInterventions?.length !== 0 && (
        <StyledTableRow
          // onClick={handleState}
          style={{ height: '8vh' }}
        >
          <StyledTableCell component="th" scope="row">
            {row.name.charAt(0).toUpperCase() + row.name.slice(1)}
          </StyledTableCell>

          {/* Capex Increase */}
          {!state ? (
            <StyledTableCell>
              <Bold isHighlight={true} value={numberWithCommas(getValue(capexConverted))} unit={getUnit(capexConverted)} />
            </StyledTableCell>
          ) : (
            <StyledTableCell>
              <Bold isHighlight={true} value={numberWithCommas(rsToCr('Rs', getValue(capex)))} unit={unit('cr')} />
            </StyledTableCell>
          )}

          {/* Total Capex */}
          {!state ? (
            <StyledTableCell>
              <Bold isHighlight={true} value={numberWithCommas(getValue(totalCapexConverted))} unit={getUnit(totalCapexConverted)} />
            </StyledTableCell>
          ) : (
            <StyledTableCell>
              <Bold isHighlight={true} value={numberWithCommas(rsToCr('Rs', getValue(totalCapex)))} unit={unit('cr')} />
            </StyledTableCell>
          )}

          {/* Opex Savings */}
          <StyledTableCell>
            <Bold isHighlight={true} value={numberWithCommas(rsToCr('Rs', getValue(opexSavings)))} unit={unit('cr')} />
          </StyledTableCell>

          {/* Energy Savings */}
          <StyledTableCell>
            <Bold isHighlight={true} value={numberWithCommas(getValue(resourceCategory))} unit={unit(getUnit(resourceCategory))} />
          </StyledTableCell>
        </StyledTableRow>
      )}

      {sortedDataWithActiveInterventions &&
        sortedDataWithActiveInterventions?.map((row, index) => {
          return (
            <StyledTableSubRow key={index}>
              <StyledRowCell component="th" scope="row">
                <>
                  <Grid container xs>
                    <Grid xs={1} style={{ paddingTop: 5 }}>
                      <CustomCheckBox
                        color="rgba(0, 0, 0, 0.38)"
                        checkState={getValue(row?.quantities?.enabled)}
                        uncheckicon={<RadioButtonUncheckedIcon style={{ fontSize: 20 }} />}
                        checkicon={<CheckCircleIcon style={{ fontSize: 20 }} />}
                        handleChange={() =>
                          handleRefresh(
                            isCustomIntervention ? `custom-interventions.${row?.customInterventionId}` : row?.interventionProjectMappingId,
                            !getValue(row?.quantities?.enabled)
                          )
                        }
                        disabled={false}
                      />
                    </Grid>
                    <Grid xs={2}>
                      {!isCustomIntervention && <img style={{ borderRadius: '10px' }} src={getIconByFeatureKey(row.feature, false)} alt="..." />}
                      {isCustomIntervention && category === 'Energy' && (
                        <img style={{ borderRadius: '10px' }} src={getIconByFeatureKey('Energy', false)} alt="..." />
                      )}
                      {isCustomIntervention && category === 'Water' && (
                        <img style={{ borderRadius: '10px' }} src={getIconByFeatureKey('Water', false)} alt="..." />
                      )}
                      {isCustomIntervention && category === 'Waste' && (
                        <img style={{ borderRadius: '10px' }} src={getIconByFeatureKey('Waste', false)} alt="..." />
                      )}
                      {isCustomIntervention && category === 'Materials' && (
                        <img style={{ borderRadius: '10px' }} src={getIconByFeatureKey('Materials', false)} alt="..." />
                      )}
                    </Grid>
                    <Grid
                      xs={9}
                      className={classes.grid}
                      onClick={() => {
                        if (!isCustomIntervention) {
                          history.push(`/projects/${iid}/interventions/${row.interventionProjectMappingId}`);
                        } else {
                          props.handleOpenBackdrop(true);
                          props.handleEditCustomIntervention(true);
                          props.handleEditCustominterventionID(row.customInterventionId);
                          props.setCustomInterventionFileNames(row.fileNames);
                        }
                      }}
                      style={{ paddingTop: '3px', paddingLeft: '2%', cursor: 'pointer' }}
                      item
                      container
                    >
                      <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>{row.name.charAt(0).toUpperCase() + row.name.slice(1)}</span>
                    </Grid>
                  </Grid>
                </>
              </StyledRowCell>

              {/* Capex row wise */}
              {!state ? (
                <StyledRowCell style={row.locked !== false ? { color: 'grey' } : {}}>
                  <Bold isHighlight={true} value={numberWithCommas(getValue(row?.quantities?.finance?.capexConverted))} unit={getUnit(capexConverted)} />
                </StyledRowCell>
              ) : (
                <StyledRowCell style={row.locked !== false ? { color: 'grey' } : {}}>
                  <Bold isHighlight={true} value={rsToCr('Rs', getValue(row?.quantities?.finance?.capex))} unit={unit('cr')} />
                </StyledRowCell>
              )}

              {/* Total Capex row wise */}
              <StyledRowCell style={row.locked !== false ? { color: 'grey' } : {}}>
                {!state ? (
                  <Bold
                    isHighlight={true}
                    value={getValue(row?.quantities?.finance?.totalCapexConverted)}
                    unit={getUnit(row?.quantities?.finance?.totalCapexConverted)}
                  />
                ) : (
                  <Bold isHighlight={true} value={rsToCr('Rs', getValue(row?.quantities?.finance?.totalCapex))} unit={unit('cr')} />
                )}
              </StyledRowCell>

              {/* Opex Savings wise */}
              <StyledRowCell style={row.locked !== false ? { color: 'grey' } : {}}>
                <Bold isHighlight={true} value={rsToCr('Rs', getValue(row?.quantities?.finance?.opex))} unit={unit('cr')} />
              </StyledRowCell>

              {/* Energy Savings wise */}
              <StyledRowCell style={row.locked !== false ? { color: 'grey' } : {}}>
                <Bold
                  isHighlight={true}
                  value={numberWithCommas(getValue(row?.quantities?.resource?.category))}
                  unit={unit(getUnit(row?.quantities?.resource?.category))}
                />
              </StyledRowCell>
            </StyledTableSubRow>
          );
        })}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography className={classes.typography}>{row?.name}</Typography>
      </Popover>
    </>
  );
}
