import React, { useContext, useEffect, useState } from 'react';
import { Box, Container, Grid, Typography, makeStyles, withWidth } from '@material-ui/core';
import { MarketplaceTypography, MarketplaceColors } from '../../../config/index';
import BreadCrumbs from '../../../common/BreadCrumb';
import ProductCard from '../../../common/ProductCard';
import Title from '../../../common/Title';
import LogicalGroup from '../common/LogicalGroup';
import Paginator from '../common/Paginator';
import ResultLengthSelector from '../common/ResultLengthSelector';
import useScreenType from '../../../../../hooks/checkScreenType';
import { Skeleton } from '@material-ui/lab';
import { ProductsPageContext } from '../../../../../../routing/IndexRouting';
import { NavigatorSource } from '../../../utils/Defaults';
import MarketplaceFooter from '../../../footer/MarketplaceFooter';
import Links from '../../../common/Typography/Links';
import PrimaryButton from '../../../../../common/button/Button';

const useStyles = makeStyles((theme) => ({
  headerStyle: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '4%',
    paddingRight: '1%',
  },
  noprojectheaderStyle: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingLeft: '3%',
    paddingRight: '1%',
    height: '100%',
  },
  padding: {
    paddingTop: '6%',
    flexGrow: 1,
  },
  dividerstyle: {
    width: '100%',
    border: `1px solid ${MarketplaceColors.NonselectedCategory}`,
    background: MarketplaceColors.NonselectedCategory,
  },
  paddingelement: {
    paddingTop: '3%',
  },
  paddingelement4: {
    paddingTop: '2%',
  },
  pdoductText: MarketplaceTypography.HeaderSmall,
  footertext: MarketplaceTypography.BodyMedium,
  link: {
    color: MarketplaceColors.Tertiary,
    cursor: 'pointer',
    paddingLeft: '5px',
  },
  paddingelement2: {
    paddingTop: '3%',
    paddingBottom: '3%',
  },
  linktextstyle: MarketplaceTypography.BodyMedium,
  linktextstyle2: {
    color: MarketplaceColors.Breadcrumb,
    paddingRight: theme.spacing(2),
  },
  linktextstyle2mobile: {
    color: MarketplaceColors.Breadcrumb,
    paddingRight: theme.spacing(2),
  },
  noprojectstextheader: MarketplaceTypography.BodyMedium,
  noprojectstextsubhead: MarketplaceTypography.AllCaps,
  skeletonsDesktop: {
    width: '220px',
    height: '320px',
    marginRight: '2%',
    marginBottom: '2%',
    boxShadow: 'none',
    border: '1px solid #00000033',
    borderRadius: '10px',
  },
  skeletonsMobile: {
    width: '183px',
    margin: '10px',
    boxShadow: 'none',
    border: '1px solid #00000033',
    borderRadius: '10px',
  },
}));

const dummmySkeletons = [1, 2, 3, 4, 5, 6, 7, 8];

const widthstyle = {
  width: '220px',
};
const widthmobile = {
  width: '170px',
};

const links = [{ href: `/marketplace`, text: 'Home' }];

const Main = (props) => {
  const classes = useStyles();
  const { width, products, categories, totalproducts, ecoLabels, shortlistedProducts, mappingid, setShortlistedProducts } = props;
  const { URLS, handleShowContactUs } = useContext(ProductsPageContext);
  const { category, supercategory, source, page, searchquery, subcategory } = URLS;
  const { isMobile } = useScreenType(width);
  const [paginatedproduct, setPaginatedProducts] = useState(products && products);
  const [isCompareModeEnabled, setIsCompareModeEnabled] = useState(false);
  const [productSelectedForComparison, setProductSelectedForComparison] = useState([]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) setPaginatedProducts(products);
    return () => (isMounted = false);
  }, [page, products]);

  const getPageHeader = () => {
    if (source === NavigatorSource.categories) return category;
    if (source === NavigatorSource.intervention) return category;
    else if (source === NavigatorSource.supercategories) return supercategory;
    else if (source === NavigatorSource.ecolabels) return 'Green Certification';
    else if (source === NavigatorSource.search) return 'Search Results';
  };
  const moveToProductCompareTab = () => {
    if (productSelectedForComparison.length > 0)
      window.open(
        `/marketplace/product-comparison?selectedProducts=${productSelectedForComparison.join(',')}&source=${
          source === 'Intervention' ? 'shortlist' : 'marketplace'
        }&interventionId=${mappingid || ''}`,
        '_blank'
      );
  };
  const compareModeApplicability = () => {
    const categoryListWithoutSubcateryFilter = ['Cement', 'Paints', 'Steel', 'Glazing', 'Wall Blocks'];
    const categoryListWithSubcateryFilter = [
      'Waste Management Companies',
      'Wet Waste Management',
      'Rain Water Harvesting',
      'SPV',
      'Water Management',
      'Plumbing Fixtures',
      'Flooring',
      'Waste Water Treatment',
      'Hot Water Management',
      'Roof Solutions',
    ];
    if (supercategory && !category) return false;
    if (categoryListWithoutSubcateryFilter.includes(category)) return true;
    if (categoryListWithSubcateryFilter.includes(category) && subcategory && !subcategory?.includes(',')) return true;
    return false;
  };
  useEffect(() => {
    if (!compareModeApplicability()) {
      setIsCompareModeEnabled(false);
      setProductSelectedForComparison([]);
    }
  }, [category, subcategory]);
  return (
    <>
      <Grid className={classes.padding} container xs={12} sm={12} md={12} lg={12} xl={12}>
        <Container maxWidth="lg">
          <Grid container xs={12} sm={12} md={12} lg={12} xl={12} item alignItems="flex-start" justifyContent="flex-start">
            <Title name={getPageHeader()} />
            <BreadCrumbs links={links} stage={<b>{source === NavigatorSource.search ? `"${searchquery}"` : getPageHeader()}</b>} />
          </Grid>
          <Grid container xs={12} sm={12} md={12} lg={12} xl={12} item alignItems="flex-start" justifyContent="flex-start">
            {source === NavigatorSource.supercategories && (
              <Typography variant="h5" style={{ color: 'black', marginTop: '1%', marginBottom: '1%' }}>
                <b>{getPageHeader()} Categories</b>
              </Typography>
            )}
          </Grid>
          {source === NavigatorSource.supercategories && (
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              item
              alignItems="flex-start"
              justifyContent="flex-start"
              direction="row"
              style={{ borderBottom: `1px solid ${MarketplaceColors.NonselectedCategory}`, paddingBottom: '2%' }}
            >
              <LogicalGroup categories={categories} />
            </Grid>
          )}
          {source === NavigatorSource.ecolabels && (
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              item
              alignItems="flex-start"
              justifyContent="flex-start"
              direction="row"
              style={{ borderBottom: `1px solid ${MarketplaceColors.NonselectedCategory}`, paddingBottom: '2%' }}
            >
              <LogicalGroup categories={ecoLabels} />
            </Grid>
          )}
          {/* Products Header and sortby*/}
          {totalproducts !== 0 && (
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12} direction="row" style={{ marginTop: '1%', height: '62px' }} alignItems="stretch">
              <Grid container xs={2} alignItems="center" justifyContent="flex-start">
                <Typography style={MarketplaceTypography.HeaderSmall}>Products</Typography>
              </Grid>
              {!isMobile && (
                <Grid xs={7} container alignItems="center" justifyContent="flex-end">
                  {isCompareModeEnabled && (
                    <Typography
                      onClick={() => {
                        setIsCompareModeEnabled(false);
                        setProductSelectedForComparison([]);
                      }}
                      style={{ color: '#666666', height: '12px', width: '12px', fontWeight: 'bold', marginRight: '2%', marginBottom: '1%', cursor: 'pointer' }}
                    >
                      X
                    </Typography>
                  )}
                  {compareModeApplicability() && (
                    <Box
                      xs={7}
                      style={{ marginRight: '2%', marginTop: isCompareModeEnabled ? '2%' : '', display: 'flex', flexDirection: 'column', textAlign: 'center' }}
                    >
                      <PrimaryButton
                        children={isCompareModeEnabled ? `compare selected (${productSelectedForComparison.length})` : 'add to compare'}
                        onClick={() => {
                          if (!isCompareModeEnabled) setIsCompareModeEnabled(!isCompareModeEnabled);
                          if (isCompareModeEnabled) moveToProductCompareTab();
                        }}
                        style={{
                          fontWeight: '700',
                          fontSize: '12px',
                          lineHeight: '14px',
                          boxShadow: 'none',
                        }}
                      />
                      {isCompareModeEnabled && <Typography style={{ marginTop: '4%', color: '#666666', fontSize: '8px' }}>ADD UP TO 5 PRODUCTS</Typography>}{' '}
                    </Box>
                  )}
                  <Typography className={`${classes.linktextstyle} ${classes.linktextstyle2mobile}`}>Sort by:</Typography>
                  <ResultLengthSelector title={'A -> Z'} />
                </Grid>
              )}
            </Grid>
          )}
          {/* Products */}
          <Grid container xs={12} sm={12} md={12} lg={12} xl={12} item alignItems="center" justifyContent="flex-start" className={classes.paddingelement4}>
            {paginatedproduct &&
              paginatedproduct.map((ele) => (
                <ProductCard
                  widthstyle={isMobile ? widthmobile : widthstyle}
                  productImage={ele?.image}
                  productName={ele?.sku_name}
                  productBrandName={ele?.brand?.name}
                  productShortDescription={ele?.short_description}
                  productCertifications={ele?.certifications}
                  ecolabels={ele?.ecolabels}
                  productCategoryID={ele?.category?.id}
                  productID={ele?.id}
                  identifier={ele?.catalog_sku_id}
                  shortlistedProducts={shortlistedProducts}
                  mappingid={mappingid}
                  setShortlistedProducts={setShortlistedProducts}
                  source={URLS?.source}
                  isCompareModeEnabled={isCompareModeEnabled}
                  setProductSelectedForComparison={setProductSelectedForComparison}
                  productSelectedForComparison={productSelectedForComparison}
                />
              ))}
            {!paginatedproduct &&
              dummmySkeletons.map((a) => <Skeleton variant="rect" className={!isMobile ? classes.skeletonsDesktop : classes.skeletonsMobile} />)}
          </Grid>
          {/* Paginator and other stuff */}
          {totalproducts !== 0 && (
            <>
              {/*Mobile Paginator Component  */}
              {isMobile && (
                <>
                  <Grid container xs={12} sm={12} md={12} lg={12} xl={12} item alignItems="center" justifyContent="center" className={classes.paddingelement}>
                    <Paginator totalproducts={totalproducts} />
                  </Grid>
                  <Grid container xs={12} sm={12} md={12} lg={12} xl={12} item alignItems="center" justifyContent="center" className={classes.paddingelement}>
                    <Typography className={`${classes.linktextstyle} ${classes.linktextstyle2mobile}`}>Results per page:</Typography>
                    <Grid item xs={1} container justifyContent="flex-end">
                      <ResultLengthSelector />
                    </Grid>
                  </Grid>
                </>
              )}
              {/*Desktop Paginator Component  */}
              {!isMobile && (
                <Grid container xs={12} sm={12} md={12} lg={12} xl={12} item alignItems="center" justifyContent="center" className={classes.paddingelement}>
                  <Grid item xs={7} container alignItems="flex-end" justifyContent="flex-end">
                    <Paginator totalproducts={totalproducts} />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={3} container alignItems="flex-end" justifyContent="flex-end">
                    <Typography className={`${classes.linktextstyle} ${classes.linktextstyle2}`}>Results per page:</Typography>
                  </Grid>
                  <Grid item xs={1} container alignItems="flex-end" justifyContent="flex-start">
                    <ResultLengthSelector />
                  </Grid>
                </Grid>
              )}
              <Grid container xs={12} sm={12} md={12} lg={12} xl={12} item alignItems="center" justifyContent="center" className={classes.paddingelement2}>
                <Grid container alignItems="center" justifyContent="center">
                  <Typography className={classes.footertext} style={{ color: MarketplaceColors.Breadcrumb }}>
                    {`Not finding what you are looking for? `}
                  </Typography>
                  <Links
                    variant="string"
                    href={null}
                    tag={` Let us know what we can add`}
                    isBold={false}
                    className={classes.link}
                    onClick={() => handleShowContactUs(true)}
                  />
                </Grid>
              </Grid>
            </>
          )}
          {totalproducts === 0 && source !== NavigatorSource.search && (
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12} item direction="column" className={classes.noprojectheaderStyle}>
              <Typography className={classes.noprojectstextheader}>No results found</Typography>
              <Typography className={classes.noprojectstextsubhead} style={{ color: MarketplaceColors.Breadcrumb }}>
                Try clearing your filters
              </Typography>
            </Grid>
          )}
          {totalproducts === 0 && source === NavigatorSource.search && (
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12} item direction="column" className={classes.noprojectheaderStyle}>
              <Typography className={classes.noprojectstextheader} style={{ paddingBottom: '2%' }}>
                No luck with "{searchquery}", but the search for sustainability isn't over. Try a different keyword.
              </Typography>
              <Grid container alignItems="left" justifyContent="flex-start" style={{ paddingBottom: '2%' }}>
                <Typography className={classes.footertext} style={{ color: MarketplaceColors.Breadcrumb }}>
                  {`Couldn’t find your product?`}
                </Typography>
                <Links
                  variant="string"
                  href={null}
                  tag={` Tell us what's missing.`}
                  isBold={false}
                  className={classes.link}
                  onClick={() => handleShowContactUs(true)}
                />
                <Typography className={classes.footertext} style={{ color: MarketplaceColors.Breadcrumb, paddingLeft: '5px' }}>
                  {` Your insights guide our way! `}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Container>
        <MarketplaceFooter />
      </Grid>
    </>
  );
};

export default withWidth()(Main);
