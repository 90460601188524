import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useEffect } from 'react';
import { ProductsPageContext } from '../../../routing/IndexRouting';
import { useContext } from 'react';
export default function CheckboxLabels(props) {
  const { handleFilterArr } = useContext(ProductsPageContext);
  const CheckBox = withStyles({
    root: {
      color: props.color || 'rgba(0, 219, 153, 0.82)',
      borderRadius: props.borderRadius || 0,
      '&$checked': {
        color: props.checkedcolor || 'rgba(0, 219, 153, 0.82)',
      },
      '&:hover': {
        backgroundColor: props.disableBackgroundEffect ? 'transparent !important' : '',
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);
  useEffect(() => {
    if (props.checkState === true) handleFilterArr(props.name, props.label, props.checkState);
  }, []);
  return (
    <FormControlLabel
      onClick={(event) => event.stopPropagation()}
      onFocus={(event) => event.stopPropagation()}
      control={
        <CheckBox
          style={props.style}
          size={props.size}
          checked={props.checkState}
          icon={props.uncheckicon}
          checkedIcon={props.checkicon}
          onChange={props.handleChange}
          onClick={props.handleClick}
          indeterminateIcon={props.indeterminateIcon}
          indeterminate={props.indeterminate}
          disabled={props.disabled}
        />
      }
      label={props.label}
    />
  );
}
