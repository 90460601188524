import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  main: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
  },
}));

const Page404 = () => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <div>
        <Typography variant="h3">Oops!</Typography>
        <Typography variant="h5">Page Not Found</Typography>
        <Typography variant="h6">Seems like you do not have access or the page does not exist.</Typography>
      </div>
    </div>
  );
};
export default Page404;
