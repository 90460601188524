import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles';
import { InputBase, InputAdornment } from '@material-ui/core';
import ReplaySharpIcon from '@material-ui/icons/ReplaySharp';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 20,
    border: 'rgba(0, 0, 0, 0.38)',
    color: 'black',
    height: 43,
    padding: '13px 10px',
    '&.Mui-focused': {
      boxShadow: `${alpha('rgba(0, 0, 0, 0.38', 0.25)} 0 0 0 0.1rem`,
      borderColor: 'rgba(0, 0, 0, 0.38)',
    },
    '&:hover': {
      border: '1px solid rgba(0, 0, 0, 0.38);',
    },
  },
}));

const ResetFields = ({ onClick, value, onChange, disabled = false, removeResetIcon = false, ...props }) => {
  const classes = useStyles();

  const getInputStyles = () => ({
    border: disabled ? '1px solid #9E9E9E' : '1px solid #2E2E2E',
  });

  const getIconStyles = () => ({
    fontSize: '20px',
    color: disabled ? '#9E9E9E' : '#2E2E2E',
    cursor: 'pointer',
  });

  const renderResetIcon = () => {
    if (removeResetIcon) return null;

    return (
      <InputAdornment position="end">
        <ReplaySharpIcon style={getIconStyles()} onClick={onClick} />
      </InputAdornment>
    );
  };

  return (
    <InputBase
      className={classes.root}
      id="outlined-adornment-weight"
      endAdornment={renderResetIcon()}
      value={value}
      onChange={onChange}
      aria-describedby="outlined-weight-helper-text"
      labelWidth={0}
      style={getInputStyles()}
      inputProps={{
        style: { color: disabled ? '#9E9E9E' : '#2E2E2E' },
      }}
      disabled={disabled}
      {...props}
    />
  );
};

export default ResetFields;
