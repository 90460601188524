import React, { useEffect, useState, useContext } from 'react';
import { Grid, Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomSelectInterventions from '../../insights/child-components/CustomSelectInterventions';
import { useHistory } from 'react-router-dom';

import { Link } from 'react-router-dom';
import numberWithCommas from '../../../../../utils/numberToComma';
import { useParams } from 'react-router';
import PrimaryButton from '../../../../../components/common/button/Button';
import { InterventionCategoryContext } from '../../../../../projectRouting/Index';

import { getEnabledAndActivatedInterventionsInCategory, getTextColourForIntervention } from '../../../interventions/intervention/utils';
import { useAppMetafireUtilities } from '../../../../../metafire/hooks/useAppMetafireUtilities';
import unit from '../../../../../utils/unitConfig';
import rsToCrore from '../../../../../utils/rsToCr';
import Bold from '../../../../../v2/components/Bold';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    minHeight: 290,
    padding: 18,
    marginBottom: theme.spacing(3),
    background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), #BFF5E6',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
    borderRadius: '6px',
  },
  margin: {
    margin: theme.spacing(0),
    borderRadius: '20px',
  },
  secondaryTextcolor: {
    color: '#9E9E9E',
  },
}));

// check out for a common component
const SelectedInterv = ({ intervention }) => {
  let history = useHistory();

  const { getValue, getUnit, getIconByFeatureKey } = useAppMetafireUtilities();

  const classes = useStyles();
  const params = useParams();
  const [filteredInterventions, setFilteredIntervention] = useState([]);
  const [selectedValue, setSelectedValue] = useState('top-opex');

  const { handleInterventionCategory } = useContext(InterventionCategoryContext);
  useEffect(() => {
    if (intervention) {
      if (selectedValue === 'top-capex') {
        const data = intervention?.categorized?.reduce((memo, categorized) => {
          const interventionsPerCategory = getEnabledAndActivatedInterventionsInCategory(categorized);
          memo = [...memo, ...interventionsPerCategory];
          return memo;
        }, []);
        const eData = data?.sort((a, b) => b?.quantities?.finance?.capex?.value - a?.quantities?.finance?.capex?.value).slice(0, 4);
        setFilteredIntervention(eData);
      } else if (selectedValue === 'top-opex') {
        const data = intervention?.categorized?.reduce((memo, categorized) => {
          const interventionsPerCategory = getEnabledAndActivatedInterventionsInCategory(categorized);
          memo = [...memo, ...interventionsPerCategory];
          return memo;
        }, []);
        const eData = data?.sort((a, b) => b?.quantities?.finance?.opex?.value - a?.quantities?.finance?.opex?.value).slice(0, 4);
        setFilteredIntervention(eData);
      } else {
        const data = intervention?.categorized?.find((categorized) => categorized?.name?.toLowerCase() === selectedValue);
        const eData = getEnabledAndActivatedInterventionsInCategory(data)
          .sort((a, b) => b?.quantities?.resource?.category?.value - a?.quantities?.resource?.category?.value)
          .slice(0, 4);
        setFilteredIntervention(eData);
      }
    }
  }, [intervention, selectedValue]);

  const handleChange = (e) => setSelectedValue(e.target.value);

  const activateIntervention = (val) => {
    handleInterventionCategory(val);
    history.push(`/projects/${params?.id}/interventions`);
  };

  const pushToInterventionDetailsPage = (interventionId) => {
    history.push(`/projects/${params?.id}/interventions/${interventionId}`);
  };

  return (
    <>
      <Container className={classes.gridContainer}>
        <Grid container xs={12}>
          <Grid item xs={5}>
            <CustomSelectInterventions selectedvalue={selectedValue} handleChange={handleChange} />
          </Grid>
          <Grid item xs={7} style={{ textAlign: 'center', padding: '2%' }}>
            <Link style={{ fontSize: '12px', color: '#00C58A', lineHeight: '20%' }} to={`/projects/${params?.id}/interventions`}>
              View All Interventions
            </Link>
          </Grid>
        </Grid>
        <Grid container item xs={12} style={{ paddingTop: 16, paddingLeft: 8 }}>
          {filteredInterventions && filteredInterventions.length !== 0 ? (
            filteredInterventions.map((intervention, index) => {
              return (
                <>
                  <Grid item xs={2} key={index}>
                    <img
                      src={getIconByFeatureKey(intervention?.feature, false) || getIconByFeatureKey('Energy', false)}
                      alt={intervention?.name}
                      style={{ height: '80%' }}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'left', paddingTop: '10px', cursor: 'pointer' }}>
                    <Typography
                      onClick={() => {
                        pushToInterventionDetailsPage(intervention.interventionProjectMappingId);
                      }}
                      variant="h6"
                      style={{ color: getTextColourForIntervention(intervention.feature) }}
                    >
                      {intervention?.name?.length > 15 ? intervention?.name?.substr(0, 17) + '..' : intervention?.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} style={{ textAlign: 'left', paddingTop: '10px' }}>
                    <Typography variant="h6" style={{ color: ' #666666' }}>
                      <b>
                        {selectedValue === 'top-capex' ? (
                          <Bold isHighlight={true} value={rsToCrore('Rs', getValue(intervention?.quantities?.finance?.capex))} unit={unit('cr')} />
                        ) : selectedValue === 'top-opex' ? (
                          <Bold isHighlight={true} value={rsToCrore('Rs', getValue(intervention?.quantities?.finance?.opex))} unit={unit('cr')} />
                        ) : (
                          <Bold
                            isHighlight={true}
                            value={numberWithCommas(Math.round(getValue(intervention?.quantities?.resource?.category)))}
                            unit={getUnit(intervention?.quantities?.resource?.category)}
                          />
                        )}
                      </b>
                    </Typography>
                  </Grid>
                </>
              );
            })
          ) : (
            <>
              <Grid container item xs={12} style={{ paddingTop: '8%' }} justifyContent="center" alignItems="center">
                <Typography variant="h5" color="textSecondary" style={{ textAlign: 'center', lineHeight: '35px' }}>
                  No intervention
                  <br /> is currently active
                </Typography>
              </Grid>
              <Grid container item xs={12} style={{ paddingTop: '8%' }} justifyContent="center" alignItems="center">
                <PrimaryButton children={`Activate Interventions`} onClick={() => activateIntervention('energy')} />
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default SelectedInterv;
