import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import numberWithCommas from '../../../../../utils/numberToComma';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';

const ScProjectInputs = () => {
  const classes = useStyles();
  const {
    get: { getQuantityValue, getQuantityLabel, getQuantityUnit },
    schema,
  } = useInterventionDetailsContext();
  const builtup = getQuantityValue(schema.sc_builtUpArea);
  const typeofconstruction = getQuantityValue(schema.sc_typeOfConstruction);
  const slabThickness = getQuantityValue(schema.sc_slabThickness);
  const beandepth = getQuantityValue(schema.sc_beamDepth);
  const wallThickness = getQuantityValue(schema.sc_wallThickness);

  return (
    <Grid container item xs={12} direction="column" style={{ marginBottom: '50px' }}>
      <Grid xs={12} style={{ display: 'flex', alignItems: 'center' }}>
        <Typography className={classes.textHeader1}>Project Inputs</Typography>
      </Grid>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid>
          <Typography className={classes.textHeader5}>Built Up Area</Typography>
          <Typography className={classes.textHeader3}>{numberWithCommas(builtup)} sqm</Typography>
        </Grid>
        <Grid className={classes.projectInputs}>
          <Typography className={classes.textHeader5}>Type of Construction</Typography>
          <Typography className={classes.textHeader3}>{typeofconstruction}</Typography>
        </Grid>
        <Grid className={classes.projectInputs}>
          <Typography className={classes.textHeader5}>Slab Thickness</Typography>
          <Typography className={classes.textHeader3}>
            {slabThickness} {getQuantityUnit(schema.sc_slabThickness)}
          </Typography>
        </Grid>
        <Grid className={classes.projectInputs}>
          <Typography className={classes.textHeader5}>Beam Depth</Typography>
          <Typography className={classes.textHeader3}>
            {beandepth} {getQuantityUnit(schema.sc_beamDepth)}
          </Typography>
        </Grid>
        <Grid className={classes.projectInputs}>
          <Typography className={classes.textHeader5}>Wall Thickness</Typography>
          <Typography className={classes.textHeader3}>
            {wallThickness} {getQuantityUnit(schema.sc_wallThickness)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ScProjectInputs;
