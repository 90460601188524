import React, { useEffect, useRef } from 'react';
import { Grid, Chip, Typography, TextField, Modal, Fade, Backdrop, Box } from '@material-ui/core';
import PrimaryButton from '../../../../common/button/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CloseIcon from '@material-ui/icons/Close';

export const EMAIL_REGEX = /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

export const Header = () => (
  <>
    <Grid item xs={12}>
      <Typography variant="h5" gutterBottom>
        <b>Add Users to SD+</b>
      </Typography>
    </Grid>
    <Grid item xs={12} style={{ marginBottom: '2%' }}>
      <Typography variant="h6" color="textSecondary">
        Enter email ID of Invitees
      </Typography>
    </Grid>
  </>
);

export const InstructionText = () => (
  <Grid item xs={12} style={{ marginTop: '2%' }}>
    <Typography variant="h6" color="textSecondary" style={{ lineHeight: '22px' }}>
      The invitees will receive an email with a link to create an SD+ account. The link will be active for 7 days.
    </Typography>
  </Grid>
);

export const ErrorMessages = ({ error, emails, classes }) => (
  <>
    {error && <Typography className={classes.errorText}>{error}</Typography>}
    {emails.some((emailObj) => emailObj.error === 'limit') && (
      <Typography className={classes.errorText}>You have reached the limit of active users.</Typography>
    )}
    {emails.some((emailObj) => emailObj.error === 'registered') && <Typography className={classes.errorText}>Email ID already registered.</Typography>}
  </>
);

export const ActionButtons = ({ onCancel, onSend, emailsExist, classes, isFetching, error, emails }) => {
  return (
    <Grid item container justifyContent="space-between" className={classes.actions}>
      <Grid item xs={4} container justifyContent="flex-end" alignItems="center">
        <Typography onClick={onCancel} color="primary" style={{ cursor: 'pointer' }}>
          Cancel
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <PrimaryButton
          children={!isFetching ? 'Send' : 'Sending'}
          variant="contained"
          color="primary"
          disabled={!emailsExist || emails?.some((email) => email.hasOwnProperty('error'))}
          onClick={onSend}
          style={{ cursor: 'pointer' }}
        />
      </Grid>
    </Grid>
  );
};

export const CustomModal = ({ children, open, onClose, className }) => (
  <Modal
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    className={className}
    open={open}
    onClose={onClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{ timeout: 500 }}
  >
    <Fade in={open}>{children}</Fade>
  </Modal>
);

export const InviteUsers = ({ setOpen }) => (
  <Grid item xs={12} container>
    <Grid item xs={10} container justifyContent="flex-end" alignItems="center">
      <Typography onClick={() => setOpen(true)} variant="h5" style={{ color: '#00C58A', cursor: 'pointer' }}>
        <b>Invite Users</b>
      </Typography>
    </Grid>

    <Grid item xs={2} container justifyContent="flex-end" alignItems="center">
      <AddCircleIcon style={{ fontSize: '30px', color: '#00C58A' }} />
    </Grid>
  </Grid>
);

export const EmailChip = ({ email, error, onDelete, classes }) => (
  <Chip
    key={email}
    label={email.length > 20 ? `${email.slice(0, 35)}...` : email}
    onDelete={onDelete}
    className={`${classes.chip} ${error ? 'error' : ''}`}
    color="primary"
    variant="outlined"
    deleteIcon={<CloseIcon style={{ color: 'black' }} />}
  />
);

export const EmailInput = ({ inputValue, onInputChange, onKeyDown, classes }) => (
  <TextField
    id="hiddenInput"
    value={inputValue}
    onChange={onInputChange}
    onKeyDown={(e) => {
      if (e.key === 'Tab') e.preventDefault();
      onKeyDown(e);
    }}
    variant="outlined"
    className={classes.hiddenInput}
  />
);

export const RenderInviteComponent = ({
  classes,
  emails,
  error,
  inputValue,
  handleDeleteEmail,
  setInputValue,
  handleAddEmail,
  handleSendEmails,
  handleCloseParent,
  isFetching,
}) => {
  const containerRef = useRef(null);
  useEffect(() => {
    if (containerRef.current) containerRef.current.scrollTop = containerRef.current.scrollHeight;
  }, [emails]);
  return (
    <Box className={classes.container}>
      <Grid container direction="row">
        <Header />
        <Grid item xs={12}>
          <Box ref={containerRef} className={classes.inputContainer} onClick={() => document.getElementById('hiddenInput').focus()}>
            {emails.map(({ email, error }) => (
              <EmailChip key={email} email={email} error={error} onDelete={() => handleDeleteEmail(email)} classes={classes} />
            ))}
            <EmailInput inputValue={inputValue} onInputChange={(e) => setInputValue(e.target.value)} onKeyDown={handleAddEmail} classes={classes} />
          </Box>
        </Grid>
        <InstructionText />

        <Grid container justifyContent="space-between" alignItems="flex-start" direction="column">
          {!error && <Grid container style={{ marginTop: '18%' }} />}
          {error && <Grid container style={{ marginTop: '15%' }} />}
          {error && <ErrorMessages error={error} emails={emails} classes={classes} />}
          <ActionButtons
            emails={emails}
            error={error}
            onCancel={handleCloseParent}
            onSend={handleSendEmails}
            emailsExist={emails.length > 0}
            classes={classes}
            isFetching={isFetching}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
