import React, { useEffect, useState } from 'react';
import axios from '../../../../../config/Axios';
import ManualPIP from './interiorPaintComponents/ManualPIP';
import PresetsPIP from './interiorPaintComponents/PresetsPIP';
import { GreenRadio } from '../../../../common/RadioButton/GreenRadio';
import { Grid, Typography, FormControl, FormControlLabel, RadioGroup } from '@material-ui/core';
import CustomDropdown from '../../../interventions/interventionDetails/common/CustomDropdown';

export const DropDownItems = {
  brand: 'project__pi_interiorPaintWaterBasedBrandName',
  finish: 'project__pi_interiorPaintWaterBasedFinish',
  paint: 'project__pi_interiorPaintWaterBasedPaintName',
};

export const TextBoxItems = {
  manualBrand: 'project__pi_interiorPaintManualBrandName',
  manualPaint: 'project__pi_interiorPaintManualPaintName',
  manualCoverageArea: 'project__pi_interiorPaintManualCoverageArea',
  manualCapex: 'project__pi_interiorPaintManualCapex',
  manualEC: 'project__pi_interiorPaintManualEC',
};

const InteriorPaints = ({
  data,
  interiorPaintBrands,
  appendChanged,
  projectPIP,
  interiorPaintUserChoicePIPValue,
  interiorPaintUserChoicePIP,
  manualPips,
  pipValues,
}) => {
  const [selectedPaintFinishs, setSelectedPaintFinishs] = useState('');
  const [selectedPaints, setSelectedPaints] = useState('');
  const [selectedPaintBrand, setSelectedPaintBrand] = useState('');
  const [selectedPaintFinish, setSelectedPaintFinish] = useState('');
  const [selectedPaint, setSelectedPaint] = useState('');

  const DropDowns = Object.entries(DropDownItems).map(([_, value]) => data?.find((pip) => pip.name === value));

  const TextBoxes = Object.entries(TextBoxItems).map(([_, value]) => data?.find((pip) => pip.name === value));

  const vocDropDown = data?.find((pip) => pip.name === 'project__pi_interiorPaintManualVocLevel');

  useEffect(() => {
    if (projectPIP) {
      const selectedPresetBrand = projectPIP.find((d) => d.PIPScheme.name === DropDownItems.brand)?.value;
      const selectedPresetFinish = projectPIP.find((d) => d.PIPScheme.name === DropDownItems.finish)?.value;
      const selectedPresetPaint = projectPIP.find((d) => d.PIPScheme.name === DropDownItems.paint)?.value;

      setSelectedPaintBrand(selectedPresetBrand);
      setSelectedPaintFinish(selectedPresetFinish);
      setSelectedPaint(selectedPresetPaint);
    }
  }, [projectPIP]);

  useEffect(() => {
    if (selectedPaintBrand) {
      axios.get(`PaintProvider?list=Finish&type=Interior Paints&brand=${selectedPaintBrand}`).then((res) => setSelectedPaintFinishs(res?.data.data.data));
    }
  }, [selectedPaintBrand]);

  useEffect(() => {
    if (selectedPaintBrand && selectedPaintFinish) {
      axios
        .get(`PaintProvider?list=Paint&brand=${selectedPaintBrand}&finish=${selectedPaintFinish}&type=Interior Paints`)
        .then((res) => setSelectedPaints(res?.data.data.data));
    }
  }, [selectedPaintBrand, selectedPaintFinish]);

  const renderPresetPIP = (dropdown, index) => {
    const { displayName, _id } = dropdown;

    const presetProps = {
      key: index,
      _id,
      displayName,
      appendChanged: (a, b) => appendChanged(a, b),
    };

    switch (displayName) {
      case 'Brand':
        return (
          <PresetsPIP
            index={index}
            {...presetProps}
            values={interiorPaintBrands}
            disabledValue={false}
            selectedValue={selectedPaintBrand}
            setSelected={setSelectedPaintBrand}
          />
        );
      case 'Finish':
        return (
          <PresetsPIP
            index={index}
            {...presetProps}
            values={selectedPaintFinishs}
            disabledValue={!selectedPaintBrand}
            selectedValue={selectedPaintFinish}
            setSelected={setSelectedPaintFinish}
          />
        );
      case 'Paint':
        return (
          <PresetsPIP
            index={index}
            {...presetProps}
            values={selectedPaints}
            disabledValue={!selectedPaintFinish}
            selectedValue={selectedPaint}
            setSelected={setSelectedPaint}
          />
        );
      default:
        return null;
    }
  };

  const renderVOCDropdown = () => (
    <Grid xs={12} style={{ marginTop: '5%', marginLeft: '5%' }}>
      <Grid container alignItems="flex-start" item xs={12} style={{ marginBottom: '4%' }}>
        <Typography variant="h6">{vocDropDown.displayName}</Typography>
      </Grid>
      <CustomDropdown
        value={pipValues[vocDropDown._id]}
        onChange={(e) => {
          e.preventDefault();
          appendChanged(vocDropDown._id, e.target.value);
        }}
        options={vocDropDown.options}
        disabled={false}
      />
    </Grid>
  );

  return (
    <Grid container direction="row" xs={12} style={{ height: 'Auto' }}>
      <Grid container alignItems="flex-start" item xs={12} style={{ marginLeft: '2%', marginTop: '2%' }}>
        <Typography variant="h5">
          <b>Interior Paint</b>
        </Typography>
      </Grid>

      <Grid container alignItems="flex-start" direction="row" xs={12} item style={{ marginLeft: '2%' }}>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="exterior paint"
            name="paint schemes"
            value={pipValues[interiorPaintUserChoicePIP._id] || ''}
            onChange={(e) => appendChanged(interiorPaintUserChoicePIP._id, e.target.value)}
          >
            {interiorPaintUserChoicePIP.options.map((option, index) => (
              <React.Fragment key={index}>
                <FormControlLabel value={option} control={<GreenRadio />} label={option} />

                {interiorPaintUserChoicePIPValue === 'Water Based' && index === 1 && <>{DropDowns?.map(renderPresetPIP)}</>}

                {interiorPaintUserChoicePIPValue === 'Manual' && index === 2 && (
                  <>
                    {manualPips &&
                      TextBoxes?.map(({ name: textbox, displayName, _id, description }, index) => (
                        <ManualPIP
                          index={index}
                          key={_id}
                          description={description}
                          textbox={textbox}
                          displayName={displayName}
                          _id={_id}
                          pipValues={pipValues}
                          projectPIP={projectPIP}
                          appendChanged={(a, b) => appendChanged(a, b)}
                        />
                      ))}
                    {vocDropDown && renderVOCDropdown()}
                  </>
                )}
              </React.Fragment>
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default InteriorPaints;
