import { api } from './http';

const getPresignedURL = async (fileName) => {
  const response = await api.get(`/v2/organisations/get-logo-upload-url?name=${fileName}`);
  return response?.data?.data?.body?.data;
};

const putOrganisation = async (organisationId, payload) => {
  const res = await api.put(`/v2/organisations/${organisationId}`, payload);
  return res;
};

const postOrganisationLogo = async (payload) => {
  const res = await api.post(`/v2/organisations/update-logo`, payload);
  return res;
};

const getOrganisationDetailsByOrgId = async (organisationId) => {
  const res = await api.get(`/v2/organisations/${organisationId}`);
  return res?.data?.data?.body?.data;
};

export { getPresignedURL, putOrganisation, postOrganisationLogo, getOrganisationDetailsByOrgId };
