import Tour from 'reactour';
import { Button, Grid, Typography, makeStyles } from '@material-ui/core';
import { useContext, useEffect } from 'react';
import { walkthroughContext } from '../../../../../projectRouting/Index';
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { UserContext } from '../../../../../routing/IndexRouting';
import api from '../../../../../http';

const useStyles = makeStyles((theme) => ({
  tourtexthead: {
    fontStyle: 'normal',
    fontWeight: 850,
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'center',
    letterSpacing: '0.714286px',
    color: '#000000',
    paddingBottom: '10px',
  },
  tourtext: {
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '24px',
    textAlign: 'justify',
    letterSpacing: '0.714286px',
    color: '#000000',
  },
}));

const InterventionDetailsTour = ({ showTour }) => {
  const classes = useStyles();
  const { uiWalkthroughContext, handleMyWalkthrough } = useContext(walkthroughContext);
  const { userDetails } = useContext(UserContext);
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);

  const closeTour = async () => {
    handleMyWalkthrough(false);
    clearAllBodyScrollLocks();
  };

  const handleEnd = async () => {
    handleMyWalkthrough(false);
    await api.postUserOnboarding(userDetails?._id, { onboarding: { showHowItWorks: false, showBasics: userDetails?.onboarding?.showBasics } });
    clearAllBodyScrollLocks();
  };

  useEffect(() => {
    clearAllBodyScrollLocks();
  }, []);

  const tourProps = {
    isOpen: uiWalkthroughContext,
    onRequestClose: closeTour,
    steps: [
      {
        selector: '[data-tour="parameter"]',
        content: (
          <Grid style={{ marginBottom: '-15px' }}>
            <Typography className={classes.tourtexthead}>Intervention Parameters</Typography>
            <Typography className={classes.tourtext}>Edit these parameters to customise solutions as per user requirements</Typography>
          </Grid>
        ),
        position: 'right',
        style: {
          backgroundColor: '#FFFFFF',
          borderRadius: '8px',
          height: '150px',
        },
      },
      {
        selector: '[data-tour="datacost"]',
        content: (
          <Grid style={{ marginBottom: '-15px' }}>
            <Typography className={classes.tourtexthead}>Data & Cost Assumptions</Typography>
            <Typography className={classes.tourtext}>View all data & cost assumptions that were used to calculate the solution values. </Typography>
            <Typography className={classes.tourtext} style={{ paddingTop: '10px' }}>
              Some assumptions can be edited while some are considered universal values and cannot be edited
            </Typography>
          </Grid>
        ),
        position: 'bottom',
        style: {
          backgroundColor: '#FFFFFF',
          borderRadius: '8px',
          height: '240px',
        },
      },
      /* __________________________________Vendor Tour code goes here___________________________________*/
    ],
  };
  return (
    <Tour
      {...tourProps}
      onAfterOpen={disableBody}
      onBeforeClose={enableBody}
      lastStepNextButton={
        <Button style={{ color: '#29E1AC', marginLeft: '150%', marginTop: '-60px', backgroundColor: '#FFFFFF', fontSize: '12px' }} onClick={handleEnd}>
          End
        </Button>
      }
      disableKeyboardNavigation={['esc']}
      showNavigation={false}
      showNumber={false}
      prevButton
      nextButton={<Button style={{ color: '#29E1AC', backgroundColor: '#FFFFFF', marginLeft: '150%', fontSize: '12px' }}>Next</Button>}
      disableInteraction={true}
      badgeContent={(curr, tot) => `${curr} of ${tot}`}
      rounded={12}
      startAt={0}
    />
  );
};

export default InterventionDetailsTour;
