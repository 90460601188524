import { MetafireInterventions } from '../../../metafire/utils/types';

export const MockOldDataForInsights = {
  id: '661d0bc9cc4a19663e43250a',
  name: 'Citrine',
  category: 'residential',
  createdBy: '62c67f17fd7444f41d0bb06a',
  location: {
    name: 'Sannatammanahalli, Battarahalli, Bengaluru, Karnataka 560049, India',
    type: 'Point',
    coordinates: [13.0214234, 77.708722],
  },
  organization: {
    uri: '',
  },
  uri: '',
  subcategory: 'apartment',
  goals: {
    categories: [
      [
        {
          name: 'Water',
          quantities: {
            targetPercentage: {
              id: '',
              name: 'random',
              label: 'random',
              uri: '',
              value: 71.55,
              tags: [],
              unit: '%',
            },
            actualPercentage: {
              id: '',
              name: 'random',
              label: 'random',
              uri: '',
              value: 33.01,
              tags: [],
              unit: '%',
            },
          },
          metadata: {
            goalMappingId: '661d0bcecc4a19663e432570',
            title: 'Zero External Dependency',
            subtitle: 'All water is derived from reusing & sustainably using ground water',
            note: 'We recommend reusing all grey & black water',
          },
        },
        {
          name: 'Waste',
          quantities: {
            targetPercentage: {
              id: '',
              name: 'random',
              label: 'random',
              uri: '',
              value: 86.68,
              tags: [],
              unit: '%',
            },
            actualPercentage: {
              id: '',
              name: 'random',
              label: 'random',
              uri: '',
              value: 86.68,
              tags: [],
              unit: '%',
            },
          },
          metadata: {
            goalMappingId: '661d0bcecc4a19663e432572',
            title: 'Sustainably Managed Waste',
            subtitle: 'All waste generated on site is managed in a sustainable manner',
            note: 'We recommend converting Wet waste into Biogas',
          },
        },
        {
          name: 'Energy',
          quantities: {
            targetPercentage: {
              id: '',
              name: 'random',
              label: 'random',
              uri: '',
              value: 100,
              tags: [],
              unit: '%',
            },
            actualPercentage: {
              id: '',
              name: 'random',
              label: 'random',
              uri: '',
              value: 100,
              tags: [],
              unit: '%',
            },
          },
          metadata: {
            goalMappingId: '661d0bcecc4a19663e432574',
            title: 'Onsite Renewable Energy',
            subtitle: 'Harnessing the maximum possible Energy from Onsite Renewable Sources',
            note: 'We recommend using all available rooftop space for Solar installations',
          },
        },
      ],
    ],
  },
  marketing: [
    {
      name: 'energy',
      summary: {
        financial: {
          capex: {
            id: 'energy.Capex',
            name: 'energy.Capex',
            label: '',
            tags: ['Capex', 'Category'],
            uri: '',
            unit: 'Rs',
            editable: false,
            type: 'InterventionParameter',
            value: 6083771,
            defaultValue: 0,
          },
          opex: {
            id: 'energy.Opex',
            name: 'energy.Opex',
            label: '',
            tags: ['Opex', 'Category'],
            uri: '',
            unit: 'Rs',
            editable: false,
            type: 'InterventionParameter',
            value: 1589010,
            defaultValue: 0,
          },
        },
        resource: {
          savings: {
            id: 'energy.Savings',
            name: 'energy.Savings',
            label: '',
            tags: ['Resource', 'Category'],
            uri: '',
            unit: 'kWh',
            editable: false,
            type: 'InterventionParameter',
            value: 244463,
            defaultValue: 0,
          },
        },
      },
      consumption: [
        {
          name: 'Energy',
          quantities: {
            before: {
              percentageContribution: [
                {
                  id: '',
                  name: 'before.residentialHotWater',
                  label: 'Residential Hot Water',
                  uri: '',
                  value: 38,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'before.residentialLighting',
                  label: 'Residential Lighting',
                  uri: '',
                  value: 21,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'before.residentialFan',
                  label: 'Residential Fan',
                  uri: '',
                  value: 13,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'before.homeAppliances',
                  label: 'Home Appliances',
                  uri: '',
                  value: 12,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'before.residentialHVAC',
                  label: 'Residential HVAC',
                  uri: '',
                  value: 3,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'before.commonArea',
                  label: 'Common Area',
                  uri: '',
                  value: 13,
                  tags: [],
                  unit: '%',
                },
              ],
            },
            after: {
              percentageContribution: [
                {
                  id: '',
                  name: 'after.residentialHotWater',
                  label: 'Residential Hot Water',
                  uri: '',
                  value: 37,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'after.residentialLighting',
                  label: 'Residential Lighting',
                  uri: '',
                  value: 21,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'after.residentialFan',
                  label: 'Residential Fan',
                  uri: '',
                  value: 13,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'after.homeAppliances',
                  label: 'Home Appliances',
                  uri: '',
                  value: 12,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'after.residentialHVAC',
                  label: 'Residential HVAC',
                  uri: '',
                  value: 3,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'after.commonArea',
                  label: 'Common Area',
                  uri: '',
                  value: 9,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'after.savings',
                  label: 'Savings',
                  uri: '',
                  value: 5,
                  tags: [],
                  unit: '%',
                },
              ],
            },
          },
        },
      ],
      details: {
        total: {
          id: '',
          name: 'savings.total',
          label: 'Total Savings',
          uri: '',
          value: 5,
          tags: [],
          unit: '%',
        },
        interventionsBySubcategory: [
          {
            name: 'Renewable Sources',
            total: {
              id: '',
              name: 'renewableSources.total',
              label: 'Renewable Sources Total',
              uri: '',
              value: 5,
              tags: [],
              unit: '%',
            },
            quantities: {
              contribution: [
                {
                  id: '',
                  name: 'solarPv.contribution',
                  label: 'Solar PV',
                  uri: '',
                  value: 4,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'solarWaterHeater.contribution',
                  label: 'Solar Water Heater',
                  uri: '',
                  value: 1,
                  tags: [],
                  unit: '%',
                },
              ],
            },
          },
        ],
      },
    },
    {
      name: 'waste',
      summary: {
        financial: {
          capex: {
            id: 'waste.Capex',
            name: 'waste.Capex',
            label: '',
            tags: ['Capex', 'Category'],
            uri: '',
            unit: 'Rs',
            editable: false,
            type: 'InterventionParameter',
            value: 80000,
            defaultValue: 0,
          },
          opex: {
            id: 'waste.Opex',
            name: 'waste.Opex',
            label: '',
            tags: ['Opex', 'Category'],
            uri: '',
            unit: 'Rs',
            editable: false,
            type: 'InterventionParameter',
            value: 95829,
            defaultValue: 0,
          },
        },
        resource: {
          savings: {
            id: 'waste.Savings',
            name: 'waste.Savings',
            label: '',
            tags: ['Resource', 'Category'],
            uri: '',
            unit: 'kg',
            editable: false,
            type: 'InterventionParameter',
            value: 288670,
            defaultValue: 0,
          },
        },
      },
      consumption: [
        {
          name: 'Waste',
          quantities: {
            before: {
              percentageContribution: [
                {
                  id: '',
                  name: 'before.organicWaste',
                  label: 'Organic Waste',
                  uri: '',
                  value: 0,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'before.dryWaste',
                  label: 'Dry Waste',
                  uri: '',
                  value: 0,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'before.sanitaryWaste',
                  label: 'Sanitary Waste',
                  uri: '',
                  value: 0,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'before.medicalWaste',
                  label: 'Medical Waste',
                  uri: '',
                  value: 0,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'before.eWaste',
                  label: 'E-Waste',
                  uri: '',
                  value: 0,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'before.rejectWaste',
                  label: 'Reject Waste',
                  uri: '',
                  value: 13.6,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'before.savings',
                  label: 'Savings',
                  uri: '',
                  value: 86.4,
                  tags: [],
                  unit: '%',
                },
              ],
            },
            after: {
              percentageContribution: [
                {
                  id: '',
                  name: 'after.organicWaste',
                  label: 'Organic Waste',
                  uri: '',
                  value: -0.28,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'after.dryWaste',
                  label: 'Dry Waste',
                  uri: '',
                  value: 0,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'after.sanitaryWaste',
                  label: 'Sanitary Waste',
                  uri: '',
                  value: 0,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'after.medicalWaste',
                  label: 'Medical Waste',
                  uri: '',
                  value: 0,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'after.eWaste',
                  label: 'E-Waste',
                  uri: '',
                  value: 0,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'after.rejectWaste',
                  label: 'Reject Waste',
                  uri: '',
                  value: 13.6,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'after.savings',
                  label: 'Savings',
                  uri: '',
                  value: 86.68,
                  tags: [],
                  unit: '%',
                },
              ],
            },
          },
        },
      ],
      details: {
        total: {
          id: '',
          name: 'savings.total',
          label: 'Total Savings',
          uri: '',
          value: 87,
          tags: [],
          unit: '%',
        },
        interventionsBySubcategory: [
          {
            name: 'Reduction',
            total: {
              id: '',
              name: 'reduction.total',
              label: 'Reduction Total',
              uri: '',
              value: 100,
              tags: [],
              unit: '%',
            },
            quantities: {
              contribution: [
                {
                  id: '',
                  name: 'wwb.contribution',
                  label: 'Wet Waste Management',
                  uri: '',
                  value: 60.28,
                  tags: [],
                  unit: '%',
                },
              ],
            },
          },
          {
            name: 'Management',
            total: {
              id: '',
              name: 'management.total',
              label: 'Management Total',
              uri: '',
              value: 100,
              tags: [],
              unit: '%',
            },
            quantities: {
              contribution: [
                {
                  id: '',
                  name: 'sni.contribution',
                  label: 'Sanitary Napkin Incinerator',
                  uri: '',
                  value: 4,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'casp.contribution',
                  label: 'Collection And Segregation Point',
                  uri: '',
                  value: 22,
                  tags: [],
                  unit: '%',
                },
              ],
            },
          },
        ],
      },
    },
    {
      name: 'water',
      summary: {
        financial: {
          capex: {
            id: 'water.Capex',
            name: 'water.Capex',
            label: '',
            tags: ['Capex', 'Category'],
            uri: '',
            unit: 'Rs',
            editable: false,
            type: 'InterventionParameter',
            value: 9384000,
            defaultValue: 0,
          },
          opex: {
            id: 'water.Opex',
            name: 'water.Opex',
            label: '',
            tags: ['Opex', 'Category'],
            uri: '',
            unit: 'Rs',
            editable: false,
            type: 'InterventionParameter',
            value: 2969294,
            defaultValue: 0,
          },
        },
        resource: {
          savings: {
            id: 'water.Savings',
            name: 'water.Savings',
            label: '',
            tags: ['Resource', 'Category'],
            uri: '',
            unit: 'kL',
            editable: false,
            type: 'InterventionParameter',
            value: 44865,
            defaultValue: 0,
          },
        },
      },
      consumption: [
        {
          name: 'Water',
          quantities: {
            before: {
              percentageContribution: [
                {
                  id: '',
                  name: 'before.drinking',
                  label: 'Drinking',
                  uri: '',
                  value: 4574.59,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'before.domestic',
                  label: 'Domestic',
                  uri: '',
                  value: 71551.19,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'before.flushing',
                  label: 'Flushing',
                  uri: '',
                  value: 11754.04,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'before.commonArea',
                  label: 'Common Area',
                  uri: '',
                  value: 33.16,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'before.savings',
                  label: 'Savings',
                  uri: '',
                  value: 17.74,
                  tags: [],
                  unit: '%',
                },
              ],
            },
            after: {
              percentageContribution: [
                {
                  id: '',
                  name: 'after.drinking',
                  label: 'Drinking',
                  uri: '',
                  value: 4574.59,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'after.domestic',
                  label: 'Domestic',
                  uri: '',
                  value: 71530.54,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'after.flushing',
                  label: 'Flushing',
                  uri: '',
                  value: 11751.8,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'after.commonArea',
                  label: 'Common Area',
                  uri: '',
                  value: 33.16,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'after.savings',
                  label: 'Savings',
                  uri: '',
                  value: -53.8,
                  tags: [],
                  unit: '%',
                },
              ],
            },
          },
        },
      ],
      details: {
        total: {
          id: '',
          name: 'savings.total',
          label: 'Total Savings',
          uri: '',
          value: -53.8,
          tags: [],
          unit: '%',
        },
        interventionsBySubcategory: [
          {
            name: 'Demand Optimization',
            total: {
              id: '',
              name: 'demandOptimization.total',
              label: 'Demand Optimization Total',
              uri: '',
              value: 31085.14,
              tags: [],
              unit: '%',
            },
            quantities: {
              contribution: [
                {
                  id: '',
                  name: 'cro.contribution',
                  label: 'Centralized RO',
                  uri: '',
                  value: 0,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'ef.contribution',
                  label: 'Efficient Fixtures',
                  uri: '',
                  value: 22868.15,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'df.contribution',
                  label: 'Dual Flush',
                  uri: '',
                  value: 3010.6,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'swm.contribution',
                  label: 'Smart Water Meters',
                  uri: '',
                  value: 5206.39,
                  tags: [],
                  unit: '%',
                },
              ],
            },
          },
          {
            name: 'Treatment And Re-use',
            total: {
              id: '',
              name: 'treatmentReuse.total',
              label: 'Treatment And Re-use Total',
              uri: '',
              value: 64872.18,
              tags: [],
              unit: '%',
            },
            quantities: {
              contribution: [
                {
                  id: '',
                  name: 'gwr.contribution',
                  label: 'Grey Water Re-use',
                  uri: '',
                  value: 52360.84,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'bwr.contribution',
                  label: 'Black Water Re-use',
                  uri: '',
                  value: 12511.34,
                  tags: [],
                  unit: '%',
                },
              ],
            },
          },
          {
            name: 'Source Creation',
            total: {
              id: '',
              name: 'sourceCreation.total',
              label: 'Source Creation Total',
              uri: '',
              value: 1268.08,
              tags: [],
              unit: '%',
            },
            quantities: {
              contribution: [
                {
                  id: '',
                  name: 'rrwh.contribution',
                  label: 'Rooftop Rainwater Harvesting',
                  uri: '',
                  value: 1268.08,
                  tags: [],
                  unit: '%',
                },
                {
                  id: '',
                  name: 'srwh.contribution',
                  label: 'Surface Rainwater Harvesting',
                  uri: '',
                  value: 0,
                  tags: [],
                  unit: '%',
                },
              ],
            },
          },
        ],
      },
    },
  ],
  sustainabilityLevel: {
    id: '',
    name: 'sustainabilityLevel',
    label: 'Sustainability Level',
    uri: '',
    value: 1,
    tags: [],
    unit: 'Level',
  },
  locationBasedInsights: {
    aqi: {
      id: '',
      name: 'aqi',
      label: 'AQI',
      uri: '',
      value: {
        aqi: 79,
        biggestPollutant: 'PM2.5',
        color: 'Yellow',
        stationName: 'BWSSB Kadabesanahalli, Bengaluru - CPCB',
      },
      tags: [],
      unit: 'AQI',
    },
    temperature: {
      id: '',
      name: 'temperature',
      label: 'Temperature',
      uri: '',
      value: {
        avgHumidity: 64.01,
        avgRainfall: 1018.2,
        avgTemp: 26.38,
        avgWindSpeed: 12.81,
        maxTemp: 29.63,
        minTemp: 20.18,
      },
      tags: [],
      unit: 'Temperature',
    },
    igbc: {
      id: '',
      name: 'IGBC',
      label: 'IGBC',
      uri: '',
      value: [
        {
          _id: '61a9ba737f39333ab0c90123',
          stationName: 'Renaissance Nature Walk',
          latitude: 13.00652656,
          longitude: 77.71813698,
          igbcRating: 'Gold',
          type: 'Luxury',
          launchDate: 'Dec-2019',
          sitearea: '4.2 Acres',
          builtupArea: null,
          noOfapts: 147,
          configurations: '4 BHK',
          avgPricePerSqftInArea: 8947,
          avgPricePerSqftForProject: null,
          startingPrice: '2.64 cr',
          address: '541, 10th Main Rd, 5th Block, Jayanagar, Bengaluru, Karnataka 560041, India',
          createdAt: '2021-12-03T06:34:27.324Z',
          __v: 0,
        },
        {
          _id: '61a9be437f39333ab0c90129',
          stationName: 'Fortius Waterscape',
          latitude: 13.0197,
          longitude: 77.7211,
          igbcRating: 'Gold',
          type: 'Affordable',
          launchDate: 'Jun 2017',
          sitearea: '2 Acres',
          builtupArea: null,
          noOfapts: 156,
          configurations: '2,3 BHK',
          avgPricePerSqftInArea: 5200,
          avgPricePerSqftForProject: null,
          startingPrice: '0.68 cr',
          address: 'K R Puram, Bangalore, Karnataka',
          createdAt: '2021-12-03T06:50:43.351Z',
          __v: 0,
        },
      ],
      tags: [],
      unit: 'IGBC',
    },
  },
  context: {
    cache: {
      PaintsDBCache: {},
    },
    project: {
      id: '661d0bc9cc4a19663e43250a',
      pip: {
        Location: 'Sannatammanahalli, Battarahalli, Bengaluru, Karnataka, India',
        RooftopArea: 1611,
        BuiltUpArea: 82932.51,
        NonavailableRooftop: 150,
        Studio: 0,
        BHK1: 45,
        BHK2: 73,
        BHK3: 253,
        BHK4: 64,
        NumberOfFloors: 33,
        WWR: 0.25,
        perimeter: 2004.6,
        Floor2FloorHeight: 2.975,
        SoftSurfaceArea: 5782,
        Sitearea: 17419.72,
        BasecaseSPVCapacity: 0,
        FlooringUtilityandBalcony: 'Vitrified Tiles',
        FlooringBathroom: 'Ceramic Tiles',
        FlooringBedroom: 'Vitrified Tiles',
        FlooringKitchen: 'Vitrified Tiles',
        FlooringLivingandDining: 'Vitrified Tiles',
        SingleCarpet: 47.6,
        DoubleCarpet: 91.65,
        ThreeCarpet: 128.05,
        FourCarpet: 156.3,
        materials: {
          interiorpaint: {
            solvent: {
              enabled: true,
            },
            manual: {
              enabled: false,
              input: {},
            },
            preset: {
              enabled: false,
              input: {},
            },
          },
          concrete: {
            typeofconcrete: 'Ordinary Portland Cement (OPC)',
          },
          manual: {
            input: {},
          },
          preset: {
            input: {
              type: 'Solvent-Based Paints',
            },
          },
        },
        LandscapeArea: 13354,
        NoOfCarParks: 550,
        AreaOfLobbiesAndCorridor: 2250.92,
        SwimmingPoolSurfaceArea: 413.1,
        water: {
          manual: {
            enabled: false,
            flows: {},
          },
          preset: {
            enabled: true,
            faucets: {
              brand: 'Jaquar',
              tags: 'Basic',
            },
            ceramics: {
              brand: 'Hindware',
              tags: 'Basic',
            },
          },
          parameters: {
            STP: {
              typeOfSTP: 'Separate',
            },
            CRO: {
              CROinstalled: {
                enabled: true,
              },
              individualROsystem: {
                enabled: true,
              },
            },
            GWR: {},
          },
        },
        waste: {
          paramters: {
            WWB: {
              WWBUserBaseCase: 'Default',
            },
          },
        },
      },
      global: {
        parameters: {
          UtilityPricePerkWh: 6.5,
        },
      },
      energy: {
        goal: {},
        parameters: {},
      },
      waste: {
        goal: {},
        parameters: {},
      },
      water: {
        goal: {},
        parameters: {},
      },
    },
    intervention: {
      solarpv: {
        enabled: true,
        parameters: {},
        solarpv: {
          parameters: {
            SPVCapacity: 50,
            CapexMultiplier: 41640,
            MultiplierSource: 'DEFAULT',
            SolarModule: 'Premium',
            RooftopAreaPerkWp: 10,
          },
        },
      },
      solarwaterheater: {
        enabled: true,
        parameters: {},
      },
      wwb: {
        enabled: true,
        parameters: {},
      },
      casp: {
        enabled: true,
        parameters: {},
      },
      sni: {
        enabled: true,
        parameters: {},
      },
      cro: {
        enabled: false,
        parameters: {},
      },
      efficientfixtures: {
        enabled: true,
        parameters: {},
      },
      smartwatermeters: {
        enabled: true,
        parameters: {},
      },
      dualflush: {
        enabled: true,
        parameters: {},
      },
      rrwh: {
        enabled: true,
        parameters: {},
      },
      srwh: {
        enabled: true,
        parameters: {},
      },
      bwr: {
        enabled: true,
        parameters: {},
      },
      gwr: {
        enabled: false,
        parameters: {},
      },
      exteriorPaints: {
        enabled: true,
        parameters: {},
      },
      interiorPaints: {
        enabled: true,
        parameters: {},
      },
      structuralsteel: {
        enabled: true,
        parameters: {},
      },
      concrete: {
        enabled: true,
        parameters: {},
      },
      livinganddining: {
        enabled: true,
        parameters: {},
      },
      bedroom: {
        enabled: true,
        parameters: {},
      },
      kitchen: {
        enabled: true,
        parameters: {},
      },
      bathroom: {
        enabled: true,
        parameters: {},
      },
      utilityandbalcony: {
        enabled: true,
        parameters: {},
      },
      custom: {},
    },
    vendor: {
      bwr: {
        parameters: {
          capexMultiplier: 50000,
          resourceMultiplier: 85,
          areaMultiplier: 3.8,
        },
      },
      sni: {
        parameters: {},
      },
      solarwaterheater: {
        parameters: {
          capexMultiplier: 118,
          resourceMultiplier: 15,
          areaMultiplier: 0.03,
        },
      },
      wwb: {
        parameters: {
          capexMultiplier: 4000,
          resourceMultiplier: 0.2,
          areaMultiplier: 0.056,
        },
      },
      casp: {
        parameters: {
          capexMultiplier: 1,
          resourceMultiplier: 1,
          areaMultiplier: 2,
        },
      },
      cro: {
        parameters: {
          capexMultiplier: 25,
          resourceMultiplier: 0.7,
          areaMultiplier: 20,
        },
      },
      dualflush: {
        parameters: {
          capexMultiplier: 7000,
          resourceMultiplier: 18,
          areaMultiplier: 1,
        },
      },
      smartwatermeters: {
        parameters: {
          capexMultiplier: 12000,
          resourceMultiplier: 7,
          areaMultiplier: 0,
        },
      },
      gwr: {
        parameters: {
          capexMultiplier: 50000,
          resourceMultiplier: 85,
          areaMultiplier: 3.8,
        },
      },
      rrwh: {
        parameters: {},
      },
      srwh: {
        parameters: {},
      },
      solarpv: {
        parameters: {
          capexMultiplier: 45000,
          resourceMultiplier: 4.4,
          areaMultiplier: 10,
        },
      },
      efficientfixtures: {
        parameters: {
          capexMultiplier: 89,
          resourceMultiplier: 10,
          areaMultiplier: 0,
        },
      },
    },
    userInput: {
      NumberOfYears: 3,
    },
    iip: {
      id: '661d0bcecc4a19663e43256b',
      projectID: '661d0bc9cc4a19663e43250a',
      allmappings: [],
    },
  },
};

export const MockSortOfInterventionInsights = {
  data: {
    success: true,
    data: {
      version: 'v1',
      data: {
        interventions: {
          quantities: {
            overall: {
              finance: {
                capex: {
                  id: 'projectService_capex',
                  rw: 'r',
                  value: -6715897064.342646,
                  label: null,
                  unit: null,
                  type: 'number',
                  tags: ['Project', 'Capex'],
                },
                capexConverted: {
                  id: 'projectService_capexConverted',
                  rw: 'r',
                  value: -2589958081.2065487,
                  label: null,
                  unit: null,
                  type: 'number',
                  tags: ['Project', 'CapexConverted'],
                },
                opexSavings: {
                  id: 'projectService_opex',
                  rw: 'r',
                  value: 82864903045377.72,
                  label: null,
                  unit: null,
                  type: 'number',
                  tags: ['Project', 'Opex'],
                },
                irr: {
                  id: 'projectService_irr',
                  rw: 'r',
                  value: null,
                  label: null,
                  unit: null,
                  type: 'number',
                  tags: ['Project', 'IRR'],
                },
                paybackPeriod: {
                  id: 'projectService_paybackPeriod',
                  rw: 'r',
                  value: 0,
                  label: null,
                  unit: null,
                  type: 'number',
                  tags: ['Project', 'PaybackPeriod'],
                },
                overallExpenses: {
                  id: 'projectService_overallExpenses',
                  rw: 'r',
                  value: -82871618942442.06,
                  label: null,
                  unit: null,
                  type: 'number',
                  tags: ['Project', 'OverallExpenses'],
                },
                totalCapex: {
                  id: 'projectService_totalCapex',
                  rw: 'r',
                  value: 12807944996.58846,
                  label: null,
                  unit: null,
                  type: 'number',
                  tags: ['Project', '_TotalCapex'],
                },
                totalCapexConverted: {
                  id: 'projectService_totalCapexConverted',
                  rw: 'r',
                  value: 4942831445.880293,
                  label: null,
                  unit: null,
                  type: 'number',
                  tags: ['Project', '_TotalCapexConverted'],
                },
              },
              resource: {
                carbon: {
                  id: 'projectService_carbon',
                  rw: 'r',
                  value: 7279755.721288171,
                  label: null,
                  unit: null,
                  type: 'number',
                  tags: ['Project', 'Carbon'],
                },
                carbonConverted: {
                  id: 'projectService_carbonConverted',
                  rw: 'r',
                  value: 1582554,
                  label: null,
                  unit: null,
                  type: 'number',
                  tags: ['Project', 'CarbonConverted'],
                },
              },
            },
          },
          categorized: [
            {
              name: 'Materials',
              quantities: {
                finance: {
                  capex: {
                    id: 'materialsService_capex',
                    rw: 'r',
                    value: -193793.86285714284,
                    label: null,
                    unit: null,
                    type: 'number',
                    tags: ['Category', 'Materials', 'Capex'],
                  },
                  capexConverted: {
                    id: 'materialsService_capexConverted',
                    rw: 'r',
                    value: -74788.7658483889,
                    label: null,
                    unit: null,
                    type: 'number',
                    tags: ['Category', 'Materials', 'CapexConverted'],
                  },
                  opexSavings: {
                    id: 'materialsService_opex',
                    rw: 'r',
                    value: 0,
                    label: null,
                    unit: null,
                    type: 'number',
                    tags: ['Category', 'Materials', 'Opex'],
                  },
                  totalCapex: {
                    id: 'materialsService_totalCapex',
                    rw: 'r',
                    value: 0,
                    label: null,
                    unit: null,
                    type: 'number',
                    tags: ['Category', 'Materials', '_TotalCapex'],
                  },
                  totalCapexConverted: {
                    id: 'materialsService_totalCapexConverted',
                    rw: 'r',
                    value: 0,
                    label: null,
                    unit: null,
                    type: 'number',
                    tags: ['Category', 'Materials', '_TotalCapexConverted'],
                  },
                },
                resource: {
                  category: {
                    id: 'materialsService_resourceSaving',
                    rw: 'r',
                    value: -220.9730713714286,
                    label: null,
                    unit: null,
                    type: 'number',
                    tags: ['Category', 'Materials', 'Resource'],
                  },
                  carbon: {
                    id: 'materialsService_carbon',
                    rw: 'r',
                    value: -220.63328340504205,
                    label: null,
                    unit: null,
                    type: 'number',
                    tags: ['Category', 'Materials', 'Carbon'],
                  },
                  carbonConverted: {
                    id: 'materialsService_carbonConverted',
                    rw: 'r',
                    value: -48,
                    label: null,
                    unit: null,
                    type: 'number',
                    tags: ['Category', 'Materials', 'CarbonConverted'],
                  },
                },
              },
              subcategories: [
                {
                  name: 'Paints',
                  interventions: [
                    {
                      name: MetafireInterventions.exteriorPaintsNonSunny,
                      feature: 'exteriorPaintsNonSunny',
                      interveventionProjectMappingId: '66c444c5f7fd76f98a070290',
                      quantities: {
                        finance: {
                          capex: {
                            id: 'exteriorPaintsNonSunny_capex',
                            rw: 'r',
                            value: -140392.18285714285,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'exteriorPaintsNonSunny', 'Capex'],
                          },
                          capexConverted: {},
                          opex: {
                            id: 'exteriorPaintsNonSunny_opex',
                            rw: 'r',
                            value: 0,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'exteriorPaintsNonSunny', 'Opex'],
                          },
                          totalCapex: {
                            id: 'exteriorPaintsNonSunny_totalCapex',
                            rw: 'r',
                            value: 0,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'exteriorPaintsNonSunny', '_TotalCapex'],
                          },
                          totalCapexConverted: {
                            id: 'exteriorPaintsNonSunny_totalCapexConverted',
                            rw: 'r',
                            value: 0,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'exteriorPaintsNonSunny', '_TotalCapexConverted'],
                          },
                        },
                        resource: {
                          category: {
                            id: 'exteriorPaintsNonSunny_resourceSaving',
                            rw: 'r',
                            value: 6.787264628571427,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'exteriorPaintsNonSunny', 'Resource'],
                          },
                        },
                      },
                    },
                    {
                      name: MetafireInterventions.exteriorPaintsSunny,
                      feature: 'exteriorPaintsSunny',
                      interveventionProjectMappingId: '66c313b46d238be297ff5571',
                      quantities: {
                        finance: {
                          capex: {
                            id: 'exteriorPaintsSunny_capex',
                            rw: 'r',
                            value: -53401.67999999999,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'exteriorPaintsSunny', 'Capex'],
                          },
                          capexConverted: {},
                          opex: {
                            id: 'exteriorPaintsSunny_opex',
                            rw: 'r',
                            value: 0,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'exteriorPaintsSunny', 'Opex'],
                          },
                          totalCapex: {
                            id: 'exteriorPaintsSunny_totalCapex',
                            rw: 'r',
                            value: 0,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'exteriorPaintsSunny', '_TotalCapex'],
                          },
                          totalCapexConverted: {
                            id: 'exteriorPaintsSunny_totalCapexConverted',
                            rw: 'r',
                            value: 0,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'exteriorPaintsSunny', '_TotalCapexConverted'],
                          },
                        },
                        resource: {
                          category: {
                            id: 'exteriorPaintsSunny_resourceSaving',
                            rw: 'r',
                            value: -227.76033600000002,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'exteriorPaintsSunny', 'Resource'],
                          },
                        },
                      },
                    },
                  ],
                  quantities: {
                    finance: {
                      capex: {
                        id: 'paints_capex',
                        rw: 'r',
                        value: -193793.86285714284,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Paints', 'Capex'],
                      },
                      capexConverted: {
                        id: 'paints_capexConverted',
                        rw: 'r',
                        value: -74788.7658483889,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Paints', 'CapexConverted'],
                      },
                      opex: {
                        id: 'paints_opex',
                        rw: 'r',
                        value: 0,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Paints', 'Opex'],
                      },
                      totalCapex: {
                        id: 'paints_totalCapex',
                        rw: 'r',
                        value: 0,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Paints', '_TotalCapex'],
                      },
                      totalCapexConverted: {
                        id: 'paints_totalCapexConverted',
                        rw: 'r',
                        value: 0,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Paints', '_TotalCapexConverted'],
                      },
                    },
                    resource: {
                      category: {
                        id: 'materialsService_resourceSaving',
                        rw: 'r',
                        value: -220.9730713714286,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Category', 'Materials', 'Resource'],
                      },
                      carbon: {
                        id: 'materialsService_carbon',
                        rw: 'r',
                        value: -220.63328340504205,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Category', 'Materials', 'Carbon'],
                      },
                    },
                  },
                },
              ],
            },
            {
              name: 'Water',
              quantities: {
                finance: {
                  capex: {
                    id: 'waterService_capex',
                    rw: 'r',
                    value: -9158887633.068249,
                    label: null,
                    unit: null,
                    type: 'number',
                    tags: ['Category', 'Water', 'Capex'],
                  },
                  capexConverted: {
                    id: 'waterService_capexConverted',
                    rw: 'r',
                    value: -3532754963.0093894,
                    label: null,
                    unit: null,
                    type: 'number',
                    tags: ['Category', 'Water', 'CapexConverted'],
                  },
                  opexSavings: {
                    id: 'waterService_opex',
                    rw: 'r',
                    value: -9383911.858505487,
                    label: null,
                    unit: null,
                    type: 'number',
                    tags: ['Category', 'Water', 'Opex'],
                  },
                  totalCapex: {
                    id: 'waterService_totalCapex',
                    rw: 'r',
                    value: 10358558114,
                    label: null,
                    unit: null,
                    type: 'number',
                    tags: ['Category', 'Water', '_TotalCapex'],
                  },
                  totalCapexConverted: {
                    id: 'waterService_totalCapexConverted',
                    rw: 'r',
                    value: 3997566103.968709,
                    label: null,
                    unit: null,
                    type: 'number',
                    tags: ['Category', 'Water', '_TotalCapexConverted'],
                  },
                },
                resource: {
                  category: {
                    id: 'waterService_resourceSaving',
                    rw: 'r',
                    value: 1738372.752375,
                    label: null,
                    unit: null,
                    type: 'number',
                    tags: ['Category', 'Water', 'Resource'],
                  },
                  carbon: {
                    id: 'waterService_carbon',
                    rw: 'r',
                    value: 1043.023651425,
                    label: null,
                    unit: null,
                    type: 'number',
                    tags: ['Category', 'Water', 'Carbon'],
                  },
                  carbonConverted: {
                    id: 'waterService_carbonConverted',
                    rw: 'r',
                    value: 226,
                    label: null,
                    unit: null,
                    type: 'number',
                    tags: ['Category', 'Water', 'CarbonConverted'],
                  },
                },
              },
              subcategories: [
                {
                  name: 'Treatment And Re-use',
                  interventions: [
                    {
                      name: MetafireInterventions.nstp,
                      feature: 'nstp',
                      interveventionProjectMappingId: '664662b494ee7c4d56abccb8',
                      quantities: {
                        finance: {
                          capex: {
                            id: 'nstp_capex',
                            rw: 'r',
                            value: -9155893648.068249,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'nstp', 'Capex'],
                          },
                          capexConverted: {},
                          opex: {
                            id: 'nstp_opex',
                            rw: 'r',
                            value: 397428762.98024464,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'nstp', 'Opex'],
                          },
                          totalCapex: {
                            id: 'swm_totalCapex',
                            rw: 'r',
                            value: 3763200,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'nstp', '_TotalCapex'],
                          },
                          totalCapexConverted: {
                            id: 'nstp_totalCapexConverted',
                            rw: 'r',
                            value: 3995036166.8839636,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'nstp', '_TotalCapexConverted'],
                          },
                        },
                        resource: {
                          category: {
                            id: 'swm_resourceSaving',
                            rw: 'r',
                            value: 685724.31375,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'nstp', 'Resource'],
                          },
                        },
                      },
                    },
                    {
                      name: MetafireInterventions.twr,
                      feature: 'twr',
                      interveventionProjectMappingId: '664662b494ee7c4d56abcce4',
                      quantities: {
                        finance: {
                          capex: {
                            id: 'twr_capex',
                            rw: 'r',
                            value: 943500,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'twr', 'Capex'],
                          },
                          capexConverted: {},
                          opex: {
                            id: 'twr_opex',
                            rw: 'r',
                            value: -27593.999999999996,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'twr', 'Opex'],
                          },
                          totalCapex: {
                            id: 'twr_totalCapex',
                            rw: 'r',
                            value: 943500,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'twr', '_TotalCapex'],
                          },
                          totalCapexConverted: {
                            id: 'twr_totalCapexConverted',
                            rw: 'r',
                            value: 364114.7327249022,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'twr', '_TotalCapexConverted'],
                          },
                        },
                        resource: {
                          category: {
                            id: 'twr_resourceSaving',
                            rw: 'r',
                            value: 0,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'twr', 'Resource'],
                          },
                        },
                      },
                    },
                  ],
                  quantities: {
                    finance: {
                      capex: {
                        id: 'treatmentAndReuse_capex',
                        rw: 'r',
                        value: -9154950148.068249,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Treatment And Re-use', 'Capex'],
                      },
                      capexConverted: {
                        id: 'treatmentAndReuse_capexConverted',
                        rw: 'r',
                        value: -3533070722.0706673,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Treatment And Re-use', 'CapexConverted'],
                      },
                      opex: {
                        id: 'treatmentAndReuse_opex',
                        rw: 'r',
                        value: 397401168.98024464,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Treatment And Re-use', 'Opex'],
                      },
                      totalCapex: {
                        id: 'treatmentAndReuse_totalCapex',
                        rw: 'r',
                        value: 10352946000,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Treatment And Re-use', '_TotalCapex'],
                      },
                      totalCapexConverted: {
                        id: 'treatmentAndReuse_totalCapexConverted',
                        rw: 'r',
                        value: 3995400281.6166883,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Treatment And Re-use', '_TotalCapexConverted'],
                      },
                    },
                    resource: {
                      category: {
                        id: 'waterService_resourceSaving',
                        rw: 'r',
                        value: 1738372.752375,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Category', 'Water', 'Resource'],
                      },
                      carbon: {
                        id: 'waterService_carbon',
                        rw: 'r',
                        value: 1043.023651425,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Category', 'Water', 'Carbon'],
                      },
                    },
                  },
                },
                {
                  name: 'Demand Optimization',
                  interventions: [
                    {
                      name: MetafireInterventions.swm,
                      feature: 'swm',
                      interveventionProjectMappingId: '66b490f218133009f44915f1',
                      quantities: {
                        finance: {
                          capex: {
                            id: 'swm_capex',
                            rw: 'r',
                            value: 818200,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'swm', 'Capex'],
                          },
                          capexConverted: {},
                          opex: {
                            id: 'swm_opex',
                            rw: 'r',
                            value: 85716199.21875,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'swm', 'Opex'],
                          },
                          totalCapex: {},
                          totalCapexConverted: {
                            id: 'swm_totalCapexConverted',
                            rw: 'r',
                            value: 1452291.0039113427,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'swm', '_TotalCapexConverted'],
                          },
                        },
                        resource: {},
                      },
                    },
                    {
                      name: MetafireInterventions.efficientFixturesKitchenSink,
                      feature: 'efficientFixturesKitchenSink',
                      interveventionProjectMappingId: '664662b394ee7c4d56abcc80',
                      quantities: {
                        finance: {
                          capex: {
                            id: 'efficientFixturesKitchenSink_capex',
                            rw: 'r',
                            value: 0,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'efficientFixturesKitchenSink', 'Capex'],
                          },
                          capexConverted: {},
                          opex: {
                            id: 'efficientFixturesKitchenSink_opex',
                            rw: 'r',
                            value: -262604423.41875,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'efficientFixturesKitchenSink', 'Opex'],
                          },
                          totalCapex: {
                            id: 'efficientFixturesKitchenSink_totalCapex',
                            rw: 'r',
                            value: 112023,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'efficientFixturesKitchenSink', '_TotalCapex'],
                          },
                          totalCapexConverted: {
                            id: 'efficientFixturesKitchenSink_totalCapexConverted',
                            rw: 'r',
                            value: 43231.82268578879,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'efficientFixturesKitchenSink', '_TotalCapexConverted'],
                          },
                        },
                        resource: {
                          category: {
                            id: 'efficientFixturesKitchenSink_resourceSaving',
                            rw: 'r',
                            value: -209915.60624999998,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'efficientFixturesKitchenSink', 'Resource'],
                          },
                        },
                      },
                    },
                    {
                      name: MetafireInterventions.efficientFixturesFaucet,
                      feature: 'efficientFixturesFaucet',
                      interveventionProjectMappingId: '664662b494ee7c4d56abccd8',
                      quantities: {
                        finance: {
                          capex: {
                            id: 'efficientFixturesHealthFaucet_capex',
                            rw: 'r',
                            value: -325,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'efficientFixturesFaucet', 'Capex'],
                          },
                          capexConverted: {},
                          opex: {
                            id: 'efficientFixturesHealthFaucet_opex',
                            rw: 'r',
                            value: -430618994.82000005,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'efficientFixturesFaucet', 'Opex'],
                          },
                          totalCapex: {
                            id: 'efficientFixturesHealthFaucet_totalCapex',
                            rw: 'r',
                            value: 303875,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'efficientFixturesFaucet', '_TotalCapex'],
                          },
                          totalCapexConverted: {
                            id: 'efficientFixturesHealthFaucet_totalCapexConverted',
                            rw: 'r',
                            value: 117271.18644067796,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'efficientFixturesFaucet', '_TotalCapexConverted'],
                          },
                        },
                        resource: {
                          category: {
                            id: 'efficientFixturesHealthFaucet_resourceSaving',
                            rw: 'r',
                            value: -344219.82000000007,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'efficientFixturesFaucet', 'Resource'],
                          },
                        },
                      },
                    },
                    {
                      name: MetafireInterventions.efficientFixturesDualFlush,
                      feature: 'efficientFixturesDualFlush',
                      interveventionProjectMappingId: '664662b394ee7c4d56abcc7c',
                      quantities: {
                        finance: {
                          capex: {
                            id: 'efficientFixturesDualFlush_capex',
                            rw: 'r',
                            value: -5605704,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'efficientFixturesDualFlush', 'Capex'],
                          },
                          capexConverted: {},
                          opex: {
                            id: 'efficientFixturesDualFlush_opex',
                            rw: 'r',
                            value: -52478901.56249999,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'efficientFixturesDualFlush', 'Opex'],
                          },
                          totalCapex: {
                            id: 'efficientFixturesDualFlush_totalCapex',
                            rw: 'r',
                            value: 0,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'efficientFixturesDualFlush', '_TotalCapex'],
                          },
                          totalCapexConverted: {
                            id: 'efficientFixturesDualFlush_totalCapexConverted',
                            rw: 'r',
                            value: 0,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'efficientFixturesDualFlush', '_TotalCapexConverted'],
                          },
                        },
                        resource: {
                          category: {
                            id: 'efficientFixturesDualFlush_resourceSaving',
                            rw: 'r',
                            value: -419831.21249999997,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'efficientFixturesDualFlush', 'Resource'],
                          },
                        },
                      },
                    },
                    {
                      name: MetafireInterventions.efficientFixturesHealthFaucet,
                      feature: 'efficientFixturesHealthFaucet',
                      interveventionProjectMappingId: '664662b494ee7c4d56abccde',
                      quantities: {
                        finance: {
                          capex: {
                            id: 'efficientFixturesHealthFaucet_capex',
                            rw: 'r',
                            value: 850824,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'efficientFixturesHealthFaucet', 'Capex'],
                          },
                          capexConverted: {},
                          opex: {
                            id: 'efficientFixturesHealthFaucet_opex',
                            rw: 'r',
                            value: 5117693.743749999,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'efficientFixturesHealthFaucet', 'Opex'],
                          },
                          totalCapex: {
                            id: 'efficientFixturesHealthFaucet_totalCapex',
                            rw: 'r',
                            value: 1039896,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'efficientFixturesHealthFaucet', '_TotalCapex'],
                          },
                          totalCapexConverted: {
                            id: 'efficientFixturesHealthFaucet_totalCapexConverted',
                            rw: 'r',
                            value: 401315.79661016946,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'efficientFixturesHealthFaucet', '_TotalCapexConverted'],
                          },
                        },
                        resource: {
                          category: {
                            id: 'efficientFixturesHealthFaucet_resourceSaving',
                            rw: 'r',
                            value: 41948.309375,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'efficientFixturesHealthFaucet', 'Resource'],
                          },
                        },
                      },
                    },
                    {
                      name: MetafireInterventions.efficientFixturesShower,
                      feature: 'efficientFixturesShower',
                      interveventionProjectMappingId: '664662b394ee7c4d56abcc82',
                      quantities: {
                        finance: {
                          capex: {
                            id: 'efficientFixturesShower_capex',
                            rw: 'r',
                            value: -480,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'efficientFixturesShower', 'Capex'],
                          },
                          capexConverted: {},
                          opex: {
                            id: 'efficientFixturesShower_opex',
                            rw: 'r',
                            value: 248083346,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'efficientFixturesShower', 'Opex'],
                          },
                          totalCapex: {
                            id: 'efficientFixturesShower_totalCapex',
                            rw: 'r',
                            value: 393120,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'efficientFixturesShower', '_TotalCapex'],
                          },
                          totalCapexConverted: {
                            id: 'efficientFixturesShower_totalCapexConverted',
                            rw: 'r',
                            value: 151712.54237288135,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'efficientFixturesShower', '_TotalCapexConverted'],
                          },
                        },
                        resource: {
                          category: {
                            id: 'efficientFixturesShower_resourceSaving',
                            rw: 'r',
                            value: 1984666.768,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'efficientFixturesShower', 'Resource'],
                          },
                        },
                      },
                    },
                  ],
                  quantities: {
                    finance: {
                      capex: {
                        id: 'demandOptimization_capex',
                        rw: 'r',
                        value: -3937485,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Demand Optimization', 'Capex'],
                      },
                      capexConverted: {
                        id: 'demandOptimization_capexConverted',
                        rw: 'r',
                        value: 315759.06127770536,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Demand Optimization', 'CapexConverted'],
                      },
                      opex: {
                        id: 'demandOptimization_opex',
                        rw: 'r',
                        value: -406785080.8387501,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Demand Optimization', 'Opex'],
                      },
                      totalCapex: {
                        id: 'demandOptimization_totalCapex',
                        rw: 'r',
                        value: 5612114,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Demand Optimization', '_TotalCapex'],
                      },
                      totalCapexConverted: {
                        id: 'demandOptimization_totalCapexConverted',
                        rw: 'r',
                        value: 2165822.35202086,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Demand Optimization', '_TotalCapexConverted'],
                      },
                    },
                    resource: {
                      category: {
                        id: 'waterService_resourceSaving',
                        rw: 'r',
                        value: 1738372.752375,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Category', 'Water', 'Resource'],
                      },
                      carbon: {
                        id: 'waterService_carbon',
                        rw: 'r',
                        value: 1043.023651425,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Category', 'Water', 'Carbon'],
                      },
                    },
                  },
                },
              ],
            },
            {
              name: 'Energy',
              quantities: {
                finance: {
                  capex: {
                    id: 'energyService_capex',
                    rw: 'r',
                    value: 2443184362.5884614,
                    label: null,
                    unit: null,
                    type: 'number',
                    tags: ['Category', 'Energy', 'Capex'],
                  },
                  capexConverted: {
                    id: 'energyService_capexConverted',
                    rw: 'r',
                    value: 942871670.5686891,
                    label: null,
                    unit: null,
                    type: 'number',
                    tags: ['Category', 'Energy', 'CapexConverted'],
                  },
                  opexSavings: {
                    id: 'energyService_opex',
                    rw: 'r',
                    value: 167087152.0728284,
                    label: null,
                    unit: null,
                    type: 'number',
                    tags: ['Category', 'Energy', 'Opex'],
                  },
                  totalCapex: {
                    id: 'energyService_totalCapex',
                    rw: 'r',
                    value: 2443252362.5884614,
                    label: null,
                    unit: null,
                    type: 'number',
                    tags: ['Category', 'Energy', '_TotalCapex'],
                  },
                  totalCapexConverted: {
                    id: 'energyService_totalCapexConverted',
                    rw: 'r',
                    value: 942897913.0719485,
                    label: null,
                    unit: null,
                    type: 'number',
                    tags: ['Category', 'Energy', '_TotalCapexConverted'],
                  },
                },
                resource: {
                  category: {
                    id: 'energyService_resourceSaving',
                    rw: 'r',
                    value: 3098301.533584109,
                    label: null,
                    unit: null,
                    type: 'number',
                    tags: ['Category', 'Energy', 'Resource'],
                  },
                  carbon: {
                    id: 'energyService_carbon',
                    rw: 'r',
                    value: 1858.9809201504654,
                    label: null,
                    unit: null,
                    type: 'number',
                    tags: ['Category', 'Energy', 'Carbon'],
                  },
                  carbonConverted: {
                    id: 'energyService_carbonConverted',
                    rw: 'r',
                    value: 403,
                    label: null,
                    unit: null,
                    type: 'number',
                    tags: ['Category', 'Energy', 'CarbonConverted'],
                  },
                },
              },
              subcategories: [
                {
                  name: 'Renewable Sources',
                  interventions: [
                    {
                      name: MetafireInterventions.spvPenthouse,
                      feature: 'spvPenthouse',
                      interveventionProjectMappingId: '664662b394ee7c4d56abcc90',
                      quantities: {
                        finance: {
                          capex: {
                            id: 'spvVilla_capex',
                            rw: 'r',
                            value: 218592528,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'spvPenthouse', 'Capex'],
                          },
                          capexConverted: {},
                          opex: {
                            id: 'spvVilla_opex',
                            rw: 'r',
                            value: -1661834.0803679996,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'spvPenthouse', 'Opex'],
                          },
                          totalCapex: {
                            id: 'spvVilla_totalCapex',
                            rw: 'r',
                            value: 218592528,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'spvPenthouse', '_TotalCapex'],
                          },
                          totalCapexConverted: {
                            id: 'spvVilla_totalCapexConverted',
                            rw: 'r',
                            value: 84359046.00782269,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'spvPenthouse', '_TotalCapexConverted'],
                          },
                        },
                        resource: {
                          category: {
                            id: 'spvVilla_resourceSaving',
                            rw: 'r',
                            value: -184648.23115199996,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'spvPenthouse', 'Resource'],
                          },
                        },
                      },
                    },
                    {
                      name: MetafireInterventions.spvClubhouse,
                      feature: 'spvClubhouse',
                      interveventionProjectMappingId: '664662b494ee7c4d56abccf0',
                      quantities: {
                        finance: {
                          capex: {
                            id: 'spvVilla_capex',
                            rw: 'r',
                            value: 5416655.03274,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'spvClubhouse', 'Capex'],
                          },
                          capexConverted: {},
                          opex: {
                            id: 'spvVilla_opex',
                            rw: 'r',
                            value: -5006278.966869453,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'spvClubhouse', 'Opex'],
                          },
                          totalCapex: {
                            id: 'spvVilla_totalCapex',
                            rw: 'r',
                            value: 5416655.03274,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'spvClubhouse', '_TotalCapex'],
                          },
                          totalCapexConverted: {
                            id: 'spvVilla_totalCapexConverted',
                            rw: 'r',
                            value: 2090390.9904707172,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'spvClubhouse', '_TotalCapexConverted'],
                          },
                        },
                        resource: {
                          category: {
                            id: 'spvVilla_resourceSaving',
                            rw: 'r',
                            value: -5011.2902571265795,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'spvClubhouse', 'Resource'],
                          },
                        },
                      },
                    },
                    {
                      name: MetafireInterventions.spvVilla,
                      feature: 'spvVilla',
                      interveventionProjectMappingId: '664662b494ee7c4d56abccf4',
                      quantities: {
                        finance: {
                          capex: {
                            id: 'spvVilla_capex',
                            rw: 'r',
                            value: 4848059.19199,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'spvVilla', 'Capex'],
                          },
                          capexConverted: {},
                          opex: {
                            id: 'spvVilla_opex',
                            rw: 'r',
                            value: 144293492.75632054,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'spvVilla', 'Opex'],
                          },
                          totalCapex: {
                            id: 'spvVilla_totalCapex',
                            rw: 'r',
                            value: 4848059.19199,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'spvVilla', '_TotalCapex'],
                          },
                          totalCapexConverted: {
                            id: 'spvVilla_totalCapexConverted',
                            rw: 'r',
                            value: 1870958.9580561148,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'spvVilla', '_TotalCapexConverted'],
                          },
                        },
                        resource: {
                          category: {
                            id: 'spvVilla_resourceSaving',
                            rw: 'r',
                            value: 14430.79235486754,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'spvVilla', 'Resource'],
                          },
                        },
                      },
                    },
                    {
                      name: MetafireInterventions.spvApartment,
                      feature: 'spvApartment',
                      interveventionProjectMappingId: '664662b494ee7c4d56abccd4',
                      quantities: {
                        finance: {
                          capex: {
                            id: 'spvApartment_capex',
                            rw: 'r',
                            value: 178292500,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'spvApartment', 'Capex'],
                          },
                          capexConverted: {},
                          opex: {
                            id: 'spvApartment_opex',
                            rw: 'r',
                            value: -1355969.16,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'spvApartment', 'Opex'],
                          },
                          totalCapex: {
                            id: 'spvApartment_totalCapex',
                            rw: 'r',
                            value: 178360000,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'spvApartment', '_TotalCapex'],
                          },
                          totalCapexConverted: {
                            id: 'spvApartment_totalCapexConverted',
                            rw: 'r',
                            value: 68832542.37288135,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'spvApartment', '_TotalCapexConverted'],
                          },
                        },
                        resource: {
                          category: {
                            id: 'spvApartment_resourceSaving',
                            rw: 'r',
                            value: -150663.24,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'spvApartment', 'Resource'],
                          },
                        },
                      },
                    },
                  ],
                  quantities: {
                    finance: {
                      capex: {
                        id: 'renewableSources_capex',
                        rw: 'r',
                        value: 407149742.22473,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Renewable Sources', 'Capex'],
                      },
                      capexConverted: {
                        id: 'renewableSources_capexConverted',
                        rw: 'r',
                        value: 157126888.78555423,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Renewable Sources', 'CapexConverted'],
                      },
                      opex: {
                        id: 'renewableSources_opex',
                        rw: 'r',
                        value: 136269410.54908308,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Renewable Sources', 'Opex'],
                      },
                      totalCapex: {
                        id: 'renewableSources_totalCapex',
                        rw: 'r',
                        value: 407217242.22473,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Renewable Sources', '_TotalCapex'],
                      },
                      totalCapexConverted: {
                        id: 'renewableSources_totalCapexConverted',
                        rw: 'r',
                        value: 157152938.32923087,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Renewable Sources', '_TotalCapexConverted'],
                      },
                    },
                    resource: {
                      category: {
                        id: 'energyService_resourceSaving',
                        rw: 'r',
                        value: 3098301.533584109,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Category', 'Energy', 'Resource'],
                      },
                      carbon: {
                        id: 'energyService_carbon',
                        rw: 'r',
                        value: 1858.9809201504654,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Category', 'Energy', 'Carbon'],
                      },
                    },
                  },
                },
                {
                  name: 'Hot Water Management',
                  interventions: [
                    {
                      name: MetafireInterventions.hwmApartment,
                      feature: 'hwmApartment',
                      interveventionProjectMappingId: '66310a37b37972468f761193',
                      quantities: {
                        finance: {
                          capex: {
                            id: 'hwmApartment_capex',
                            rw: 'r',
                            value: 12538012.432902671,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'hwmApartment', 'Capex'],
                          },
                          capexConverted: {},
                          opex: {
                            id: 'hwmApartment_opex',
                            rw: 'r',
                            value: 22216433.27240363,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'hwmApartment', 'Opex'],
                          },
                          totalCapex: {
                            id: 'hwmApartment_totalCapex',
                            rw: 'r',
                            value: 12538012.432902671,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'hwmApartment', '_TotalCapex'],
                          },
                          totalCapexConverted: {
                            id: 'hwmApartment_totalCapexConverted',
                            rw: 'r',
                            value: 4838659.296139753,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'hwmApartment', '_TotalCapexConverted'],
                          },
                        },
                        resource: {
                          category: {
                            id: 'hwmApartment_resourceSaving',
                            rw: 'r',
                            value: 2468492.5858226256,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'hwmApartment', 'Resource'],
                          },
                        },
                      },
                    },
                    {
                      name: MetafireInterventions.hwmSwimmingPool,
                      feature: 'hwmSwimmingPool',
                      interveventionProjectMappingId: '664662b494ee7c4d56abcc9e',
                      quantities: {
                        finance: {
                          capex: {
                            id: 'hwmSwimmingPool_capex',
                            rw: 'r',
                            value: 421982.9308288611,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'hwmSwimmingPool', 'Capex'],
                          },
                          capexConverted: {},
                          opex: {
                            id: 'hwmSwimmingPool_opex',
                            rw: 'r',
                            value: 717308.2513416815,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'hwmSwimmingPool', 'Opex'],
                          },
                          totalCapex: {
                            id: 'hwmSwimmingPool_totalCapex',
                            rw: 'r',
                            value: 422107.9308288611,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'hwmSwimmingPool', '_TotalCapex'],
                          },
                          totalCapexConverted: {
                            id: 'hwmSwimmingPool_totalCapexConverted',
                            rw: 'r',
                            value: 162899.54045025146,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'hwmSwimmingPool', '_TotalCapexConverted'],
                          },
                        },
                        resource: {
                          category: {
                            id: 'hwmSwimmingPool_resourceSaving',
                            rw: 'r',
                            value: 79700.91681574238,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'hwmSwimmingPool', 'Resource'],
                          },
                        },
                      },
                    },
                    {
                      name: MetafireInterventions.hwmPenthouse,
                      feature: 'hwmPenthouse',
                      interveventionProjectMappingId: '664662b494ee7c4d56abccdc',
                      quantities: {
                        finance: {
                          capex: {
                            id: 'hwmPenthouse_capex',
                            rw: 'r',
                            value: -125,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'hwmPenthouse', 'Capex'],
                          },
                          capexConverted: {},
                          opex: {
                            id: 'hwmPenthouse_opex',
                            rw: 'r',
                            value: 0,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'hwmPenthouse', 'Opex'],
                          },
                          totalCapex: {
                            id: 'hwmPenthouse_totalCapex',
                            rw: 'r',
                            value: 0,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'hwmPenthouse', '_TotalCapex'],
                          },
                          totalCapexConverted: {
                            id: 'hwmPenthouse_totalCapexConverted',
                            rw: 'r',
                            value: 0,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'hwmPenthouse', '_TotalCapexConverted'],
                          },
                        },
                        resource: {
                          category: {
                            id: 'hwmPenthouse_resourceSaving',
                            rw: 'r',
                            value: 0,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'hwmPenthouse', 'Resource'],
                          },
                        },
                      },
                    },
                    {
                      name: MetafireInterventions.hwmClubhouse,
                      feature: 'hwmClubhouse',
                      interveventionProjectMappingId: '664662b394ee7c4d56abcc6c',
                      quantities: {
                        finance: {
                          capex: {
                            id: 'hwmClubhouse_capex',
                            rw: 'r',
                            value: 74875,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'hwmClubhouse', 'Capex'],
                          },
                          capexConverted: {},
                          opex: {
                            id: 'hwmClubhouse_opex',
                            rw: 'r',
                            value: 65700,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'hwmClubhouse', 'Opex'],
                          },
                          totalCapex: {
                            id: 'hwmClubhouse_totalCapex',
                            rw: 'r',
                            value: 75000,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'hwmClubhouse', '_TotalCapex'],
                          },
                          totalCapexConverted: {
                            id: 'hwmClubhouse_totalCapexConverted',
                            rw: 'r',
                            value: 28943.937418513688,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'hwmClubhouse', '_TotalCapexConverted'],
                          },
                        },
                        resource: {
                          category: {
                            id: 'hwmClubhouse_resourceSaving',
                            rw: 'r',
                            value: 7300,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'hwmClubhouse', 'Resource'],
                          },
                        },
                      },
                    },
                    {
                      name: MetafireInterventions.hwmVilla,
                      feature: 'hwmVilla',
                      interveventionProjectMappingId: '664662b494ee7c4d56abccea',
                      quantities: {
                        finance: {
                          capex: {
                            id: 'hwmVilla_capex',
                            rw: 'r',
                            value: 2022999875,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'hwmVilla', 'Capex'],
                          },
                          capexConverted: {},
                          opex: {
                            id: 'hwmVilla_opex',
                            rw: 'r',
                            value: 7818300,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'hwmVilla', 'Opex'],
                          },
                          totalCapex: {
                            id: 'hwmVilla_totalCapex',
                            rw: 'r',
                            value: 2023000000,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'hwmVilla', '_TotalCapex'],
                          },
                          totalCapexConverted: {
                            id: 'hwmVilla_totalCapexConverted',
                            rw: 'r',
                            value: 780714471.9687092,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'hwmVilla', '_TotalCapexConverted'],
                          },
                        },
                        resource: {
                          category: {
                            id: 'hwmVilla_resourceSaving',
                            rw: 'r',
                            value: 868700,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'hwmVilla', 'Resource'],
                          },
                        },
                      },
                    },
                  ],
                  quantities: {
                    finance: {
                      capex: {
                        id: 'hwm_capex',
                        rw: 'r',
                        value: 2036034620.3637314,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Hot Water Management', 'Capex'],
                      },
                      capexConverted: {
                        id: 'hwm_capexConverted',
                        rw: 'r',
                        value: 785744781.7831348,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Hot Water Management', 'CapexConverted'],
                      },
                      opex: {
                        id: 'hwm_opex',
                        rw: 'r',
                        value: 30817741.523745313,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Hot Water Management', 'Opex'],
                      },
                      totalCapex: {
                        id: 'hwm_totalCapex',
                        rw: 'r',
                        value: 2036035120.3637314,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Hot Water Management', '_TotalCapex'],
                      },
                      totalCapexConverted: {
                        id: 'hwm_totalCapexConverted',
                        rw: 'r',
                        value: 785744974.7427176,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Hot Water Management', '_TotalCapexConverted'],
                      },
                    },
                    resource: {
                      category: {
                        id: 'energyService_resourceSaving',
                        rw: 'r',
                        value: 3098301.533584109,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Category', 'Energy', 'Resource'],
                      },
                      carbon: {
                        id: 'energyService_carbon',
                        rw: 'r',
                        value: 1858.9809201504654,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Category', 'Energy', 'Carbon'],
                      },
                    },
                  },
                },
              ],
            },
            {
              name: 'Waste',
              quantities: {
                finance: {
                  capex: {
                    id: 'wasteService_capex',
                    rw: 'r',
                    value: 0,
                    label: null,
                    unit: null,
                    type: 'number',
                    tags: ['Category', 'Waste', 'Capex'],
                  },
                  capexConverted: {
                    id: 'wasteService_capexConverted',
                    rw: 'r',
                    value: 0,
                    label: null,
                    unit: null,
                    type: 'number',
                    tags: ['Category', 'Waste', 'CapexConverted'],
                  },
                  opexSavings: {
                    id: 'wasteService_opex',
                    rw: 'r',
                    value: 82864745342137.5,
                    label: null,
                    unit: null,
                    type: 'number',
                    tags: ['Category', 'Waste', 'Opex'],
                  },
                  totalCapex: {
                    id: 'wasteService_totalCapex',
                    rw: 'r',
                    value: 6134520,
                    label: null,
                    unit: null,
                    type: 'number',
                    tags: ['Category', 'Waste', '_TotalCapex'],
                  },
                  totalCapexConverted: {
                    id: 'wasteService_totalCapexConverted',
                    rw: 'r',
                    value: 2367428.8396349414,
                    label: null,
                    unit: null,
                    type: 'number',
                    tags: ['Category', 'Waste', '_TotalCapexConverted'],
                  },
                },
                resource: {
                  category: {
                    id: 'wasteService_resourceSaving',
                    rw: 'r',
                    value: 223909980,
                    label: null,
                    unit: null,
                    type: 'number',
                    tags: ['Category', 'Waste', 'Resource'],
                  },
                  carbon: {
                    id: 'wasteService_carbon',
                    rw: 'r',
                    value: 7277074.35,
                    label: null,
                    unit: null,
                    type: 'number',
                    tags: ['Category', 'Waste', 'Carbon'],
                  },
                  carbonConverted: {
                    id: 'wasteService_carbonConverted',
                    rw: 'r',
                    value: 1581973,
                    label: null,
                    unit: null,
                    type: 'number',
                    tags: ['Category', 'Waste', 'CarbonConverted'],
                  },
                },
              },
              subcategories: [
                {
                  name: 'Management',
                  interventions: [
                    {
                      name: MetafireInterventions.csp,
                      feature: 'csp',
                      interveventionProjectMappingId: '66b33005465291fe9d583c3f',
                      quantities: {
                        finance: {
                          capex: {
                            id: 'wwm_capex',
                            rw: 'r',
                            value: 0,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'csp', 'Capex'],
                          },
                          capexConverted: {},
                          opex: {
                            id: 'wwm_opex',
                            rw: 'r',
                            value: 82286917650000,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'csp', 'Opex'],
                          },
                          totalCapex: {
                            id: 'wwm_totalCapex',
                            rw: 'r',
                            value: 4600890,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'csp', '_TotalCapex'],
                          },
                          totalCapexConverted: {
                            id: 'wwm_totalCapexConverted',
                            rw: 'r',
                            value: 1775571.629726206,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'csp', '_TotalCapexConverted'],
                          },
                        },
                        resource: {
                          category: {
                            id: 'wwm_resourceSaving',
                            rw: 'r',
                            value: 167932485,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'csp', 'Resource'],
                          },
                        },
                      },
                    },
                  ],
                  quantities: {
                    finance: {
                      capex: {
                        id: 'management_capex',
                        rw: 'r',
                        value: 0,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Management', 'Capex'],
                      },
                      capexConverted: {
                        id: 'management_capexConverted',
                        rw: 'r',
                        value: 0,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Management', 'CapexConverted'],
                      },
                      opex: {
                        id: 'management_opex',
                        rw: 'r',
                        value: 82286917650000,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Management', 'Opex'],
                      },
                      totalCapex: {
                        id: 'management_totalCapex',
                        rw: 'r',
                        value: 4600890,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Management', '_TotalCapex'],
                      },
                      totalCapexConverted: {
                        id: 'management_totalCapexConverted',
                        rw: 'r',
                        value: 1775571.629726206,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Management', '_TotalCapexConverted'],
                      },
                    },
                    resource: {
                      category: {
                        id: 'wasteService_resourceSaving',
                        rw: 'r',
                        value: 223909980,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Category', 'Waste', 'Resource'],
                      },
                      carbon: {
                        id: 'wasteService_carbon',
                        rw: 'r',
                        value: 7277074.35,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Category', 'Waste', 'Carbon'],
                      },
                    },
                  },
                },
                {
                  name: 'Reduction',
                  interventions: [
                    {
                      name: MetafireInterventions.wwm,
                      feature: 'wwm',
                      interveventionProjectMappingId: '66b32df5465291fe9d5838c3',
                      quantities: {
                        finance: {
                          capex: {
                            id: 'wwm_capex',
                            rw: 'r',
                            value: 0,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'wwm', 'Capex'],
                          },
                          capexConverted: {},
                          opex: {
                            id: 'wwm_opex',
                            rw: 'r',
                            value: 577827692137.5,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'wwm', 'Opex'],
                          },
                          totalCapex: {
                            id: 'wwm_totalCapex',
                            rw: 'r',
                            value: 1533630,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'wwm', '_TotalCapex'],
                          },
                          totalCapexConverted: {
                            id: 'wwm_totalCapexConverted',
                            rw: 'r',
                            value: 591857.2099087354,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'wwm', '_TotalCapexConverted'],
                          },
                        },
                        resource: {
                          category: {
                            id: 'wwm_resourceSaving',
                            rw: 'r',
                            value: 55977495,
                            label: null,
                            unit: null,
                            type: 'number',
                            tags: ['Intervention', 'wwm', 'Resource'],
                          },
                        },
                      },
                    },
                  ],
                  quantities: {
                    finance: {
                      capex: {
                        id: 'reduction_capex',
                        rw: 'r',
                        value: 0,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Reduction', 'Capex'],
                      },
                      capexConverted: {
                        id: 'reduction_capexConverted',
                        rw: 'r',
                        value: 0,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Reduction', 'CapexConverted'],
                      },
                      opex: {
                        id: 'reduction_opex',
                        rw: 'r',
                        value: 577827692137.5,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Reduction', 'Opex'],
                      },
                      totalCapex: {
                        id: 'reduction_totalCapex',
                        rw: 'r',
                        value: 1533630,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Reduction', '_TotalCapex'],
                      },
                      totalCapexConverted: {
                        id: 'reduction_totalCapexConverted',
                        rw: 'r',
                        value: 591857.2099087354,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Subcategory', 'Reduction', '_TotalCapexConverted'],
                      },
                    },
                    resource: {
                      category: {
                        id: 'wasteService_resourceSaving',
                        rw: 'r',
                        value: 223909980,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Category', 'Waste', 'Resource'],
                      },
                      carbon: {
                        id: 'wasteService_carbon',
                        rw: 'r',
                        value: 7277074.35,
                        label: null,
                        unit: null,
                        type: 'number',
                        tags: ['Category', 'Waste', 'Carbon'],
                      },
                    },
                  },
                },
              ],
            },
          ],
        },
        ...MockOldDataForInsights,
      },
    },
  },
};
