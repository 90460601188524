/* eslint-disable react-hooks/exhaustive-deps */
// React and Core Libraries
import React, { useEffect } from 'react';

// Material-UI Components
import { Grid, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';

// Common Components
import HeadAndBackIcon from '../components/common/header/HeadAndBackIcon';
import { BackdropLoader, SearchField } from '../components/views/users/admin/main/Components';

// Project-Specific Components
import { OrganizationSelector, NoUsersAvailable, DeleteUserConfirmation } from '../components/views/users/admin/manageUsers/Components';
import UsersManagementTable from '../components/views/adminpannel/superAdmins/users/table';

// Hooks
import { useAppApiRequest } from '../hooks/useAppApiRequest';

// API Endpoints
import apiList from '../http';

// Styles
import { makeStyles } from '@material-ui/core';
import UserFormModal from '../components/views/adminpannel/superAdmins/users/form';
export const useStyles = makeStyles((theme) => ({
  searchInput: {
    width: '100%',
  },
  formControl: {
    minWidth: 200,
    maxWidth: '100%',
  },
  select: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  menuPaper: {
    maxHeight: 300,
  },
  organizationName: {
    fontWeight: 500,
  },
  organizationCode: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(1),
    fontSize: '0.875rem',
  },
  noOrganizations: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  searchField: {
    marginTop: '3%',
    marginBottom: '3%',
  },
}));

const Users = () => {
  const classes = useStyles();
  const [selectedOrg, setSelectedOrg] = React.useState('');
  const [organizations, setOrganizations] = React.useState([]);
  const [loader, showLoader] = React.useState(false);
  const [fetchOrgUsers, setFetchOrgUsers] = React.useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = React.useState(false);

  const [openUsersModal, setOpenUsersModal] = React.useState(false);

  const [searchValue, setSearchValue] = React.useState('');
  const [usersList, setUsersList] = React.useState('');
  const [selectedUser, setSelectedUser] = React.useState('');
  const [selectedUserToDelete, setSelectedUserToDelete] = React.useState('');

  const [editUserDetails, setEditUserDetails] = React.useState(null);
  const [selectedOrganisationUserLimit, setSelectedOrganisationUserLimit] = React.useState(null);

  const { data: organisationsData, isFetching: fetchingorganisationsData } = useAppApiRequest(() => apiList.getOrganisations, {}, [], true);

  const { data, isFetching, refetch } = useAppApiRequest(() => apiList.getOrganisationUsers(selectedOrg), {}, [selectedOrg], fetchOrgUsers);

  useEffect(() => {
    if (fetchingorganisationsData) showLoader(true);
  }, [fetchingorganisationsData]);

  useEffect(() => {
    if (isFetching) showLoader(true);
  }, [isFetching]);

  useEffect(() => {
    if (organisationsData) {
      organisationsData
        .then((data) => {
          const orgs = data?.data?.data?.body?.data;
          setOrganizations(orgs);
          if (orgs && orgs.length > 0 && !selectedOrg) setSelectedOrg(orgs.find((org) => org.name === 'SD+')?._id);
          showLoader(false);
        })
        .catch((error) => console.error('Error fetching organizations:', error));
    }
  }, [organisationsData]);

  useEffect(() => {
    if (selectedOrg && organizations) {
      setSelectedOrganisationUserLimit(organizations.find((org) => org._id === selectedOrg)?.subscription?.numberOfUsers);
      setFetchOrgUsers(true);
    }
  }, [selectedOrg, organizations]);

  useEffect(() => {
    if (data) {
      showLoader(false);
      setFetchOrgUsers(false);
      setUsersList(data?.data?.data?.body?.data);
    }
  }, [data]);

  // Handle edit organization
  const handleEditUsers = (user) => {
    setSelectedUser(user);
    setOpenUsersModal(true);
    let obj = {};
    obj['name'] = user?.name;
    obj['email'] = user?.email;
    obj['role'] = user?.role;
    setEditUserDetails(obj);
  };

  // Handle delete organization
  const handleDeleteUser = async (user) => {
    setDeleteConfirmOpen(false);
    const deleteUser = await apiList.deleteUser({
      userId: user?._id,
      organisationId: selectedOrg,
    });
    if (deleteUser) setFetchOrgUsers(true);
  };

  const remainingLimit = selectedOrganisationUserLimit - usersList?.length;
  const maxLimit = selectedOrganisationUserLimit;

  const renderUsersOps = () => (
    <Grid container justifyContent="space-between" className={classes.searchField}>
      <Grid item xs={9} container justifyContent="flex-start" alignItems="center">
        <SearchField setSearchValue={setSearchValue} classes={classes} placeholder="Search by name / email" />
      </Grid>
      <Grid item xs={3} container justifyContent="flex-end" alignItems="center">
        <Typography
          onClick={() => (!!remainingLimit && remainingLimit < maxLimit ? setOpenUsersModal(true) : null)}
          variant="h5"
          style={!!remainingLimit && remainingLimit < maxLimit ? { color: '#00C58A', cursor: 'pointer' } : { color: 'grey', cursor: 'pointer' }}
        >
          <b>Create User</b>
        </Typography>
        <Grid item xs={1} container justifyContent="flex-end" alignItems="center" style={{ marginLeft: '5%' }}>
          <AddCircleIcon style={!!remainingLimit && remainingLimit < maxLimit ? { fontSize: '30px', color: '#00C58A' } : { fontSize: '30px', color: 'grey' }} />
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <div>
      <BackdropLoader open={loader} />
      <Grid container justifyContent="space-between">
        <Grid item xs={10} container justifyContent="flex-start" alignItems="center">
          <HeadAndBackIcon heading="Users Management" showArrow={false} />
        </Grid>
        <Grid item xs={2} container justifyContent="flex-end" alignItems="center">
          <OrganizationSelector organizations={organizations} selectedOrg={selectedOrg} setSelectedOrg={setSelectedOrg} required />
        </Grid>
        {renderUsersOps()}
        <Grid item xs={12}>
          {!loader &&
            (usersList?.length > 0 ? (
              <UsersManagementTable
                users={usersList}
                onEditUser={handleEditUsers}
                searchValue={searchValue}
                onDeleteUser={(user) => {
                  setDeleteConfirmOpen(true);
                  setSelectedUserToDelete(user);
                }}
              />
            ) : (
              <NoUsersAvailable onCreateUser={() => setOpenUsersModal(true)} />
            ))}
        </Grid>
        {!loader && (
          <Grid container>
            {!!remainingLimit && remainingLimit < maxLimit && (
              <Grid xs={12} container alignItems="center" justifyContent="center" style={{ marginTop: '2%' }}>
                <Typography variant="h6" color="textSecondary">
                  {`you can create ${remainingLimit} more ${remainingLimit === 1 ? `user` : 'users'} for this organisation. max limit is ${maxLimit}.`}
                </Typography>
              </Grid>
            )}{' '}
            {!remainingLimit && (
              <Grid xs={12} container alignItems="center" justifyContent="center" style={{ marginTop: '2%' }}>
                <Typography variant="h6" color="textSecondary">
                  {`maximum limit achieved, you can not create more users for this organisation. max limit is ${maxLimit}.`}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
      {openUsersModal && (
        <UserFormModal
          open={openUsersModal}
          onClose={() => {
            setOpenUsersModal(false);
            setEditUserDetails(null);
          }}
          initialData={editUserDetails}
          selectedUser={selectedUser}
          setSelectedUser={(val) => setSelectedUser(val)}
          refetchUsers={() => {
            setFetchOrgUsers(true);
            refetch();
          }}
          organisationID={selectedOrg}
        />
      )}

      {deleteConfirmOpen && (
        <DeleteUserConfirmation
          selectedUser={selectedUserToDelete}
          deleteConfirmOpen={deleteConfirmOpen}
          onClose={() => setDeleteConfirmOpen(false)}
          onCancel={() => setDeleteConfirmOpen(false)}
          handleDeleteConfirm={(user) => handleDeleteUser(user)}
        />
      )}
    </div>
  );
};

export default Users;
