import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIosSharpIcon from '@material-ui/icons/ArrowForwardIosSharp';
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import axios from '../../../../config/Axios';
import CasaSkeleton from '../../../common/skeleton/CasaSkeleton';
import { UserContext } from '../../../../routing/IndexRouting';
import api from '../../../../http';

export const AllProjectContext = React.createContext();

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '870px',
    outline: 'none',
    height: 530,
    backgroundColor: '#FFFFFF',
    borderRadius: '15px',
    padding: theme.spacing(2, 4, 3),
  },
  modalhead: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '25px',
    textAlign: 'center',
    letterSpacing: '0.714286px',
    color: '#29E1AC',
    paddingTop: '30px',
  },
  modalhead2: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '25px',
    textAlign: 'center',
    letterSpacing: '0.714286px',
    color: '#29E1AC',
  },
  close: {
    marginLeft: '95%',
    fontSize: '20px',
  },
  text1: {
    fontSize: '14px',
    lineHeight: '228.56%',
    letterSpacing: '1.42857px',

    color: '#000000',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textAlign: 'center',
    paddingTop: '13%',
    marginBottom: '39px',
  },
  text2: {
    fontSize: '13px',
    lineHeight: '20px',
    letterSpacing: '1.42857px',

    color: '#000000',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textAlign: 'left',
    paddingTop: '2%',
    paddingBottom: '1%',
  },
  link: {
    lineHeight: '28px',
    fontWeight: 850,
    textDecorationLine: 'underline',
    color: '#00C58A',
    paddingTop: '2%',
    cursor: 'pointer',
  },
}));

const SDBasics = ({ source }) => {
  const classes = useStyles();

  const { userDetails } = useContext(UserContext);

  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(true);

  const [number, setNumber] = useState(0);
  const [pic1, setPic1] = useState('');
  const [pic2, setPic2] = useState('');
  const [pic3, setPic3] = useState('');
  const [pic4, setPic4] = useState('');
  const [pic5, setPic5] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getUserDetails = async () => {
    const response = await api.getAuthWhoAmI();
    setOpen(!!response?.onboarding?.showBasics);
  };
  useEffect(() => {
    getUserDetails();
  }, []);

  useEffect(() => {
    if (source === 'FAQs') setOpen(false);
  }, [source]);

  const handleCheckbox = async () => {
    setOpen(false);
    await api.postUserOnboarding(userDetails?._id, { onboarding: { showBasics: false, showHowItWorks: userDetails?.onboarding?.showHowItWorks } });
  };
  const handleBack = () => {
    if (number === 0) setNumber(5);
    else setNumber(number - 1);
  };

  const handleNext = () => {
    if (number === 5) setNumber(0);
    else setNumber(number + 1);
  };

  useEffect(() => {
    axios
      .get('/platform-asset')
      .then((res) => {
        if (res.data && res.data.data && res.data.data.data) {
          let all = res.data.data.data;
          let pic1 = all.find((a) => a.tag === 'sdplus-basics-1');
          let pic2 = all.find((a) => a.tag === 'sdplus-basics-2');
          let pic3 = all.find((a) => a.tag === 'sdplus-basics-3');
          let pic4 = all.find((a) => a.tag === 'sdplus-basics-4');
          let pic5 = all.find((a) => a.tag === 'sdplus-basics-5');
          setPic1(pic1.assetlocation.URL);
          setPic2(pic2.assetlocation.URL);
          setPic3(pic3.assetlocation.URL);
          setPic4(pic4.assetlocation.URL);
          setPic5(pic5.assetlocation.URL);
        }
      })
      .catch((err) => {
        console.log(err, 'error');
      });
  }, []);

  const content1 = (
    <>
      <Typography className={classes.modalhead}>What is SD+?</Typography>
      <Typography className={classes.text1}>
        All financial & environmental data at your fingertips to help make an informed decision Tailored solutions considering resource constraints and location
        based insights Curated list of vendors to execute the solutions.
      </Typography>
    </>
  );
  const content2 = (
    <>
      <Typography className={classes.modalhead2}>Create Project</Typography>
      <Typography className={classes.text2}>
        The first step on our sustainable journey is to create a project, providing as much information as possible. More data ensures more accurate
        recommendations.{' '}
      </Typography>
      <Grid container item xs={12}>
        <Grid item xs={8}>
          {pic1 ? (
            <img src={pic1} width="430" height="350" alt="..." style={{ marginBottom: '-160px' }} />
          ) : (
            <CasaSkeleton variant="rect" height="350" width="430" animation="wave" />
          )}
        </Grid>
        <Grid item xs={4}>
          <Typography style={{ fontSize: '13px', lineHeight: '20px', paddingTop: '100px' }}>
            Project inputs are used to calculate the environmental and financial data along with sizing & other parameters of the tailored solutions
          </Typography>
        </Grid>
      </Grid>
    </>
  );
  const content3 = (
    <>
      <Typography className={classes.modalhead2}>Insights</Typography>
      <Typography className={classes.text2}>
        Insights is a high-level summary of all important information pertaining to the sustainable design of the project.
      </Typography>
      <Grid container item xs={12}>
        <Grid item xs={8}>
          {pic2 ? (
            <img src={pic2} width="430" height="350" alt="..." style={{ marginBottom: '-160px' }} />
          ) : (
            <CasaSkeleton variant="rect" height="350" width="430" animation="wave" />
          )}
        </Grid>
        <Grid item xs={4} style={{ marginBottom: '-160px' }}>
          <Typography style={{ fontSize: '12px', lineHeight: '20px', paddingTop: '10px' }}>Key metrics inlcude:</Typography>
          <Typography style={{ fontSize: '12px', lineHeight: '20px', paddingTop: '10px' }}>
            Sustainability level - a ranking system to benchmark project's overall sustainability quotient
          </Typography>
          <Typography style={{ fontSize: '12px', lineHeight: '20px', paddingTop: '10px' }}>
            Tracking of Goals - check progress of targeted sustainability goals
          </Typography>
          <Typography style={{ fontSize: '12px', lineHeight: '20px', paddingTop: '10px' }}>
            Location based insights - environmental insights specific to the project location
          </Typography>
          <Typography style={{ fontSize: '12px', lineHeight: '20px', paddingTop: '10px' }}>
            Capex and Opex - Overall investment & future financial returns
          </Typography>
          <Typography style={{ fontSize: '12px', lineHeight: '20px', paddingTop: '10px' }}>
            Resource savings- Environmental impact in terms of resources saved
          </Typography>
        </Grid>
      </Grid>
    </>
  );

  const content4 = (
    <>
      <Typography className={classes.modalhead2}>Interventions</Typography>
      <Typography className={classes.text2}>
        Interventions are solutions that can be implemented to improve the building performance & contribute towards achieving the project's goals.
      </Typography>
      <Grid container item xs={12}>
        <Grid item xs={8}>
          {pic3 ? (
            <img src={pic3} width="430" height="350" alt="..." style={{ marginBottom: '-160px' }} />
          ) : (
            <CasaSkeleton variant="rect" height="350" width="430" animation="wave" />
          )}
        </Grid>
        <Grid item xs={4} style={{ marginBottom: '-160px' }}>
          <Typography style={{ fontSize: '12px', lineHeight: '20px', paddingTop: '10px' }}></Typography>
          <Typography style={{ fontSize: '12px', lineHeight: '20px', paddingTop: '10px' }}>
            The Interventions page displays both the overall summary & break-up of financial & environmental impact.
          </Typography>
          <Typography style={{ fontSize: '12px', lineHeight: '20px', paddingTop: '10px' }}>
            The user can enable & disable different solutions to see the impact on each category as well as overall summary.
          </Typography>
          <Typography style={{ fontSize: '12px', lineHeight: '20px', paddingTop: '10px' }}>
            Local municipal guidelines and government mandates are considered while sizing the solutions.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
  const content5 = (
    <>
      <Typography className={classes.modalhead2}>Intervention Details</Typography>
      <Typography className={classes.text2}>
        Solutions are recommended to the best optimized capacity but the user can stillcustomize solutions as per their requirements.{' '}
      </Typography>
      <Grid container item xs={12}>
        <Grid item xs={8}>
          {pic4 ? (
            <img src={pic4} width="430" height="350" alt="..." style={{ marginBottom: '-160px' }} />
          ) : (
            <CasaSkeleton variant="rect" height="350" width="430" animation="wave" />
          )}
        </Grid>
        <Grid item xs={4} style={{ marginBottom: '-160px' }}>
          <Typography style={{ fontSize: '12px', lineHeight: '20px', paddingTop: '20px' }}></Typography>
          <Typography style={{ fontSize: '12px', lineHeight: '20px', paddingTop: '10px' }}>
            Each intervention can be explored more in detail when clicked on it
          </Typography>
          <Typography style={{ fontSize: '12px', lineHeight: '20px', paddingTop: '10px' }}>
            Cost & Data assumptions used in the calcualtions can be observed & edited
          </Typography>
          <Typography style={{ fontSize: '12px', lineHeight: '20px', paddingTop: '10px' }}>
            Different break-ups & contributions of the specific solutions can be explored in this section{' '}
          </Typography>
        </Grid>
      </Grid>
    </>
  );

  const content6 = (
    <>
      <Typography className={classes.modalhead2}>Vendors</Typography>
      <Typography className={classes.text2}>List of curated vendors who can execute each solution. </Typography>
      <Grid container item xs={12}>
        <Grid item xs={8}>
          {pic5 ? (
            <img src={pic5} width="430" height="350" alt="..." style={{ marginBottom: '-140px' }} />
          ) : (
            <CasaSkeleton variant="rect" height="350" width="430" animation="wave" />
          )}
        </Grid>
        <Grid item xs={4} style={{ marginBottom: '-140px' }}>
          <Typography style={{ fontSize: '12px', lineHeight: '20px', paddingTop: '20px' }}></Typography>
          <Typography style={{ fontSize: '12px', lineHeight: '20px', paddingTop: '10px' }}>
            Each intervention can be explored more in detail when clicked on it
          </Typography>
          <Typography style={{ fontSize: '12px', lineHeight: '20px', paddingTop: '10px' }}>
            Cost & Data assumptions used in the calcualtions can be observed & edited
          </Typography>
          <Typography style={{ fontSize: '12px', lineHeight: '20px', paddingTop: '10px' }}>
            Different break-ups & contributions of the specific solutions can be explored in this section{' '}
          </Typography>
        </Grid>
      </Grid>
    </>
  );

  const body1 = (
    <div style={modalStyle} className={classes.paper}>
      <CloseIcon className={classes.close} onClick={handleClose} />
      <Grid item container xs={12}>
        <Grid item xs={1}>
          <Paper style={{ height: '62px', width: '37px', background: 'rgba(0, 0, 0, 0.05)', marginLeft: '-48%', marginTop: '190px' }}>
            <ArrowBackIosSharpIcon style={{ alignItems: 'left', fontSize: '30px', marginTop: '15px', cursor: 'pointer' }} onClick={handleBack} />
          </Paper>
        </Grid>
        <Grid item xs={10}>
          {number === 0 && content1}
          {number === 1 && content2}
          {number === 2 && content3}
          {number === 3 && content4}
          {number === 4 && content5}
          {number === 5 && content6}
        </Grid>
        <Grid item xs={1}>
          <Paper style={{ height: '62px', width: '37px', background: 'rgba(0, 0, 0, 0.05)', marginLeft: '93%', marginTop: '190px' }}>
            <ArrowForwardIosSharpIcon style={{ alignItems: 'left', fontSize: '30px', marginTop: '15px', cursor: 'pointer' }} onClick={handleNext} />
          </Paper>
        </Grid>
      </Grid>
      {source === 'FAQs' ? (
        ''
      ) : (
        <Grid container item xs={12} style={{ paddingTop: '160px' }}>
          <Grid item xs={6}>
            <Grid container item xs={12}>
              <Grid item xs={1}>
                <CheckBoxOutlineBlankIcon style={{ border: '1px solid #49DDB1', cursor: 'pointer', color: '#FFFFFF' }} onClick={handleCheckbox} />
              </Grid>
              <Grid item xs={9}>
                <Typography variant="h6" style={{ fontSize: '13px', lineHeight: '22px' }}>
                  Do not show again
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" style={{ fontSize: '13px', lineHeight: '22px' }}>
              *These slides can be accessed from “Help” section anytime
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );

  return (
    <div>
      {source === 'FAQs' && (
        <Typography variant="h6" className={classes.link} onClick={handleOpen}>
          SD+ BASICS
        </Typography>
      )}

      <Modal open={open} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
        {body1}
      </Modal>
    </div>
  );
};
export default SDBasics;
