import React, { useEffect, useState, useContext } from 'react';
import { Document, Page, StyleSheet, PDFViewer, Font, View, Text } from '@react-pdf/renderer';
import LatoRegular from '../../../../fonts/Lato-Regular.ttf';
import LatoItalic from '../../../../fonts/Lato-Italic.ttf';
import LatoBold from '../../../../fonts/Lato-Bold.ttf';
import { useParams } from 'react-router';
import { UserContext } from '../../../../../routing/IndexRouting';
import MaterialsRounded from '../../../../../assets/new-pdf/MaterialsRounded.png';
import HeadingPage from './HeadingPage';
import MaterialsReportIcon from '../../../../../assets/new-pdf/MaterialsReportIcon.png';
import WaterIcon from '../../../../../assets/new-pdf/WaterIcon.png';
import { useAppApiRequest } from '../../../../../hooks/useAppApiRequest';
import { MetafireFeatures } from '../../../../../metafire/utils/types';
import CategoryWiseSummaryPage from './CategoryWiseSummaryPage';
import CategoryReportBarGraphPage from './CategoryReportBarGraphPage';
import ImplementInterventionsDetailsPage from './ImplementedInterventionsDetailsPage';
import Footer from './Footer';
import Header from './Header';
import EmbodiedCarbonChart from './EmbodiedCarbonChart';
import { getCategoryWiseEnabledAndActivatedInterventionsData, getCurrentDate } from './ReportUtils';

const styles = StyleSheet.create({
  footerMargin: {
    marginTop: '1%',
  },
  viewPage2: {
    margin: '10px 20px 20px 20px',
    padding: 10,
    flexGrow: 1,
    height: '700px',
    width: 'auto',
  },
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  viewer: {
    width: window.innerWidth - 8,
    height: window.innerHeight - 8,
  },
});

Font.register({
  family: 'Lato',
  fonts: [
    {
      src: LatoRegular,
    },
    {
      src: LatoBold,
    },
  ],
});
Font.register({
  family: 'Lato-Italic',
  fonts: [
    {
      src: LatoItalic,
    },
  ],
});

const MaterialsReport = () => {
  const { userDetails } = useContext(UserContext);
  const params = useParams();
  const [asset, setAsset] = useState('');
  const [intervention, setIntervention] = useState(null);
  const [reportData, setReportData] = useState([]);
  const [projectData, setProjectData] = React.useState('');
  const { data: insightsResponse } = useAppApiRequest(`/metafire?projectId=${params?.id}&feature=${MetafireFeatures.INSIGHTS}&numberOfYears=1`, {
    method: 'GET',
  });
  const { data: projectDataResponse } = useAppApiRequest(`/projectv2/${params?.id}/basics?NumberOfYears=1`, { method: 'GET' });
  const { data: reportResponse } = useAppApiRequest(`/metafire?projectId=${params?.id}&feature=${MetafireFeatures.REPORT}`, { method: 'GET' });

  useEffect(() => {
    if (insightsResponse) {
      setIntervention(insightsResponse?.data?.data?.data?.interventions);
    }
  }, [insightsResponse]);
  useEffect(() => {
    if (reportResponse) {
      setReportData(reportResponse?.data?.data?.data);
    }
  }, [reportResponse]);
  useEffect(() => {
    if (projectDataResponse) {
      setProjectData(projectDataResponse?.data?.data?.data);
    }
  }, [projectDataResponse]);
  const { data: assetReponse } = useAppApiRequest(`/sign-s3/${userDetails?.organisation?.payloadId}`, { method: 'GET' });
  useEffect(() => {
    if (assetReponse?.data?.data) {
      setAsset(assetReponse?.data?.data?.data);
    }
  }, [assetReponse]);

  return (
    <PDFViewer style={styles.viewer}>
      <Document title={`Materials Selection Report ${getCurrentDate()}`}>
        <Page size="A4" style={styles.page}>
          <HeadingPage
            category={'Materials'}
            pageIcon={MaterialsReportIcon}
            asset={asset}
            reportName={'Material Selection'}
            projectname={projectData && projectData.name}
            date={getCurrentDate()}
            reportHeadingColor="#916AAC"
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <CategoryReportBarGraphPage
            heading="Materials"
            category="Materials"
            icon={MaterialsRounded}
            companyname={projectData && projectData.name}
            capex={intervention?.categorized?.filter((item) => item.name === 'Materials')[0]?.quantities?.finance?.capex?.value}
            opex={intervention?.categorized?.filter((item) => item.name === 'Materials')[0]?.quantities?.finance?.opexSavings?.value}
            totalResourceSavings={intervention?.categorized?.filter((item) => item.name === 'Materials')[0]?.quantities?.resource?.category?.value}
            unit="tCO2e"
            baselineConsumption={
              intervention?.categorized?.filter((item) => item.name === 'Materials')[0]?.quantities?.resource?.annualBaselineConsumption?.value
            }
            year={getCurrentDate().split('/')[2]}
            title1={'Base Case Embodied Carbon'}
            title2={'Sustainable Case Embodied Carbon'}
            percentageResourceSaving={intervention?.categorized?.filter((item) => item.name === 'Materials')[0]?.quantities?.resource?.categorySavingPercentage}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <CategoryWiseSummaryPage
            companyname={projectData && projectData.name}
            totalResourceSavings={{
              value: intervention?.categorized?.filter((item) => item.name === 'Materials')[0]?.quantities?.resource?.category?.value,
              unit: 'kL',
            }}
            interventionData={intervention}
            resourceSavingPercentage={
              intervention?.categorized?.filter((item) => item.name === 'Materials')[0]?.quantities?.resource?.categorySavingPercentage?.value
            }
            category="Materials"
            border="1px solid #9E9E9E"
            color="#916AAC"
            categorisedReportData={getCategoryWiseEnabledAndActivatedInterventionsData(reportData && reportData, 'reportImpactSummary_materials')}
            headingIcon={MaterialsRounded}
            dummyicon={WaterIcon}
            year={getCurrentDate().split('/')[2]}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <ImplementInterventionsDetailsPage
            companyname={projectData && projectData.name}
            ProjectinterventionMappingData={{}}
            allVendorMappings={{}}
            allvendors={{}}
            EnabledGrouped={{}}
            border="1px solid #9E9E9E"
            color="#916AAC"
            year={getCurrentDate().split('/')[2]}
            category="Materials"
            categorisedReportData={getCategoryWiseEnabledAndActivatedInterventionsData(reportData && reportData, 'reportImpactSummary_materials')}
            numnber={2}
            resourceSavingUnit={'tons'}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <View style={styles.viewPage2}>
            <Header companyname={projectData && projectData.name} />
            <Text
              style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '30px', textTransform: 'uppercase', marginTop: '5px', marginBottom: '5px' }}
            >{`EMBODIED CARBON IMPACT`}</Text>
            <View>
              <EmbodiedCarbonChart
                categorisedReportData={getCategoryWiseEnabledAndActivatedInterventionsData(reportData && reportData, 'reportImpactSummary_materials')}
                baselineEmbodiedCarbon={
                  intervention?.categorized?.filter((item) => item.name === 'Materials')[0]?.quantities?.resource?.annualBaselineConsumption?.value
                }
              />
            </View>
          </View>
          <View style={{ position: 'absolute', left: 20, top: 810, right: 20 }}>
            <Footer year={getCurrentDate().split('/')[2]} />
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};
export default MaterialsReport;
