import React, { useContext, useEffect, useState } from 'react';
import Dropdown from './DropDown';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { UserContext } from '../../../routing/IndexRouting';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Avatar from 'react-avatar';
import Loader from '../../../config/Loader';
import userRoles from '../../../config/roles';
import SDLogoNew from '../../../assets/header/SDLogoNew.svg';
import axios from '../../../config/Axios';

export const NoteTakingContext = React.createContext();

const useStyles = makeStyles((theme) => ({
  appBar: {
    minheight: '60px',
    maxheight: '82px',
    flexGrow: 1,
    backgroundColor: '#FFFFFF',
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    alignSelf: 'flex-end',
  },
}));

const StyledButton = withStyles({
  root: {
    color: '#ffffff',
    background: 'transparent',
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
})(IconButton);

export default function Header(props) {
  const id = props.id;
  const classes = useStyles();
  const { userDetails } = useContext(UserContext);
  const [asset, setAsset] = useState('');
  useEffect(() => {
    let id = userDetails?.organisation?.payloadId;
    if (id) {
      axios.get(`/sign-s3/${id}`).then((response) => {
        if (response.data.data) {
          setAsset(response.data.data.data);
        }
      });
    }
  }, [userDetails]);
  const OrgLogo = () => {
    return <img src={asset.URL || SDLogoNew} alt="..." height="36vh" width="auto" />;
  };

  const SDplus = () => {
    return <img src={SDLogoNew} alt="..." height="36vh" width="auto" />;
  };
  return (
    <>
      <CssBaseline />
      <AppBar className={classes.appBar} position="fixed" style={{ zIndex: props.notetaking ? 9999 : 100 }}>
        <Toolbar>
          {Loader(OrgLogo)(userRoles.usersandadmins)}
          {Loader(SDplus)(userRoles.supersV2)}
          <Typography className={classes.title} variant="h5" noWrap></Typography>
          {userDetails && <Avatar color={'#29E1AC'} round={true} size="30" name={userDetails.name} />}
          <StyledButton edge="end" className={classes.menuButton} aria-label="menu">
            <Dropdown id={id} currentprojects={props.currentprojects} />
          </StyledButton>
        </Toolbar>
      </AppBar>
    </>
  );
}
