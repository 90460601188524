import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import ResetFields from '../common/textFiledwithReset';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import numberWithCommas from '../../../../../utils/numberToComma';

const ScCustomAdvancedParameters = (props) => {
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityDefaultValue, getQuantityLabel, getQuantityUnit },
    set: { updateQuantityValue },
  } = useInterventionDetailsContext();
  const handleNumericInputChange = (schemaId, value, updateFunction) => {
    const numericValue = parseFloat(value) || 0;
    updateFunction(schemaId, numericValue);
  };
  return (
    <Grid container direction="column" alignItems="left" item xs={12}>
      {/* <Grid container item xs={12}> */}
      <Typography className={classes.textHeader1} style={{ paddingTop: '3%' }}>
        Intervention Cost Assumptions
        <CustomizedTooltips icon="info" title={<Typography>Changes will only affect this intervention </Typography>} placement="right" />
      </Typography>
      <Typography className={classes.textHeader4}>CAPEX (per bag)</Typography>
      <Grid container item xs={12}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader3}>
              {getQuantityValue(schema.sc__pi_capexSourceMultiplier) === 'vendor' ? (
                <CheckCircleIcon className={classes.selectedCheckbox} onClick={() => updateQuantityValue(schema.sc__pi_capexSourceMultiplier, 'vendor')} />
              ) : (
                <RadioButtonUncheckedIcon
                  className={classes.nonselectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.sc__pi_capexSourceMultiplier, 'vendor');
                  }}
                />
              )}
              Selected Vendor
            </Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <Typography className={classes.textconstants}>
                {numberWithCommas(getQuantityValue(schema.sc_vendorCapex))} {getQuantityUnit(schema.sc_vendorCapex)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader3}>
              {getQuantityValue(schema.sc__pi_capexSourceMultiplier) === 'custom' ? (
                <CheckCircleIcon
                  className={classes.selectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.sc__pi_capexSourceMultiplier, 'custom');
                  }}
                />
              ) : (
                <RadioButtonUncheckedIcon
                  className={classes.nonselectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.sc__pi_capexSourceMultiplier, 'custom');
                  }}
                />
              )}
              Custom
            </Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.sc__pi_customCapex)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.sc__pi_customCapex, e.target.value);
                }}
                fullWidth
                required
                onClick={() => updateQuantityValue(schema.sc__pi_customCapex, getQuantityDefaultValue(schema.sc__pi_customCapex))}
                disabled={getQuantityValue(schema.sc__pi_capexSourceMultiplier) !== 'custom' ? true : false}
                removeResetIcon={true}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6" className={getQuantityValue(schema.sc__pi_capexSourceMultiplier) !== 'custom' ? classes.textDisable : undefined}>
                {' '}
                {getQuantityUnit(schema.sc__pi_customCapex)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.sc__pi_opcCapex)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.sc__pi_opcCapex)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  handleNumericInputChange(schema.sc__pi_opcCapex, e.target.value, updateQuantityValue);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.sc__pi_opcCapex, getQuantityDefaultValue(schema.sc__pi_opcCapex));
                }}
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.sc__pi_opcCapex)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.sc__pi_baseCaseggbsPercentage)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.sc__pi_baseCaseggbsPercentage)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  handleNumericInputChange(schema.sc__pi_baseCaseggbsPercentage, e.target.value, updateQuantityValue);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.sc__pi_baseCaseggbsPercentage, getQuantityDefaultValue(schema.sc__pi_baseCaseggbsPercentage));
                }}
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              {/* <Typography variant="h6">{getQuantityUnit(schema.sc__pi_baseCaseggbsPercentage)}</Typography> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.sc__pi_opcEmboidedCarbon)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.sc__pi_opcEmboidedCarbon)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  handleNumericInputChange(schema.sc__pi_opcEmboidedCarbon, e.target.value, updateQuantityValue);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.sc__pi_opcEmboidedCarbon, getQuantityDefaultValue(schema.sc__pi_opcEmboidedCarbon));
                }}
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.sc__pi_opcEmboidedCarbon)}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* </Grid> */}
    </Grid>
  );
};

export default ScCustomAdvancedParameters;
